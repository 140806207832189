import { useCallback, useRef, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQuery } from "@tanstack/react-query";
import { useActiveMonitorsDrawer } from "../ActiveMonitorsDrawer";
import { useTrueBizApi } from "../../../api";

interface UnenrolledButtonProps extends ButtonProps {
  domain?: string | null | undefined;
}

function UnenrolledButton({ domain, ...etc }: UnenrolledButtonProps) {
  const drawer = useActiveMonitorsDrawer();

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => drawer.createMonitor({ domain: domain || undefined })}
      {...etc}
    >
      Enroll in monitoring
    </Button>
  );
}

interface EnrolledButtonProps extends ButtonProps {
  monitorId: UUID;
  domain: string;
  loading?: boolean;
}

function EnrolledButton({
  monitorId,
  domain,
  loading = false,
  disabled,
  ...etc
}: EnrolledButtonProps) {
  const drawer = useActiveMonitorsDrawer();
  const [el, setEl] = useState<HTMLElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const closeMenu = useCallback(() => {
    setEl(null);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        ref={buttonRef}
        endIcon={
          <ArrowDropDownIcon
            onClick={(e) => {
              e.stopPropagation();
              if (buttonRef.current) {
                setEl(buttonRef.current);
              }
            }}
          />
        }
        onClick={() => {
          window
            .open(`/monitoring/issues/open/?search=${domain}`, "_blank")
            ?.focus();
        }}
        disabled={disabled}
        {...etc}
      >
        View Monitor
      </Button>
      <Menu
        open={!disabled && el != null}
        onClose={() => setEl(null)}
        anchorEl={el}
      >
        <MenuItem
          onClick={() => {
            window
              .open(`/monitoring/issues/open/?search=${domain}`, "_blank")
              ?.focus();
            closeMenu();
          }}
          disabled={disabled}
        >
          See issues
        </MenuItem>
        <MenuItem
          onClick={() => {
            window
              .open(
                `/monitoring/issues/open/?search=${domain}&monitorId=${monitorId}`,
                "_blank"
              )
              ?.focus();
            closeMenu();
          }}
          disabled={disabled}
        >
          See timeline
        </MenuItem>
        <MenuItem
          onClick={() => {
            drawer.editMonitor(monitorId, true);
            closeMenu();
          }}
        >
          Modify enrollment
        </MenuItem>
        <MenuItem
          onClick={() => {
            drawer.deleteMonitor(monitorId, true);
            closeMenu();
          }}
        >
          Unenroll
        </MenuItem>
      </Menu>
    </>
  );
}

export interface Props extends ButtonProps {
  domain: string;
}

export default function EnrollmentButton({ domain, disabled, ...etc }: Props) {
  const api = useTrueBizApi();

  const monitorQuery = useQuery({
    queryKey: ["getMonitors", domain],
    queryFn: () => api.getMonitors(0, 1, null, undefined, domain),
  });

  if (monitorQuery.isLoading || monitorQuery.isError) {
    return <UnenrolledButton disabled {...etc} />;
  }

  if (monitorQuery.data?.items.length) {
    return (
      <EnrolledButton
        domain={domain}
        monitorId={monitorQuery.data.items[0].id}
        disabled={disabled}
        {...etc}
      />
    );
  }

  return <UnenrolledButton domain={domain} disabled={disabled} {...etc} />;
}
