import * as React from "react";
import type { SVGProps } from "react";
const SvgVa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1365.333}
    height={1365.333}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M700.6 150.1c-36.8 14.7-67 27-67.1 27.1-.2.2.6 5 1.6 10.8 1.1 5.7 1.6 10.7 1.1 11.1-.4.3-10.1 3.6-21.6 7.3l-20.8 6.7-2.2-2c-2-1.9-3.5-2-26.1-2.2l-24-.1-24.2 10.8c-22.6 10.1-24.3 11.1-24.3 13.5 0 1.4-.6 3.2-1.2 4.1-1.2 1.5-42.5 15.5-43.2 14.7-.2-.2-1.8-4.8-3.5-10.1-3.1-9.4-3.3-9.7-5.9-9.2-1.5.2-17 2.2-34.5 4.3-17.5 2.2-32 4.1-32.2 4.3-.2.1-4.2 12.6-8.9 27.7-7 22.7-8.3 27.6-7.1 28.5.7.6 4.5 3.5 8.4 6.5 6.8 5.3 7 5.5 5.3 7.6-.9 1.1-19 20.2-40.2 42.4l-38.5 40.3-5.2-5.8-5.3-5.7-14.5 6.8c-11.7 5.5-14.5 7.2-14.6 8.9 0 1.2-.8 9.4-1.7 18.4-1 9-1.5 16.5-1.2 16.7.3.1 3.1.8 6.3 1.5 3.4.8 5.7 1.8 5.7 2.6 0 1.9-29 99.8-29.8 100.6-.4.3-5.4-.4-11.2-1.7-10.3-2.4-10.5-2.4-12-.5-12.9 16.3-14.8 18.2-28.1 27.7l-13.5 9.7-6.6 11.9-6.6 12 6.2 5c3.3 2.7 6.7 5.6 7.5 6.2 1.1 1.1-4.7 7.9-33.4 39.2-19.1 20.8-36.2 39.3-38 41.2l-3.3 3.3-8.8-6.6c-4.8-3.7-9.1-6.5-9.5-6.4-3.6 1.4-73.6 44.5-73.1 45 1 1 66.5 31.8 67.6 31.8.5 0 4.1-2.9 8.1-6.5l7.2-6.4 46.4 38.4c25.5 21.2 46.8 39.1 47.3 39.9.7 1 0 2.8-2.2 6.2-2.6 4.1-2.9 5.1-1.8 6.4.7.8 4.5 5.4 8.5 10.2 4.3 5.3 7.9 8.8 9 8.8 1.6 0 51.2-9 57.7-10.5 1.9-.4 2.3-1.7 3.5-9.7l1.4-9.3 3.5.1c1.9.1 37.6 2.8 79.2 6l75.7 5.9.3 10.9.3 10.9 43 14.4 43 14.3 37.5 2.4c20.6 1.3 37.8 2.2 38.2 2 .3-.2.9-3.8 1.3-7.9.5-4.9 1.2-7.5 2-7.5.6 0 31.1 5.9 67.6 13 36.5 7.2 66.7 13 67.1 13 .4 0 3-3.9 5.8-8.6l5.2-8.5 73.5.7 73.4.7-.5-3.4c-1.4-8.1-5.7-40.2-6.5-48.4-.7-7.1-1.8-11.2-5.1-19l-4.2-10-.5-18c-.5-17.7.7-37.8 2.4-40.9.7-1.3 3.1 1.8 10.8 13.6 15.3 23.6 22.8 29.7 48.9 39.9 8.5 3.3 11.7 4 20.1 4.5 5.5.3 14.3.1 19.6-.4 8.8-1 10.6-1.6 21.5-7 15.1-7.6 20.9-12.4 29.4-24.2 14.8-20.8 16.4-26.6 16.6-62.5 0-14.3.4-31.4.8-38 1-16.4-1.2-34.4-5.4-45.3-4.6-11.9-13.5-26-20.5-32.4-3.3-3-10.3-8.1-15.5-11.3-5.2-3.2-9.6-5.9-9.8-6-.2-.2-1.6-65.2-3.2-144.5s-3-144.3-3.2-144.4c-.2-.2-37.2-10-82.3-22-45.1-11.9-82-21.8-82.1-21.9-.2-.1-7.3-26.5-15.9-58.7-18.2-68.1-16.5-62-17.3-61.9-.4 0-30.8 12.2-67.6 27" />
  </svg>
);
export default SvgVa;
