import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import BooleanAttribute from "./BooleanAttribute";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIWebsiteContent from "../../types/APIWebsiteContent";
import { isTruthy } from "../../utilities/isTruthy";

export default function WebsiteContent({
  websiteContent,
  websiteContentProperties,
}: {
  websiteContent: APIWebsiteContent | null;
  websiteContentProperties: null | any;
}) {
  if (
    !websiteContent ||
    (!websiteContent.evaluated_url &&
      !websiteContent.title &&
      !websiteContent.description &&
      !websiteContent.primary_language)
  )
    return null;

  return (
    <Box data-testid="website-content-wrapper">
      <Grid container spacing={2} marginTop={-2} columnSpacing={{ md: 6 }}>
        {websiteContent.evaluated_url && (
          <Grid item xs={12}>
            <Attribute
              label="Evaluated URL"
              labelIcon={
                <Tooltip
                  title={
                    websiteContentProperties?.evaluated_url.description ||
                    "The url that was evaluated for content related information."
                  }
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
            >
              {websiteContent?.evaluated_url}
            </Attribute>
          </Grid>
        )}

        {websiteContent.content_flags && (
          <Grid item xs={4}>
            <Attribute
              label="Content flags"
              failureText="No problematic content found"
              labelIcon={
                <Tooltip
                  title={websiteContentProperties?.content_flags.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
            >
              {websiteContent?.content_flags.join(", ")}
            </Attribute>
          </Grid>
        )}

        <Grid item xs={4}>
          <Attribute
            label="Multi-level-marketing name"
            labelIcon={
              <Tooltip
                title={
                  websiteContentProperties?.multi_level_marketing_name
                    .description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.multi_level_marketing_name}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <BooleanAttribute
            label="Placeholder text detected?"
            labelIcon={
              <Tooltip
                title={
                  websiteContentProperties?.has_placeholder_text.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            value={websiteContent?.has_placeholder_text}
            trueIsPositive={false}
          />
        </Grid>

        {isTruthy(websiteContent?.pages_linked_from_homepage) && (
          <Grid item xs={4}>
            <Attribute
              label="Pages linked from homepage"
              failureText="Unknown"
              labelIcon={
                <Tooltip
                  title={
                    websiteContentProperties?.pages_linked_from_homepage
                      .description ||
                    "The number of pages at the domain that are linked from the homepage."
                  }
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
            >
              {websiteContent?.pages_linked_from_homepage?.toLocaleString(
                "en-US"
              )}
            </Attribute>
          </Grid>
        )}

        {(!websiteContent.duplicate_websites ||
          websiteContent.duplicate_websites.length === 0) && (
          <Grid item xs={4}>
            <Attribute
              label="Duplicate websites"
              failureText="Not found"
              labelIcon={
                <Tooltip
                  title={
                    websiteContentProperties?.duplicate_websites.description
                  }
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
            >
              {null}
            </Attribute>
          </Grid>
        )}

        <Grid item xs={4}>
          <Attribute
            label="Website language"
            failureText="Unknown"
            labelIcon={
              <Tooltip
                title={websiteContentProperties?.primary_language.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.primary_language}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Website title"
            labelIcon={
              <Tooltip
                title={
                  websiteContentProperties?.title.description ||
                  "The website's title as seen by users and search engines."
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.title}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Payment Processors"
            labelIcon={
              <Tooltip
                title={websiteContentProperties?.payment_processors.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.payment_processors &&
              websiteContent.payment_processors.join(", ")}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Website description"
            labelIcon={
              <Tooltip
                title={
                  websiteContentProperties?.description.description ||
                  "How the website describes itself to users, search engines, and social media links."
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.description}
          </Attribute>
        </Grid>
        <Grid item xs={8}>
          <Attribute
            label="Detected Brands"
            failureText="No suspicious brands detected"
            labelIcon={
              <Tooltip
                title={websiteContentProperties?.detected_brands.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {websiteContent?.detected_brands &&
              websiteContent.detected_brands.map((brand) => (
                <Box key={brand.name}>
                  {brand.name} ({brand.count} times)
                </Box>
              ))}
          </Attribute>
        </Grid>
      </Grid>
    </Box>
  );
}
