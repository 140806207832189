import { NavLink, useLocation } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import WebOnly from "./WebOnly";
import APIResponse from "../types/APIResponse";

export default function ResultsTabs({
  response,
  pathPrefix,
}: {
  response: APIResponse | null;
  pathPrefix: string;
}) {
  const location = useLocation();

  return (
    <WebOnly>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={location.pathname}
          sx={{
            ".MuiTabs-flexContainer": {
              justifyContent: "right",
            },
          }}
        >
          <Tab
            to={`${pathPrefix}${location.search}`}
            component={NavLink}
            value={pathPrefix}
            sx={{ px: 2, py: 1, fontSize: "small" }}
            label="Results"
          />
          <Tab
            to={`${pathPrefix}/parameters${location.search}`}
            component={NavLink}
            value={`${pathPrefix}/parameters`}
            sx={{ px: 2, py: 1, fontSize: "small" }}
            label="Parameters"
          />
          <Tab
            to={`${pathPrefix}/json${location.search}`}
            component={NavLink}
            value={`${pathPrefix}/json`}
            sx={{ px: 2, py: 1, fontSize: "small" }}
            label="API response"
          />
        </Tabs>
      </Box>
    </WebOnly>
  );
}
