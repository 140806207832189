import { Alert, Snackbar } from "@mui/material";
import { useCallback, useEffect } from "react";
import { createRoot } from "react-dom/client";

function TraditionalNetworkFailureToast({
  open,
  onClose,
  message = "An unexpected error occurred and the request could not be completed. Please try again.",
}: {
  open: boolean;
  onClose: () => void;
  message?: string;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity="error"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export const useShowNetworkFailureToast = () => {
  const renderToast = useCallback((message?: string) => {
    const el = document.createElement("div");
    document.body.appendChild(el);

    const root = createRoot(el);
    root.render(
      <TraditionalNetworkFailureToast
        open
        message={message}
        onClose={() => {
          root.unmount();
          el.remove();
        }}
      />
    );
  }, []);

  return renderToast;
};

function AutoClosingNetworkToast({ message }: { message?: string }) {
  const showToast = useShowNetworkFailureToast();

  useEffect(() => {
    showToast(message);
  }, [message, showToast]);

  return null;
}

export default function NetworkFailureToast({
  open,
  onClose,
  message,
}: {
  open?: boolean;
  onClose?: () => void;
  message?: string;
}) {
  if (open !== undefined && onClose !== undefined) {
    return (
      <TraditionalNetworkFailureToast
        open={open}
        onClose={onClose}
        message={message}
      />
    );
  }

  return <AutoClosingNetworkToast message={message} />;
}
