import {
  generatePath,
  Outlet,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import { useTrueBizApi } from "../../api";
import NetworkFailureToast from "../NetworkFailureToast";
import ResultsTabs from "../ResultsTabs";
import APICompanyLookup from "../../types/APICompanyLookup";

export default function Relay() {
  const api = useTrueBizApi();
  let { searchId } = useParams();

  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [response, setResponse] = useState<null | APICompanyLookup>(null);

  const pathPrefix = generatePath("/relay/search/:searchId", {
    searchId: encodeURIComponent(String(searchId)),
  });

  useEffect(() => {
    if (!searchId) return;

    const lookup = async () => {
      setLoading(true);
      setFailed(false);

      try {
        setResponse(
          await api.getAuthRelayCompanyLookup(searchId!, queryParams)
        );
      } catch {
        setFailed(true);
      } finally {
        setLoading(false);
      }
    };

    lookup();
  }, [api, searchId, queryParams]);

  const onResetState = () => {
    setLoading(false);
    setFailed(false);
    setResponse(null);
  };

  return (
    <Box>
      <NetworkFailureToast open={failed} onClose={onResetState} />

      <Typography variant="h4" component="h1" fontWeight={700}>
        Web Presence Review
      </Typography>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {response && (
        <Slide direction="up" in>
          <Box marginTop={1.5}>
            <ResultsTabs
              pathPrefix={pathPrefix}
              response={response.response_data}
            />

            <Outlet
              context={{
                response: response.response_data,
                relayQueryParameters: queryParams,
                relayRequestId: searchId,
              }}
            />
          </Box>
        </Slide>
      )}
    </Box>
  );
}
