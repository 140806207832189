import type { SvgIconProps } from "@mui/material";
import {
  SupportAgentOutlined,
  LocalShippingOutlined,
  ReportGmailerrorredOutlined,
  CopyrightOutlined,
  AutoAwesomeOutlined,
  PaymentsOutlined,
  InfoOutlined,
} from "@mui/icons-material";

import { CustomerReviewAnalysisFlag } from "../../../../types/APICustomerReviews";

interface Props extends SvgIconProps {
  flag: CustomerReviewAnalysisFlag | string;
}

export default function FlaggedConcernIcon({ flag, ...etc }: Props) {
  switch (flag) {
    case CustomerReviewAnalysisFlag.CUSTOMER_SERVICE:
      return <SupportAgentOutlined {...etc} />;
    case CustomerReviewAnalysisFlag.DELIVERY:
      return <LocalShippingOutlined {...etc} />;
    case CustomerReviewAnalysisFlag.FRAUD:
      return <ReportGmailerrorredOutlined {...etc} />;
    case CustomerReviewAnalysisFlag.PRODUCT_AUTHENTICITY:
      return <CopyrightOutlined {...etc} />;
    case CustomerReviewAnalysisFlag.PRODUCT_QUALITY:
      return <AutoAwesomeOutlined {...etc} />;
    case CustomerReviewAnalysisFlag.RETURN_OR_REFUND:
      return <PaymentsOutlined {...etc} />;
    default:
      return <InfoOutlined {...etc} />;
  }
}
