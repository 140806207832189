import { useState } from "react";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import { Box, Paper, ToggleButtonGroup, ToggleButton } from "@mui/material";
import APIAddress from "../../types/APIAddress";

const emptyMap = require("../../images/empty-map.png");

const MAP_HEIGHT_PX = 406;

export default function AddressMap({ address }: { address: APIAddress }) {
  const [mapType, setMapType] = useState<"map" | "streetview">("streetview");

  if (!address?.coordinates || !address?.full_address) {
    return (
      <Box
        height={MAP_HEIGHT_PX}
        sx={{
          backgroundImage: `url(${emptyMap})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backdropFilter: "blur(4px)",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
          }}
        ></Box>
        <Box
          position="relative"
          zIndex={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <LocationOffIcon fontSize="large" />

          <Box marginTop={1}>
            <strong>No address data found.</strong>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Paper elevation={2} sx={{ p: 0, position: "relative", lineHeight: 0 }}>
      <Box position="absolute" top={12} right={12} zIndex={99}>
        <ToggleButtonGroup
          size="small"
          value={mapType}
          exclusive
          onChange={(_, newType) => setMapType(newType)}
          aria-label="text alignment"
          sx={{ backgroundColor: "#fff" }}
        >
          <ToggleButton
            value="streetview"
            aria-label="streetview"
            sx={{ px: 3 }}
          >
            Streetview
          </ToggleButton>

          <ToggleButton value="map" aria-label="map" sx={{ px: 3 }}>
            Map
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {mapType === "map" ? (
        <iframe
          width="100%"
          height={MAP_HEIGHT_PX}
          title="Google Map"
          style={{ border: 0, borderRadius: 4, display: "block" }}
          loading="lazy"
          allowFullScreen
          id="embed-map"
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }&q=${encodeURIComponent(address.full_address)}&center=${
            address.coordinates.lat
          },${address.coordinates.lon}&zoom=18&maptype=satellite`}
        ></iframe>
      ) : (
        <iframe
          width="100%"
          title="Google Streetview"
          height={MAP_HEIGHT_PX}
          style={{ border: 0, borderRadius: 4 }}
          src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&location=${address.coordinates.lat},${address.coordinates.lon}&fov=75`}
          allowFullScreen
          id="embed-streetview"
        ></iframe>
      )}
    </Paper>
  );
}
