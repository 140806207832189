/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useInspectIssueIds = () => {
  const [searchParams] = useSearchParams();
  const issueId = searchParams.get("issueId");
  const monitorId = searchParams.get("monitorId");
  return useMemo(() => ({ issueId, monitorId }), [issueId, monitorId]);
};

export const useCloseIssueDetailModal = () => {
  const [_, setSearchParams] = useSearchParams();
  return useCallback(() => {
    setSearchParams((params) => {
      params.delete("issueId");
      params.delete("monitorId");

      return params;
    });
  }, [setSearchParams]);
};

export const useOpenIssueDetailModal = () => {
  const [_, setSearchParams] = useSearchParams();
  const close = useCloseIssueDetailModal();

  return useCallback(
    (issueId: UUID, monitorId?: UUID | null | undefined) => {
      setSearchParams((params) => {
        params.set("issueId", issueId);
        if (monitorId) {
          params.set("monitorId", monitorId);
        }

        return params;
      });

      return close;
    },
    [setSearchParams, close]
  );
};
