import { ReactNode, useState } from "react";
import WebsiteStatusCheckBody from "./WebsiteStatusCheck";
import Context from "./context";

export { useWebsiteStatusCheck } from "./hooks";

export default function DomainFinder({
  children,
  initialIsOpen = false,
}: {
  children?: ReactNode;
  initialIsOpen?: boolean;
}) {
  const value = useState(initialIsOpen);
  const [isOpen, setIsOpen] = value;

  return (
    <Context.Provider value={value}>
      {children}
      <WebsiteStatusCheckBody
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onResetState={() => void 0}
      />
    </Context.Provider>
  );
}
