import { Fragment } from "react";
import { sortBy, uniqBy } from "lodash";
import { Link, Typography } from "@mui/material";

import { getPrettyLongPolicyName, intersperse } from "./utils";
import WebOnly from "../../WebOnly";
import PrintOnly from "../../PrintOnly";
import {
  APIPolicyAnalysisAnalysis,
  APIPolicyAnalysisSource,
} from "../../../types/APIPolicyAnalysis";

export interface Props {
  analysis: APIPolicyAnalysisAnalysis | null | undefined;
  allSources: APIPolicyAnalysisSource[] | null | undefined;
  onSelectCitationByURL: (url?: string | null) => void;
  currentSourceURL?: string | null | undefined;
}

export default function Citations({
  analysis,
  allSources,
  onSelectCitationByURL,
  currentSourceURL,
}: Props) {
  if (!analysis || !allSources) return null;

  const resolvedSources = allSources
    .map((source, idx) => ({ ...source }))
    .filter((source) => analysis.sources.includes(source.url || ""))
    .filter((source) => {
      if (!currentSourceURL) return true;
      return source.url !== currentSourceURL;
    })
    .map((source) => ({
      url: source.url,
      name: getPrettyLongPolicyName(source.title || source.type),
    }));

  if (!resolvedSources.length) return null;

  const sortedSources = sortBy(uniqBy(resolvedSources, "name"), "name");
  const sourceLinks = sortedSources.map(({ name, url }) => (
    <Fragment key={`${url}_name`}>
      <WebOnly>
        <Link
          onClick={() => onSelectCitationByURL(url)}
          sx={{ cursor: "pointer" }}
        >
          {name}
        </Link>
      </WebOnly>
      <PrintOnly>
        <Typography
          fontWeight={600}
          sx={{ display: "inline" }}
          component="span"
        >
          {name}
        </Typography>
      </PrintOnly>
    </Fragment>
  ));

  return (
    <Fragment>
      ({currentSourceURL ? `Additional sources: ` : `Sources: `}
      {intersperse(sourceLinks, (l, r) => (
        <Fragment key={`${l.key}__${r.key}`}>, </Fragment>
      ))}
      )
    </Fragment>
  );
}
