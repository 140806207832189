import { Box, Typography } from "@mui/material";
import type { BoxProps } from "@mui/material";

export type Props = BoxProps;

export default function AnalysisText({ children, style = {}, ...etc }: Props) {
  if (!children) return null;

  return (
    <Box
      style={{
        padding: "1em",
        backgroundColor: "#F9F8FD",
        borderRadius: "3px",
        border: "1px solid",
        borderColor: "#E1E1F9",
        ...style,
      }}
      {...etc}
    >
      <Typography>{children}</Typography>
    </Box>
  );
}
