import EnableMonitoringButton from "../Monitoring/EnrollmentButton";

export interface Props {
  domain: string;
  disabled: boolean;
}

export default function InlineEnableMonitoringButton({
  domain,
  disabled,
}: Props) {
  return (
    <EnableMonitoringButton
      domain={domain}
      disabled={disabled}
      sx={{ marginLeft: 2 }}
    />
  );
}
