import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";

import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import MonitoringIcon from "./monitoring-icon.svg";

export interface Props {
  domain: string;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
  onDelete: () => Promise<void>;
}

export default function Confirmation({
  domain,
  onCancel,
  onDelete,
  onSuccess,
  onError,
}: Props) {
  const [isWorking, setIsWorking] = useState(false);
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignItems: "center",
        mb: "2em",
      }}
      flex={1}
    >
      <img src={MonitoringIcon} width={100} alt="" />
      <Typography align="center">
        Unenroll Merchant
        <br />
        in merchant monitoring
      </Typography>

      <Typography
        color={theme.palette.error.main}
        flex={1}
        mt={5}
        maxWidth={"90%"}
        textAlign="center"
      >
        Are you sure you want to unenroll {domain}?<br />
        <strong>This action cannot be undone.</strong>
      </Typography>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "1.5em",
          marginTop: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
          disabled={isWorking}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={isWorking}
          onClick={async () => {
            try {
              setIsWorking(true);
              await onDelete();
              onSuccess();
            } catch (e) {
              setIsWorking(false);
              onError();
            }
          }}
        >
          Unenroll merchant
        </LoadingButton>
      </div>
    </Stack>
  );
}
