import { useFormik } from "formik";
import ISO6391 from "iso-639-1";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { array, object, string } from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { flatten, sortBy, uniqBy } from "lodash";
import { getCountries } from "./utils/getCountries";
import { getSuggestedLanguages } from "./utils/getSuggestedLanguages";
import { OverrideListType } from "./types";

interface FormData {
  languages: string[];
  overrideListType: OverrideListType;
}

interface Language {
  code: string;
  name: string;
}

const rawLanguages = getCountries().map((country) => {
  return country.languages;
});
const languages: Language[] = sortBy(
  uniqBy(
    flatten(rawLanguages).map((code) => {
      return { code: code, name: ISO6391.getName(code) };
    }),
    "code"
  ),
  "code"
);

const LanguagesStepSchema = object({
  languages: array().when("overrideListType", {
    is: (overrideListType: string) => overrideListType === "permit",
    then: () =>
      array()
        .min(1, "You must select at least one language to permit.")
        .required(),
    otherwise: () =>
      array()
        .max(
          Object.values(languages).length - 1,
          "You cannot deny all languages."
        )
        .required(),
  }),
  overrideListType: string().oneOf(["permit", "deny"]).required(),
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LanguagesStep({
  selectedValues,
  overrideListType,
  onStepCompletion,
  onStepBack,
  onReset,
  countriesSelections,
}: {
  selectedValues: string[];
  overrideListType: OverrideListType;
  onStepCompletion: (
    languages: string[],
    overrideListType: OverrideListType
  ) => void;
  onStepBack: () => void;
  onReset: () => void;
  countriesSelections: string[];
}) {
  const suggestedLanguageCodes = getSuggestedLanguages(countriesSelections);
  const initialLanguages =
    selectedValues.length > 0 ? selectedValues : suggestedLanguageCodes;

  const formik = useFormik({
    initialValues: {
      languages: initialLanguages,
      overrideListType: overrideListType,
    },
    onSubmit: (values: FormData) =>
      onStepCompletion(values.languages, values.overrideListType),
    validationSchema: LanguagesStepSchema,
  });

  return (
    <Grid container spacing={2}>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <Typography id="override-type-label">
            Do you want to <strong>deny</strong> or <strong>permit</strong> the
            selected languages when a lookup is ran?
          </Typography>
          <RadioGroup
            aria-labelledby="override-type-label"
            name="overrideListType"
            onChange={formik.handleChange}
            defaultValue={formik.initialValues.overrideListType}
          >
            <FormControlLabel
              value={OverrideListType.PERMIT.toString()}
              control={<Radio />}
              label="Permit"
            />
            <FormControlLabel
              value={OverrideListType.DENY.toString()}
              control={<Radio />}
              label="Deny"
            />
          </RadioGroup>
          {formik.errors.overrideListType &&
            formik.touched.overrideListType && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.overrideListType}
              </Alert>
            )}
        </Grid>

        <hr />

        <FormGroup sx={{ mt: 5, mb: 1 }}>
          <FormControl>
            <Typography>
              What languages do you want to{" "}
              <strong>
                {formik.values.overrideListType === OverrideListType.DENY
                  ? "deny"
                  : "allow"}
              </strong>
              ?
            </Typography>
            <Autocomplete
              sx={{ mt: 1, width: "100%" }}
              fullWidth={true}
              multiple
              options={languages}
              value={languages.filter((language) => {
                return formik.values.languages.includes(language.code);
              })}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => {
                return option.code === value.code;
              }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValues) => {
                formik.setFieldTouched("languages");
                formik.setFieldValue(
                  "languages",
                  newValues.map((value) => {
                    return value.code;
                  })
                );
              }}
            />
            {formik.errors.languages && formik.touched.languages && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.languages}
              </Alert>
            )}
          </FormControl>
        </FormGroup>

        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button variant="outlined" size="large" onClick={onStepBack}>
                Back
              </Button>

              <Button
                variant="outlined"
                color="error"
                size="large"
                sx={{ ml: 1 }}
                onClick={onReset}
              >
                Start Over
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button variant="contained" size="large" type="submit">
                Next
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}
