import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  Verified as VerifiedIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import Filters from "./Filters";
import SearchStatsSummary from "../SearchStatsSummary";
import APIPagedCompanyLookup from "../../types/APIPagedCompanyLookup";
import { useTrueBizApi } from "../../api";
import { tzAwareHumanizeTimeString } from "../../utilities/formatting";
import { useDebounce } from "../../hooks";

const DEBOUNCE_FILTER_UPDATE_MS = 500;
const RESULTS_PER_PAGE = 15;

export default function SearchHistory() {
  const api = useTrueBizApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pastSearches, setPastSearches] =
    useState<APIPagedCompanyLookup | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const {
    search_term: searchTerm = "",
    page: pageString = "1",
    min_date: minDate = "",
    max_date: maxDate = "",
  } = Object.fromEntries(searchParams);

  const page = parseInt(pageString, 10);
  const debouncedSearchTerm = useDebounce(
    searchTerm,
    DEBOUNCE_FILTER_UPDATE_MS
  );

  useEffect(() => {
    const lookup = async () => {
      try {
        const offset = (page - 1) * RESULTS_PER_PAGE;
        setLoading(true);
        setPastSearches(
          await api.getCompanyLookups(
            debouncedSearchTerm,
            minDate,
            maxDate,
            offset,
            RESULTS_PER_PAGE
          )
        );
      } finally {
        setLoading(false);
      }
    };

    lookup();
    return () => {
      // TODO: Cancelable promise
    };
  }, [api, page, minDate, maxDate, debouncedSearchTerm]);

  useEffect(() => {
    if (page) window.scrollTo(0, 0);
  }, [page]);

  const setPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(page),
    });
  };

  const setSearchTerm = (searchTerm: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: "1",
      search_term: searchTerm,
    });
  };

  const setMinDate = (date: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: "1",
      min_date: date,
    });
  };

  const setMaxDate = (date: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: "1",
      max_date: date,
    });
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" fontWeight={700} gutterBottom>
        Search History{" "}
        {loading && <CircularProgress size={28} sx={{ marginLeft: 1 }} />}
      </Typography>

      <SearchStatsSummary />

      <Filters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        minDate={minDate}
        setMinDate={setMinDate}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
      />

      <Paper elevation={2} sx={{ p: 0 }}>
        <TableContainer component={Box} sx={{ marginTop: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Search</strong>
                </TableCell>

                <TableCell>
                  <strong>Time Run</strong>
                </TableCell>

                <TableCell align="center">
                  <strong>Decision</strong>
                </TableCell>

                <TableCell align="right">
                  <strong>Risk Count</strong>
                </TableCell>

                <TableCell align="right">
                  <strong>Validity Count</strong>
                </TableCell>

                <TableCell align="right">
                  <strong>Notice Count</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(pastSearches?.items || []).map((search) => {
                const url = `/search/${encodeURIComponent(
                  search.search_term || "offline"
                )}/${encodeURIComponent(search.id)}?${searchParams.toString()}`;

                return (
                  <TableRow
                    hover
                    onClick={() => navigate(url)}
                    key={search.id}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <NavLink
                        aria-label={search.search_term}
                        to={url}
                        onClick={(e) => {
                          // prevent multiple history entries being created for the same navigation
                          // target. caused when the row click handler is also fired.
                          e.stopPropagation();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography color="primary.main" fontWeight={600}>
                          {search.search_term === null
                            ? search.search_query?.submitted_business_name
                            : search.search_term}
                        </Typography>
                      </NavLink>
                    </TableCell>

                    <TableCell>
                      {tzAwareHumanizeTimeString(search.created_at)}
                    </TableCell>

                    <TableCell align="center">
                      {"recommendation" in search.response_data ? (
                        search.response_data.recommendation?.decision.toLowerCase() ===
                        "pass" ? (
                          <VerifiedIcon color="success" />
                        ) : (
                          <ErrorIcon color="error" />
                        )
                      ) : (
                        "N/A"
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {"risks" in search.response_data
                        ? search.response_data.risks.risk.length
                        : "N/A"}
                    </TableCell>

                    <TableCell align="right">
                      {"risks" in search.response_data
                        ? search.response_data.risks.validity.length
                        : "N/A"}
                    </TableCell>

                    <TableCell align="right">
                      {"risks" in search.response_data
                        ? search.response_data.risks.notice.length
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {pastSearches && (
        <Box
          display="flex"
          justifyContent="center"
          paddingTop={3}
          paddingBottom={2}
        >
          <Pagination
            siblingCount={3}
            count={Math.ceil(pastSearches.count / RESULTS_PER_PAGE)}
            page={page}
            onChange={(_: React.ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
