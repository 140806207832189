import { useState } from "react";
import {
  Typography,
  Link,
  Button,
  Modal,
  Paper,
  Box,
  IconButton,
} from "@mui/material";
import { OpenInFull as OpenInFullIcon } from "@mui/icons-material";

import ConnectedEntityDetail from "./ConnectedEntityDetail";
import Tooltip from "../Tooltip";
import APIConnectedEntity from "../../types/APIConnectedEntity";
import isReadOnly from "../../utilities/isReadOnly";

export default function ConnectedEntityDetailModal({
  connectedEntity,
}: {
  connectedEntity: APIConnectedEntity;
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip
        title={"Expand to see more details on this entity."}
        placement="right"
        arrow
      >
        <IconButton onClick={handleOpen}>
          <OpenInFullIcon color="primary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box marginBottom={4} position="relative">
            <Typography variant="h6" component="h3">
              Connected Entity Detail
            </Typography>
            {connectedEntity.domain && !isReadOnly() && (
              <Link
                href={`${window.location.origin}/domain/${connectedEntity.domain}?run=1`}
                target="_blank"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Button variant="contained">
                  run full web presence review
                </Button>
              </Link>
            )}
          </Box>
          <ConnectedEntityDetail connectedEntity={connectedEntity} />
        </Paper>
      </Modal>
    </>
  );
}
