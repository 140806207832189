import { useContext } from "react";

import ActiveMonitorsDrawerContext from "./context";

export const useActiveMonitorsDrawer = () => {
  const api = useContext(ActiveMonitorsDrawerContext);
  if (api === null)
    throw new Error(
      "useActiveMonitorsDrawer must be used inside an ActiveMonitorsDrawer"
    );

  return api;
};
