import { memo, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  tableFilterByToServerFilterBy,
  tableOrderByToServerOrderBy,
  serverNotificationToTableIssue,
  messageToAlert,
  useGetIssuesByIds,
} from "./utils";
import { useTrueBizApi } from "../../../api";
import {
  APINotificationStatus,
  APINotificationType,
} from "../../../types/APINotification";
import IssuesTable, { Prefetch } from "../IssuesTable";
import { TableFilterBy, TableOrderBy } from "../IssuesTable/types";

const useGetIssues = () => {
  const api = useTrueBizApi();

  return useCallback(
    async ({
      limit,
      offset,
      fuzzySearch,
      filterBy,
      orderBy,
    }: {
      limit: number;
      offset: number;
      fuzzySearch: string;
      filterBy: TableFilterBy[];
      orderBy: TableOrderBy[];
    }) => {
      const notifications = await api.getNotifications({
        limit,
        offset,
        fuzzySearch,
        filterBy: [
          {
            column: "type",
            operator: "in",
            value: [APINotificationType.SiteContent],
          },
          {
            column: "status",
            operator: "in",
            value: [APINotificationStatus.Resolved],
          },
          ...tableFilterByToServerFilterBy(filterBy),
        ],
        orderBy: tableOrderByToServerOrderBy(orderBy),
      });

      return {
        ...notifications,
        items: notifications.items.map(serverNotificationToTableIssue),
      };
    },
    [api]
  );
};

const useGetUniqueValuesForAlerts = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api
      .getNotificationsMetaUniqueValuesForMessages({
        filterBy: [
          {
            column: "type",
            operator: "in",
            value: [APINotificationType.SiteContent],
          },
          {
            column: "status",
            operator: "in",
            value: [APINotificationStatus.Resolved],
          },
        ],
      })
      .then((messages) => messages.map(messageToAlert));
  }, [api]);
};

const useGetUniqueValuesForDomain = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api.getNotificationsMetaUniqueValuesForDomain({
      filterBy: [
        {
          column: "type",
          operator: "in",
          value: [APINotificationType.SiteContent],
        },
        {
          column: "status",
          operator: "in",
          value: [APINotificationStatus.Resolved],
        },
      ],
    });
  }, [api]);
};

const useGetUniqueValuesForStatus = () => {
  const api = useTrueBizApi();

  return useCallback(async () => {
    return api.getNotificationsMetaUniqueValuesForStatus({
      filterBy: [
        {
          column: "type",
          operator: "in",
          value: [APINotificationType.SiteContent],
        },
        {
          column: "status",
          operator: "in",
          value: [APINotificationStatus.Resolved],
        },
      ],
    });
  }, [api]);
};

const useGetUniqueValuesForAssignees = () => {
  const api = useTrueBizApi();

  return useCallback(
    () =>
      api
        .getNotificationsEligibleAssignees()
        .then(
          (users) =>
            [
              "unassigned",
              ...users.map((user) => user.email).filter((x) => !!x),
            ] as string[]
        ),
    [api]
  );
};

export const PrefetchResolvedIssues = memo(function PrefetchResolvedIssues() {
  const getIssues = useGetIssues();
  const getUniqueValuesForAssignees = useGetUniqueValuesForAssignees();
  const getUniqueValuesForAlerts = useGetUniqueValuesForAlerts();
  const getUniqueValuesForDomain = useGetUniqueValuesForDomain();
  const getUniqueValuesForStatus = useGetUniqueValuesForStatus();

  return (
    <Prefetch
      key="resolved issues"
      description="resolved issues"
      columns={["domain", "status", "date", "alerts", "assignee"]}
      getIssues={getIssues}
      getUniqueValuesForAssignees={getUniqueValuesForAssignees}
      getUniqueValuesForAlerts={getUniqueValuesForAlerts}
      getUniqueValuesForDomain={getUniqueValuesForDomain}
      getUniqueValuesForStatus={getUniqueValuesForStatus}
    />
  );
});

export interface Props {
  onOpenIssueDetail: (
    issueId: UUID,
    monitorId?: UUID | null | undefined
  ) => void;
}

export default function ResolvedIssues({ onOpenIssueDetail }: Props) {
  const queryClient = useQueryClient();
  const api = useTrueBizApi();

  const getIssues = useGetIssues();
  const getIssuesByIds = useGetIssuesByIds();
  const getUniqueValuesForAssignees = useGetUniqueValuesForAssignees();
  const getUniqueValuesForAlerts = useGetUniqueValuesForAlerts();
  const getUniqueValuesForDomain = useGetUniqueValuesForDomain();
  const getUniqueValuesForStatus = useGetUniqueValuesForStatus();

  return (
    <IssuesTable
      key="resolved issues"
      description="resolved issues"
      columns={["domain", "status", "date", "alerts", "assignee"]}
      selectable
      selectionActions={["export", "assign"]}
      getIssues={getIssues}
      getIssuesByIds={getIssuesByIds}
      getUniqueValuesForAssignees={getUniqueValuesForAssignees}
      getUniqueValuesForAlerts={getUniqueValuesForAlerts}
      getUniqueValuesForDomain={getUniqueValuesForDomain}
      getUniqueValuesForStatus={getUniqueValuesForStatus}
      onAssignIssuesToUser={async ({ issueIds, userId }) => {
        await api.bulkAssignNotifications(issueIds, userId);
      }}
      onAssignIssuesToUserSuccess={async () => {
        await queryClient.invalidateQueries({
          queryKey: ["my", "issues", "openIssueCount"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["all", "issues", "openIssueCount"],
        });
      }}
      onOptimisticallyUpdateIssuesAfterAssignment={({
        issueIds,
        userId,
        userEmail,
        items,
      }) =>
        items.map((item) => ({
          ...item,
          assignee: issueIds.includes(item.id)
            ? userId && userEmail
              ? { id: userId, email: userEmail }
              : null
            : item.assignee,
        }))
      }
      onOpenIssueDetail={onOpenIssueDetail}
    />
  );
}
