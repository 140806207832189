import { ReactNode, useEffect, useState } from "react";

export interface Props {
  byMs?: number;
  children: ReactNode;
}

export default function Delayed({ byMs = 100, children }: Props) {
  const [isVisible, setIsVisible] = useState(byMs === 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, byMs);
    return () => clearTimeout(timeout);
  }, [byMs]);

  return <>{isVisible ? children : null}</>;
}
