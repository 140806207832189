import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import { Grid, Link } from "@mui/material";
import Attribute from "./Attribute";
import BooleanAttribute from "./BooleanAttribute";
import Tooltip from "../Tooltip";
import APIDomain from "../../types/APIDomain";
import APIWebsiteContent from "../../types/APIWebsiteContent";
import { humanizeDateString } from "../../utilities/formatting";

export default function DomainAnalysis({
  websiteContent,
  websiteContentProperties,
  domain,
  domainProperties,
}: {
  websiteContent: APIWebsiteContent | null;
  websiteContentProperties: null | any;
  domain: APIDomain;
  domainProperties: null | any;
}) {
  return (
    <Grid
      container
      spacing={2}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 2 }}
    >
      <Grid item xs={4}>
        <Attribute
          label="Name"
          labelIcon={
            websiteContent?.evaluated_url ? (
              <Tooltip
                title={websiteContentProperties?.evaluated_url.description}
                placement="right"
                arrow
              >
                <Link
                  href={websiteContent.evaluated_url}
                  color="inherit"
                  sx={{ ml: 0.25 }}
                  target="_blank"
                  rel="noopener"
                >
                  <LinkIcon fontSize="inherit" />
                </Link>
              </Tooltip>
            ) : undefined
          }
        >
          {domain?.name}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        {domain?.registrar && (
          <Attribute
            label="Registrar"
            labelIcon={
              <Tooltip
                title={domainProperties?.registrar?.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {domain?.registrar?.name}
          </Attribute>
        )}
      </Grid>

      <Grid item xs={4}>
        {domain?.registrar && (
          <Attribute
            label="Registrar country"
            labelIcon={
              <Tooltip
                title="The country the domain registrar is based in."
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {domain?.registrar?.country}
          </Attribute>
        )}
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Registration date"
          labelIcon={
            <Tooltip
              title={domainProperties?.registration_date.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {humanizeDateString(domain?.registration_date)}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Last update date"
          labelIcon={
            <Tooltip
              title={domainProperties?.last_update_date.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {humanizeDateString(domain?.last_update_date)}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Expiration date"
          labelIcon={
            <Tooltip
              title={domainProperties?.expiration_date.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {humanizeDateString(domain?.expiration_date)}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Resolvable?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_resolvable.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_resolvable}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Registered?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_registered.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_registered}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Non-business domain?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_non_business.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_non_business}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Known free email host?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_known_free_email_host.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_known_free_email_host}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Known social media domain?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_known_social_media_domain.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_known_social_media_domain}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Known marketplace domain?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_known_marketplace_domain.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_known_marketplace_domain}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Known prominent domain?"
          labelIcon={
            <Tooltip
              title={domainProperties?.is_known_top_domain.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_known_top_domain}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanAttribute
          label="Known hosting provider domain?"
          labelIcon={
            <Tooltip
              title={
                domainProperties?.is_known_hosting_provider_domain.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
          value={domain?.is_known_hosting_provider_domain}
          trueIsPositive={false}
          failureText="Unknown"
        />
      </Grid>
    </Grid>
  );
}
