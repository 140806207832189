import { useCallback, useMemo, useContext } from "react";
import Context from "./context";

export const useWebsiteStatusCheck = () => {
  const ctx = useContext(Context);

  if (!ctx)
    throw new Error(
      "useWebsiteStatusCheck must be used within a WebsiteStatusCheck provider"
    );

  const setIsOpen = ctx[1];

  const show = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const hide = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return useMemo(() => ({ show, hide }), [show, hide]);
};
