import { Box, CircularProgress } from "@mui/material";

export function LoadingIframe() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 4,
      }}
    >
      <CircularProgress color="inherit" />

      <Box marginTop={3}>Loading content...</Box>
    </Box>
  );
}
