import { ReactNode, useState } from "react";
import DomainFinderBody from "./DomainFinder";
import Context from "./context";

export { useDomainFinder } from "./hooks";

export default function DomainFinder({
  children,
  initialIsOpen = false,
}: {
  children?: ReactNode;
  initialIsOpen?: boolean;
}) {
  const value = useState(initialIsOpen);
  const [isOpen, setIsOpen] = value;

  return (
    <Context.Provider value={value}>
      {children}
      <DomainFinderBody open={isOpen} onClose={() => setIsOpen(false)} />
    </Context.Provider>
  );
}
