import { useFormik } from "formik";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { array, object, string } from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { sortBy, uniqBy } from "lodash";
import { getCountries } from "./utils/getCountries";
import { getSuggestedCurrencies } from "./utils/getSuggestedCurrencies";
import { OverrideListType } from "./types";

interface Currency {
  name: string;
  code: string;
  displayName: string;
}

interface FormData {
  currencies: string[];
  overrideListType: OverrideListType;
}

const currencies: Currency[] = sortBy(
  uniqBy(
    getCountries().map((country) => {
      return {
        name: country.currency_name,
        code: country.currency,
        displayName: `${country.currency_name} (${country.currency})`,
      };
    }),
    "code"
  ),
  "name"
);

const CurrenciesStepSchema = object({
  currencies: array().when("overrideListType", {
    is: (overrideListType: string) => overrideListType === "permit",
    then: () =>
      array()
        .min(1, "You must select at least one currency to permit.")
        .required(),
    otherwise: () =>
      array()
        .max(currencies.length - 1, "You cannot deny all currencies.")
        .required(),
  }),
  overrideListType: string().oneOf(["permit", "deny"]).required(),
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CurrenciesStep({
  selectedValues,
  overrideListType,
  onStepCompletion,
  onStepBack,
  onReset,
  countriesSelections,
}: {
  selectedValues: string[];
  overrideListType: OverrideListType;
  onStepCompletion: (
    currencies: string[],
    overrideListType: OverrideListType
  ) => void;
  onStepBack: () => void;
  onReset: () => void;
  countriesSelections: string[];
}) {
  const suggestedCurrencyCodes = getSuggestedCurrencies(countriesSelections);
  const initialCurrencies =
    selectedValues.length > 0 ? selectedValues : suggestedCurrencyCodes;

  const formik = useFormik({
    initialValues: {
      currencies: initialCurrencies,
      overrideListType: overrideListType,
    },
    onSubmit: (values: FormData) => {
      onStepCompletion(values.currencies, values.overrideListType);
    },
    validationSchema: CurrenciesStepSchema,
  });

  return (
    <Grid container spacing={2}>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <FormControl>
            <Typography id="override-type-label">
              Do you want to <strong>deny</strong> or <strong>permit</strong>{" "}
              the selected currencies when a lookup is ran?
            </Typography>
            <RadioGroup
              aria-labelledby="override-type-label"
              name="overrideListType"
              onChange={formik.handleChange}
              defaultValue={formik.initialValues.overrideListType}
            >
              <FormControlLabel
                value={OverrideListType.PERMIT.toString()}
                control={<Radio />}
                label="Permit"
              />
              <FormControlLabel
                value={OverrideListType.DENY.toString()}
                control={<Radio />}
                label="Deny"
              />
            </RadioGroup>
            {formik.errors.overrideListType &&
              formik.touched.overrideListType && (
                <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                  {formik.errors.overrideListType}
                </Alert>
              )}
          </FormControl>
        </Grid>

        <hr />

        <FormGroup sx={{ mt: 5, mb: 1 }}>
          <FormControl>
            <Typography>
              What currencies do you want to{" "}
              <strong>
                {formik.values.overrideListType === OverrideListType.DENY
                  ? "deny"
                  : "allow"}
              </strong>
              ?
            </Typography>
            <Autocomplete
              sx={{ mt: 1, width: "100%" }}
              fullWidth={true}
              multiple
              options={currencies}
              value={currencies.filter((currency) => {
                return formik.values.currencies.includes(currency.code);
              })}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => {
                return option.code === value.code;
              }}
              getOptionLabel={(option) => {
                return option.displayName;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.displayName}
                </li>
              )}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValues) => {
                formik.setFieldTouched("currencies");
                formik.setFieldValue(
                  "currencies",
                  newValues.map((value) => {
                    return value.code;
                  })
                );
              }}
            />
            {formik.errors.currencies && formik.touched.currencies && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.currencies}
              </Alert>
            )}
          </FormControl>
        </FormGroup>

        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button variant="outlined" size="large" onClick={onStepBack}>
                Back
              </Button>

              <Button
                variant="outlined"
                color="error"
                size="large"
                sx={{ ml: 1 }}
                onClick={onReset}
              >
                Start Over
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button variant="contained" size="large" type="submit">
                Next
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}
