import type { Palette } from "@mui/material";
import { useTheme, Typography } from "@mui/material";

import LevelOfConcernIcon from "./LevelOfConcernIcon";

import { CustomerReviewAnalysisLevelOfConcern } from "../../../../types/APICustomerReviews";
import { Optional } from "../../../../utilities/types";

export const humanizeLevelOfConcern = (
  levelOfConcern: CustomerReviewAnalysisLevelOfConcern
) => {
  switch (levelOfConcern) {
    case CustomerReviewAnalysisLevelOfConcern.CRITICAL:
      return "Critically concerning issues";
    case CustomerReviewAnalysisLevelOfConcern.HIGH:
      return "Concerning issues";
    case CustomerReviewAnalysisLevelOfConcern.MEDIUM:
      return "Some issues";
    case CustomerReviewAnalysisLevelOfConcern.LOW:
      return "No concerning issues";
    default:
      return "Issues";
  }
};

export const getColorForLevelOfConcern = (
  levelOfConcern: CustomerReviewAnalysisLevelOfConcern,
  palette: Palette
) => {
  switch (levelOfConcern) {
    case CustomerReviewAnalysisLevelOfConcern.CRITICAL:
    case CustomerReviewAnalysisLevelOfConcern.HIGH:
      return palette.error.main;
    case CustomerReviewAnalysisLevelOfConcern.MEDIUM:
      return palette.warning.main;
    default:
      return palette.text.primary;
  }
};

export interface Props {
  levelOfConcern: Optional<CustomerReviewAnalysisLevelOfConcern>;
  hasFlags?: Optional<boolean>;
}

export default function LevelOfConcern({
  levelOfConcern,
  hasFlags = false,
}: Props) {
  const theme = useTheme();

  if (!levelOfConcern) return null;

  if (
    levelOfConcern === CustomerReviewAnalysisLevelOfConcern.LOW &&
    !hasFlags
  ) {
    return null;
  }

  return (
    <Typography
      mb="1em"
      component="div"
      fontWeight={
        [
          CustomerReviewAnalysisLevelOfConcern.CRITICAL,
          CustomerReviewAnalysisLevelOfConcern.HIGH,
        ].includes(levelOfConcern)
          ? 700
          : 400
      }
      color={getColorForLevelOfConcern(levelOfConcern, theme.palette)}
      display="flex"
      alignItems="center"
      gap="0.25em"
    >
      <LevelOfConcernIcon levelOfConcern={levelOfConcern} />
      <span>{humanizeLevelOfConcern(levelOfConcern)} detected</span>
    </Typography>
  );
}
