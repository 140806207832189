import { Paper, Grid, Typography } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";

import BooleanAttribute from "./BooleanAttribute";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIPhone from "../../types/APIPhone";

export default function SubmittedPhone({ phone }: { phone: APIPhone }) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <Typography variant="h6" component="h3" marginBottom={1}>
        Submitted Phone
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <BooleanAttribute
            label="Valid?"
            labelIcon={
              <Tooltip title="Not yet implemented." placement="right" arrow>
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            // only show validity signals, no validation failures
            value={phone.is_valid}
            trueIsPositive
            failureText="Unknown"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Attribute label="Line type">{phone.line_type}</Attribute>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Attribute label="Country">{phone.country_name}</Attribute>
        </Grid>
      </Grid>
    </Paper>
  );
}
