import { OFACS_COUNTRY_CODES } from "../constants";

export const isOfacsSet = (
  selectedCountryCodes: string[],
  strict: boolean
): boolean => {
  const ofacsSet = OFACS_COUNTRY_CODES.every((code) =>
    selectedCountryCodes.includes(code)
  );

  return strict
    ? ofacsSet && selectedCountryCodes.length === OFACS_COUNTRY_CODES.length
    : ofacsSet;
};
