import { useState, MouseEvent, Fragment } from "react";
import {
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Collapse,
  useTheme,
} from "@mui/material";
import type { BoxProps } from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { grey, lightBlue } from "@mui/material/colors";

import { Value } from "./BooleanAttribute";
import { useIsPrint } from "../PrintOnly";
import Tooltip from "../Tooltip";
import APIEmail from "../../types/APIEmail";
import APIPhone, { APIPhoneLinkType } from "../../types/APIPhone";
import {
  humanizePhoneLineType,
  humanizeISODuration,
  formatPhoneNumber,
  capitalizeFirstLetterOnly,
} from "../../utilities/formatting";

function SourceUrls({
  internalSourceUrls,
  externalSourceUrls,
}: {
  internalSourceUrls: string[];
  externalSourceUrls: string[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const total = internalSourceUrls.length + externalSourceUrls.length;

  if (total === 0) {
    return null;
  }

  return (
    <>
      <Button onClick={handleClick}>
        {total} source url{total > 1 && "s"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          style={{ pointerEvents: "none" }}
          sx={{ mt: -1, backgroundColor: grey[200] }}
        >
          <Typography variant="subtitle1">
            URLs where the email address was found.
          </Typography>
        </MenuItem>
        <MenuItem style={{ pointerEvents: "none" }}>
          <Typography color={grey[600]} variant="overline">
            External
          </Typography>
        </MenuItem>
        {externalSourceUrls.length ? (
          externalSourceUrls.map((url) => (
            <MenuItem
              color={lightBlue[400]}
              component="a"
              target="_blank"
              href={url}
              key={url}
            >
              <Typography color={lightBlue[800]}>{url}</Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ pointerEvents: "none" }}>
            <Typography>Not Found</Typography>
          </MenuItem>
        )}
        <MenuItem style={{ pointerEvents: "none" }}>
          <Typography color={grey[600]} variant="overline">
            Internal
          </Typography>
        </MenuItem>
        {internalSourceUrls.length ? (
          internalSourceUrls.map((url) => (
            <MenuItem component="a" target="_blank" href={url} key={url}>
              <Typography color={lightBlue[800]}>{url}</Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ pointerEvents: "none" }}>
            <Typography>Not Found</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function PhoneLineTypeExplanation(props: BoxProps) {
  return (
    <Box {...props}>
      <ul>
        <li>
          <strong>Landline</strong> - traditional wired phone line
        </li>
        <li>
          <strong>Mobile</strong> - wireless phone
        </li>
        <li>
          <strong>Fixed VoIP</strong> - VoIP number connected to a physical
          address, such as Vonix or Comcast
        </li>
        <li>
          <strong>Non-fixed VoIP</strong> - VoIP number unconnected to a
          physical address, such as Google Voice or Skype
        </li>
        <li>
          <strong>Toll-free</strong> - callee pays for call
        </li>
        <li>
          <strong>Voicemail</strong> - voicemail-only service
        </li>
      </ul>
    </Box>
  );
}

export default function ContactInfo({
  emailAddresses,
  primaryPhoneNumber,
  domain,
}: {
  emailAddresses: APIEmail[] | null;
  primaryPhoneNumber: APIPhone | null;
  domain: string | null;
}) {
  const isPrint = useIsPrint();
  const [expanded, setExpanded] = useState(isPrint);
  const theme = useTheme();
  let rows = [];
  if (primaryPhoneNumber) {
    const phoneNumberHasMoreDetails =
      !!primaryPhoneNumber.line_type ||
      primaryPhoneNumber.links_to_company.length > 0;

    rows.push({
      key: primaryPhoneNumber.number,
      contact: (
        <div>
          <div>
            {phoneNumberHasMoreDetails ? (
              <Button
                onClick={() => setExpanded((x) => !x)}
                startIcon={
                  <ExpandMoreIcon
                    sx={{
                      transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                      transition: theme.transitions.create("transform", {
                        duration: theme.transitions.duration.shortest,
                      }),
                    }}
                  />
                }
                sx={{ userSelect: "text" }}
              >
                <Typography component="span" sx={{ cursor: "text" }}>
                  {primaryPhoneNumber.is_valid
                    ? formatPhoneNumber(primaryPhoneNumber.number)
                    : primaryPhoneNumber.number}
                </Typography>
              </Button>
            ) : primaryPhoneNumber.is_valid ? (
              formatPhoneNumber(primaryPhoneNumber.number)
            ) : (
              primaryPhoneNumber.number
            )}
          </div>
          {phoneNumberHasMoreDetails && (
            <Collapse in={expanded} unmountOnExit>
              <ul>
                {primaryPhoneNumber.line_type && (
                  <Typography component="li">
                    {capitalizeFirstLetterOnly(
                      humanizePhoneLineType(primaryPhoneNumber.line_type)
                    )}{" "}
                    line
                    <Tooltip
                      title={<PhoneLineTypeExplanation />}
                      placement="right"
                      arrow
                    >
                      <HelpOutlineOutlinedIcon
                        fontSize="inherit"
                        sx={{ ml: 0.25, mb: -0.25 }}
                      />
                    </Tooltip>
                  </Typography>
                )}
                {primaryPhoneNumber.links_to_company.length > 0 &&
                  primaryPhoneNumber.links_to_company
                    .map(({ type, duration }) => {
                      const formattedDuration = duration
                        ? humanizeISODuration(duration)
                        : "";
                      let formattedLinkType = "";
                      switch (type) {
                        case APIPhoneLinkType.Direct:
                          formattedLinkType = `${
                            formattedDuration ? "has been" : "is"
                          } directly registered to the company`;
                          break;
                        case APIPhoneLinkType.Address:
                          formattedLinkType = `registration address ${
                            formattedDuration ? "has matched" : "matches"
                          } the company address`;
                          break;
                        case APIPhoneLinkType.Employee:
                          formattedLinkType = `${
                            formattedDuration ? "has been " : ""
                          }linked to current or former company employee`;
                          break;
                      }

                      const content = `${formattedLinkType}${
                        formattedDuration
                          ? ` for at least ${formattedDuration}`
                          : ""
                      }`;

                      if (!content?.trim()) {
                        return null;
                      }

                      return (
                        <Typography key={type} component="li">
                          {capitalizeFirstLetterOnly(content)}
                        </Typography>
                      );
                    })
                    .filter((x) => !!x)}
              </ul>
            </Collapse>
          )}
        </div>
      ),
      is_valid: primaryPhoneNumber.is_valid,
      internal_source_urls: [],
      external_source_urls: [],
    });
  }

  if (emailAddresses) {
    emailAddresses.forEach((email) => {
      rows.push({
        key: email.address,
        contact: <Typography variant="subtitle1">{email.address}</Typography>,
        is_valid: email.is_valid,
        internal_source_urls: email.internal_source_urls,
        external_source_urls: email.external_source_urls,
      });
    });
  }

  return (
    <Box data-testid="website-content-wrapper">
      {rows.length > 0 ? (
        <Grid container spacing={2} aria-label="contact info table">
          {rows.map((row, i) => (
            <Fragment key={row.key}>
              <Grid item xs={6}>
                {row.contact}
              </Grid>
              <Grid
                item
                container
                xs={3}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <SourceUrls
                  internalSourceUrls={row.internal_source_urls || []}
                  externalSourceUrls={row.external_source_urls || []}
                />
              </Grid>
              <Grid item container xs={3} alignItems="baseline">
                {row.is_valid !== null && (
                  <Value
                    trueIsPositive={true}
                    value={row.is_valid}
                    positiveText="Valid"
                    negativeText="Invalid"
                  />
                )}
              </Grid>
              {i !== rows.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Typography marginTop={1}>No contact info found</Typography>
      )}
    </Box>
  );
}
