import { useFormik } from "formik";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { array, object, string } from "yup";
import { union } from "lodash";
import { isOfacsSet } from "./utils/isOfacsSet";
import { getCountries } from "./utils/getCountries";
import {
  EEC_COUNTRY_CODES,
  EU_COUNTRY_CODES,
  OFACS_COUNTRY_CODES,
} from "./constants";
import { OverrideListType } from "./types";

const countries = getCountries();
const africaCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "africa";
  })
  .map((country) => {
    return country.alpha2;
  });
const northAmericaCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "north america";
  })
  .map((country) => {
    return country.alpha2;
  });
const southAmericaCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "south america";
  })
  .map((country) => {
    return country.alpha2;
  });
const europeCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "europe";
  })
  .map((country) => {
    return country.alpha2;
  });
const asiaCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "asia";
  })
  .map((country) => {
    return country.alpha2;
  });
const oceaniaCountryCodes = countries
  .filter((country) => {
    return country.continent.toLowerCase() === "oceania";
  })
  .map((country) => {
    return country.alpha2;
  });

interface FormData {
  countries: string[];
  overrideListType: OverrideListType;
}

const CountryStepSchema = object({
  countries: array().when("overrideListType", {
    is: (overrideListType: string) => overrideListType === "permit",
    then: () =>
      array()
        .min(1, "You must select at least one country to permit.")
        .required(),
    otherwise: () =>
      array()
        .max(countries.length - 1, "You cannot deny all countries.")
        .required(),
  }),
  overrideListType: string().oneOf(["permit", "deny"]).required(),
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CountryStep({
  selectedValues,
  overrideListType,
  onStepCompletion,
  onStepBack,
  onReset,
}: {
  selectedValues: string[];
  overrideListType: OverrideListType;
  onStepCompletion: (
    countries: string[],
    overrideListType: OverrideListType
  ) => void;
  onStepBack: () => void;
  onReset: () => void;
}) {
  const formik = useFormik({
    initialValues: {
      countries: selectedValues,
      overrideListType: overrideListType,
    },
    onSubmit: (values: FormData) => {
      onStepCompletion(values.countries, values.overrideListType);
    },
    validationSchema: CountryStepSchema,
  });

  const ofacsSet = isOfacsSet(formik.values.countries, false);
  const usSet = formik.values.countries.includes("US");
  const euSet = EU_COUNTRY_CODES.every((code) =>
    formik.values.countries.includes(code)
  );
  const eecSet = EEC_COUNTRY_CODES.every((code) =>
    formik.values.countries.includes(code)
  );
  const africaSet = africaCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );
  const northAmericaSet = northAmericaCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );
  const southAmericaSet = southAmericaCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );
  const europeSet = europeCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );
  const asiaSet = asiaCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );
  const oceaniaSet = oceaniaCountryCodes.every((code) =>
    formik.values.countries.includes(code)
  );

  return (
    <Grid container spacing={2}>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <FormControl>
            <Typography id="override-type-label">
              Do you want to <strong>deny</strong> or <strong>permit</strong>{" "}
              the selected countries when a lookup is ran?
            </Typography>
            <RadioGroup
              aria-labelledby="override-type-label"
              name="overrideListType"
              onChange={formik.handleChange}
              defaultValue={formik.initialValues.overrideListType}
              row
            >
              <FormControlLabel
                value={OverrideListType.PERMIT.toString()}
                control={<Radio />}
                label="Permit"
              />
              <FormControlLabel
                value={OverrideListType.DENY.toString()}
                control={<Radio />}
                label="Deny"
              />
            </RadioGroup>
            {formik.errors.overrideListType &&
              formik.touched.overrideListType && (
                <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                  {formik.errors.overrideListType}
                </Alert>
              )}
          </FormControl>
        </Grid>

        <hr />

        <Grid container item xs={12}>
          <FormGroup sx={{ mt: 5, mb: 1, width: "100%" }}>
            <FormControl>
              <Typography>
                What countries do you want to{" "}
                <strong>
                  {formik.values.overrideListType === OverrideListType.DENY
                    ? "deny"
                    : "allow"}
                </strong>
                ?
              </Typography>

              <Grid item xs={12} sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Chip
                    label={`${ofacsSet ? "Remove" : "Add"} OFACS`}
                    color={ofacsSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        ofacsSet
                          ? formik.values.countries.filter((code) => {
                              return !OFACS_COUNTRY_CODES.includes(code);
                            })
                          : union(formik.values.countries, OFACS_COUNTRY_CODES)
                      );
                    }}
                  />
                  <Chip
                    label={`${usSet ? "Remove" : "Set"} United States${
                      usSet ? "" : " Only"
                    }`}
                    color={usSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        usSet
                          ? formik.values.countries.filter((code) => {
                              return code.toLowerCase() !== "us";
                            })
                          : ["US"]
                      );
                    }}
                  />
                  <Chip
                    label={`${euSet ? "Remove" : "Add"} EU`}
                    color={euSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        euSet
                          ? formik.values.countries.filter((code) => {
                              return !EU_COUNTRY_CODES.includes(code);
                            })
                          : union(formik.values.countries, EU_COUNTRY_CODES)
                      );
                    }}
                  />
                  <Chip
                    label={`${eecSet ? "Remove" : "Add"} EEC`}
                    color={eecSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        eecSet
                          ? formik.values.countries.filter((code) => {
                              return !EEC_COUNTRY_CODES.includes(code);
                            })
                          : union(formik.values.countries, EEC_COUNTRY_CODES)
                      );
                    }}
                  />
                  <Chip
                    label={`${africaSet ? "Remove" : "Add"} Africa`}
                    color={africaSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        africaSet
                          ? formik.values.countries.filter((code) => {
                              return !africaCountryCodes.includes(code);
                            })
                          : union(formik.values.countries, africaCountryCodes)
                      );
                    }}
                  />
                  <Chip
                    label={`${asiaSet ? "Remove" : "Add"} Asia`}
                    color={asiaSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        asiaSet
                          ? formik.values.countries.filter((code) => {
                              return !asiaCountryCodes.includes(code);
                            })
                          : union(formik.values.countries, asiaCountryCodes)
                      );
                    }}
                  />
                  <Chip
                    label={`${europeSet ? "Remove" : "Add"} Europe`}
                    color={europeSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        europeSet
                          ? formik.values.countries.filter((code) => {
                              return !europeCountryCodes.includes(code);
                            })
                          : union(formik.values.countries, europeCountryCodes)
                      );
                    }}
                  />
                  <Chip
                    label={`${northAmericaSet ? "Remove" : "Add"} N. America`}
                    color={northAmericaSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        northAmericaSet
                          ? formik.values.countries.filter((code) => {
                              return !northAmericaCountryCodes.includes(code);
                            })
                          : union(
                              formik.values.countries,
                              northAmericaCountryCodes
                            )
                      );
                    }}
                  />
                  <Chip
                    label={`${oceaniaSet ? "Remove" : "Add"} Oceania`}
                    color={oceaniaSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        oceaniaSet
                          ? formik.values.countries.filter((code) => {
                              return !oceaniaCountryCodes.includes(code);
                            })
                          : union(formik.values.countries, oceaniaCountryCodes)
                      );
                    }}
                  />
                  <Chip
                    label={`${southAmericaSet ? "Remove" : "Add"} S. America`}
                    color={southAmericaSet ? "error" : "primary"}
                    variant="outlined"
                    onClick={() => {
                      formik.setFieldValue(
                        "countries",
                        southAmericaSet
                          ? formik.values.countries.filter((code) => {
                              return !southAmericaCountryCodes.includes(code);
                            })
                          : union(
                              formik.values.countries,
                              southAmericaCountryCodes
                            )
                      );
                    }}
                  />
                </Box>
              </Grid>

              <Autocomplete
                sx={{ mt: 2, width: "100%" }}
                fullWidth={true}
                multiple
                options={countries.sort((a, b) => {
                  return a.continent < b.continent
                    ? -1
                    : a.continent > b.continent
                    ? 1
                    : 0;
                })}
                value={countries.filter((country) => {
                  return formik.values.countries.includes(country.alpha2);
                })}
                groupBy={(country) => {
                  return country.continent;
                }}
                disableCloseOnSelect
                isOptionEqualToValue={(option, value) => {
                  return option.alpha2 === value.alpha2;
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, newValues) => {
                  formik.setFieldTouched("countries");
                  formik.setFieldValue(
                    "countries",
                    newValues.map((value) => {
                      return value.alpha2;
                    })
                  );
                }}
              />
              {formik.errors.countries && formik.touched.countries && (
                <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                  {formik.errors.countries}
                </Alert>
              )}
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button variant="outlined" size="large" onClick={onStepBack}>
                Back
              </Button>

              <Button
                variant="outlined"
                color="error"
                size="large"
                sx={{ ml: 1 }}
                onClick={onReset}
              >
                Start Over
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button variant="contained" size="large" type="submit">
                Next
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}
