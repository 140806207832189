import { Fragment, ReactNode } from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";

import {
  UpdateOutlined,
  VerifiedOutlined,
  FactoryOutlined,
  LanguageOutlined,
  TranslateOutlined,
  DifferenceOutlined,
  AlternateEmailOutlined,
  Report,
} from "@mui/icons-material";
import { isNil } from "lodash";

import PolicyFact from "./PolicyFact";
import Citations from "./Citations";
import {
  getHasSuspiciousContentForPolicy,
  getPrettyLongPolicyName,
  orderAnalysisQuestions,
} from "./utils";
import { OutlinedSourceIcon } from "./SourceTypeIcons";
import PrintOnly from "../../PrintOnly";
import WebOnly from "../../WebOnly";
import {
  APIPolicyAnalysisAnalysis,
  APIPolicyAnalysisSource,
} from "../../../types/APIPolicyAnalysis";

export interface Props {
  policy: Exclude<APIPolicyAnalysisSource, "synopsis"> & {
    synopsis: APIPolicyAnalysisSource["synopsis"] | ReactNode;
  };
  relatedAnalyses: APIPolicyAnalysisAnalysis[] | null | undefined;
  allPolicySources: APIPolicyAnalysisSource[] | null | undefined;
  onSelectPolicyByURL: (url?: string | null | undefined) => void;
  showFacts?: boolean;
  hideEmptyFacts?: boolean;
  hasSuspiciousContent?: boolean | null | undefined;
}

function PolicyFacts({
  policy,
  hideEmptyFacts = false,
  isSus = false,
  ncols = 2,
}: {
  policy: Pick<
    APIPolicyAnalysisSource,
    | "last_updated"
    | "company_name"
    | "url"
    | "language"
    | "change_policy"
    | "policy_contact"
  >;
  hideEmptyFacts?: boolean;
  isSus?: boolean;
  ncols?: number;
}) {
  return (
    <Grid container sx={{ mb: "1em" }}>
      {(hideEmptyFacts ? isSus != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            value={
              isSus ? "Suspicious content detected" : "No risk indicators found"
            }
            icon={isSus ? Report : VerifiedOutlined}
            isSuspicious={isSus}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.last_updated != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Last Updated"
            value={policy.last_updated}
            icon={UpdateOutlined}
            isEmpty={!!policy.last_updated}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.company_name != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Covered Entity Name"
            value={policy.company_name}
            icon={FactoryOutlined}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.url != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Policy URL"
            value={
              policy.url && (
                <a href={policy.url} target="_blank" rel="noreferrer">
                  {policy.url}
                </a>
              )
            }
            icon={LanguageOutlined}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.language != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Policy Language"
            value={policy.language}
            icon={TranslateOutlined}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.change_policy != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Change Policy"
            value={policy.change_policy}
            icon={DifferenceOutlined}
          />
        </Grid>
      )}
      {(hideEmptyFacts ? policy.policy_contact != null : true) && (
        <Grid item xs={12 / ncols}>
          <PolicyFact
            name="Policy Contact"
            value={policy.policy_contact}
            icon={AlternateEmailOutlined}
          />
        </Grid>
      )}
    </Grid>
  );
}

function PolicyBody({
  policy,
  relatedAnalyses,
  allPolicySources,
  onSelectPolicyByURL,
}: {
  policy: Omit<APIPolicyAnalysisSource, "synopsis"> & {
    synopsis: ReactNode | APIPolicyAnalysisSource["synopsis"];
  };
  relatedAnalyses: null | undefined | APIPolicyAnalysisAnalysis[];
  allPolicySources: null | undefined | APIPolicyAnalysisSource[];
  onSelectPolicyByURL: (url?: string | null | undefined) => void;
}) {
  return (
    <Fragment>
      <Typography variant="body1" sx={{ mb: "1.5em" }}>
        {policy.synopsis ||
          `TrueBiz detected a ${getPrettyLongPolicyName(
            policy.title || policy.type
          )}.`}
      </Typography>

      {!!relatedAnalyses?.length &&
        !!relatedAnalyses.filter((analysis) => analysis.answer != null)
          .length && (
          <Typography variant="h6" sx={{ mb: "0.5em" }}>
            Related Analyses
          </Typography>
        )}
      {orderAnalysisQuestions(relatedAnalyses)
        .filter((analysis) => analysis.answer != null)
        .map((analysis) => {
          return (
            <Fragment key={analysis.question}>
              <Typography variant="body1" sx={{ mb: "0.25em" }}>
                <strong>{analysis.question}</strong>
              </Typography>
              <Typography variant="body1" sx={{ mb: "1.5em" }} component="div">
                {analysis.answer}{" "}
                <Citations
                  analysis={analysis}
                  allSources={allPolicySources}
                  onSelectCitationByURL={onSelectPolicyByURL}
                  currentSourceURL={policy.url}
                />
              </Typography>
            </Fragment>
          );
        })}
    </Fragment>
  );
}

export default function Policy({
  policy,
  relatedAnalyses,
  allPolicySources,
  onSelectPolicyByURL,
  showFacts = true,
  hideEmptyFacts = false,
  hasSuspiciousContent,
}: Props) {
  // we only want to consider these conditions if they're
  // actually defined
  const isSus = [hasSuspiciousContent, getHasSuspiciousContentForPolicy(policy)]
    .filter((x) => !isNil(x)) // typescript is too dumb to realize that this narrows the type to boolean[]
    .reduce((acc, condition) => acc || condition, false) as boolean;

  return (
    <Box>
      <WebOnly>
        <Typography variant="h6" sx={{ mb: "0.5em" }} component="div">
          {getPrettyLongPolicyName(policy.title || policy.type)}
        </Typography>
        {showFacts && (
          <PolicyFacts
            policy={policy}
            isSus={isSus}
            hideEmptyFacts={hideEmptyFacts}
          />
        )}
        <PolicyBody
          policy={policy}
          relatedAnalyses={relatedAnalyses}
          allPolicySources={allPolicySources}
          onSelectPolicyByURL={onSelectPolicyByURL}
        />
      </WebOnly>

      <PrintOnly>
        <Typography variant="h6" sx={{ mb: "0.75em" }} component="div">
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.75em" }}>
            <OutlinedSourceIcon type={policy.type} />
            {getPrettyLongPolicyName(policy.title || policy.type)}
          </Box>
          <Divider sx={{ mt: "0.25em" }} />
        </Typography>
        <Grid container columnGap={1}>
          <Grid item xs={7}>
            <Box sx={{ pr: "1em" }}>
              <PolicyBody
                policy={policy}
                relatedAnalyses={relatedAnalyses}
                allPolicySources={allPolicySources}
                onSelectPolicyByURL={onSelectPolicyByURL}
              />
            </Box>
          </Grid>
          {showFacts && (
            <Grid item xs={4}>
              <PolicyFacts
                policy={policy}
                isSus={isSus}
                hideEmptyFacts={hideEmptyFacts}
                ncols={1}
              />
            </Grid>
          )}
        </Grid>
      </PrintOnly>
    </Box>
  );
}
