import { SVGProps } from "react";
import cn from "classnames";

import styles from "./Logo.module.css";

export interface Props extends SVGProps<SVGSVGElement> {
  reversed?: boolean;
}

export default function Logo({ reversed = false, className, ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 105.02"
      className={cn(className, styles.logo, { [styles.reversed]: reversed })}
      {...props}
    >
      <path d="M33,26.56V103H52.89V26.56ZM0,0V16.58H85.91V0Z" />
      <path d="M149.59,55.85c13.95-4.3,21.79-14.09,21.79-27C171.38,11.23,156.86,0,133.59,0H99.52V103h19.54V58.81h.19v-16h-.19V16h13.33c11.76,0,18.54,4.15,18.54,12.9,0,8.45-6.78,14-18.54,14h-3.15V59.15L162.06,103h24.28Z" />
      <path d="M257.24,0V60.44c0,15.24-7.26,27-23.84,27-16.73,0-24-11.71-24-26.95V0H189.54V59.68c0,29.47,15.86,45.34,43.86,45.34s43.71-15.87,43.71-45.34V0Z" />
      <path d="M329.43,43.1V59.68h34.16V43.1Zm-10,43.24V16.58h50.64V0H299.62V103h71.95V86.34Z" />
      <path d="M443.29,49.74v-.58c13.61-3.53,20.25-12.28,20.25-23C463.54,11.37,452.17,0,427.71,0H389.49V103h41.8c24.28,0,37.32-13.66,37.32-28.76C468.61,62.78,461.06,52.89,443.29,49.74ZM425.37,87H409V16h15.29c10.65,0,19.4,3,19.4,13,0,10.23-8.75,13.47-19.4,13.47h-5.26v16h6.31c13.33,0,22.79,2.68,22.79,14.24C448.16,83.66,438.7,87,425.37,87Z" />
      <path d="M485.62,0V103h19.83V0Z" />
      <path d="M544.43,86.34v-.58L598,17.15V0H524.89V16.58h50.79v.57L522.22,85.76V103H600V86.34Z" />
    </svg>
  );
}
