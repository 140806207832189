import {
  Button,
  CircularProgress,
  useTheme,
  Alert,
  Snackbar,
} from "@mui/material";
import type { ButtonProps } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useCallback, useState } from "react";
import { backOff } from "exponential-backoff";
import { saveAs } from "file-saver";
import { useTrueBizApi } from "../../api";
import APICompany from "../../types/APICompany";

export interface Props extends ButtonProps {
  response: APICompany;
}

export default function DownloadPdfButton({ response, ...etc }: Props) {
  const api = useTrueBizApi();
  const theme = useTheme();
  const [notReady, setNotReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [pdfData, setPdfData] = useState<any>(null);

  const onDownloadPdf = useCallback(() => {
    if (!response || !response.tracking_id) return;
    const trackingId = response.tracking_id;

    const filename = getFileName(response);

    if (pdfData) {
      saveAs(pdfData, filename);
      return;
    }

    setToastOpen(false);
    setLoading(true);
    setFailed(false);
    setNotReady(false);

    class PdfGenerationIncompleteError extends Error {}

    backOff(
      async () => {
        const pdfSummary = await api.getCompanyLookupPdfSummary(trackingId);

        if (pdfSummary.status === 202) {
          throw new PdfGenerationIncompleteError();
        }

        if (pdfSummary.status === 204 || !pdfSummary.blob) {
          throw new Error("Report has no content");
        }

        setPdfData(pdfSummary.blob);
        saveAs(pdfSummary.blob, filename);
      },
      {
        maxDelay: 30_000,
        startingDelay: 3_000,
        numOfAttempts: 4,
        delayFirstAttempt: false,
        retry: (e, attemptNumber) => {
          if (e instanceof PdfGenerationIncompleteError) {
            console.error(
              `[getCompanyLookupPdfSummary]: attempt ${attemptNumber} failed, PDF is still generating, retrying.`
            );
            return true;
          }
          console.error(`[getCompanyLookupPdfSummary]: ${e}`);
          return false;
        },
      }
    )
      .catch((e) => {
        if (e instanceof PdfGenerationIncompleteError) {
          setNotReady(true);
        } else {
          setFailed(true);
        }
        setToastOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, response, pdfData]);

  let errorMessage;
  if (failed) {
    errorMessage =
      "An unexpected error occurred and the request could not be completed. Please try again.";
  } else if (notReady) {
    errorMessage = "The PDF is still being generated. Please try again.";
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={toastOpen}
        autoHideDuration={8000}
        onClose={() => setToastOpen(false)}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Button
        variant="outlined"
        size="small"
        startIcon={
          loading ? (
            <CircularProgress
              size={12}
              style={
                loading ? { color: theme.palette.action.disabled } : undefined
              }
            />
          ) : failed ? (
            <ErrorOutlineIcon />
          ) : notReady ? (
            <AccessTimeIcon />
          ) : (
            <PictureAsPdfIcon />
          )
        }
        disabled={loading || !response.tracking_id}
        onClick={onDownloadPdf}
        title={
          loading
            ? "Fetching PDF summary..."
            : failed
            ? "There was a problem fetching the PDF summary, please try again shortly"
            : "Download PDF summary"
        }
        {...etc}
      >
        {loading ? "Generating" : "Generate PDF"}
      </Button>
    </>
  );
}

const getFileName = (response: APICompany): string => {
  if (response?.domain?.name) {
    return `web_presence_review_${response.domain.name}.pdf`;
  } else if (response.name) {
    return `web_presence_review_${response.name}.pdf`;
  }

  return `web_presence_review_${response.tracking_id}.pdf`;
};
