import { Button, Stack, Typography, useTheme } from "@mui/material";

import SuccessIcon from "./success-icon.svg";

export interface Props {
  domain: string;
  nextRun?: Date | null | undefined;
  onDone: () => void;
}

export default function Success({ domain, nextRun, onDone }: Props) {
  const theme = useTheme();
  return (
    <>
      <Stack
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "6em",
        }}
      >
        <img src={SuccessIcon} width={175} alt="" />
        <h3 style={{ color: theme.palette.error.main }}>{domain} unenrolled</h3>
        <Typography color={theme.palette.error.main} align="center">
          The merchant was successfully unenrolled.
        </Typography>
      </Stack>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onDone();
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
}
