export const OFACS_COUNTRY_CODES = [
  "AF", // Afghanistan
  "AL", // Albania
  "BY", // Belarus
  "BA", // Bosnia
  "CF", // Central African Republic
  "CN", // China
  "CG", // Congo
  "CI", // Cote d'Ivoire
  "HR", // Croatia
  "CU", // Cuba
  "CY", // Cyprus
  "ER", // Eritrea
  "ET", // Ethiopia
  "HT", // Haiti
  "HK", // Hong Kong
  "IR", // Iran
  "IQ", // Iraq
  "XK", // Kosovo
  "LB", // Lebanon
  "LR", // Liberia
  "LY", // Libya
  "ME", // Montenegro
  "MM", // Myanmar
  "KP", // North Korea
  "MK", // North Macedonia
  "RU", // Russia
  "RS", // Serbia
  "SI", // Slovenia
  "SO", // Somalia
  "SS", // South Sudan
  "LK", // Sri Lanka
  "SD", // Sudan
  "SY", // Syria
  "VE", // Venezuela
  "VN", // Vietnam
  "YE", // Yemen
  "ZW", // Zimbabwe
];

export const EU_COUNTRY_CODES = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czechia
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
];

export const EEC_COUNTRY_CODES = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czechia
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IS", // Iceland
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LI", // Lichtenstein
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "NO", // Norway
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
  "CH", // Switzerland
];
