import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTrueBizApi } from "../../api";
import APICompanyMatchProfile from "../../types/APICompanyMatchProfile";
import MatchProfiles from "../MatchProfile";
import Loading from "../Loading";

export function SettingsWithoutDataLoader({
  matchProfiles,
}: {
  matchProfiles: APICompanyMatchProfile[] | null;
}) {
  return (
    <Box>
      <Typography variant="h4" component="h1" fontWeight={700} gutterBottom>
        Risk Profiles
      </Typography>

      <MatchProfiles matchProfiles={matchProfiles} />
    </Box>
  );
}

export default function Settings() {
  const api = useTrueBizApi();
  const [matchProfiles, setMatchProfiles] = useState<
    APICompanyMatchProfile[] | null
  >(null);

  useEffect(() => {
    const loadCompanyMatchProfiles = async () => {
      try {
        const companyMatchProfiles = await api.getCompanyMatchProfiles();
        setMatchProfiles(companyMatchProfiles);
      } catch {
        // pass
      }
    };

    loadCompanyMatchProfiles();
  }, [api]);

  return !matchProfiles ? (
    <Loading />
  ) : (
    <SettingsWithoutDataLoader matchProfiles={matchProfiles} />
  );
}
