import { flatten, uniq } from "lodash";
import { getCountries } from "./getCountries";
import { isOfacsSet } from "./isOfacsSet";

export const getSuggestedLanguages = (
  selectedCountryCodes: string[]
): string[] => {
  const ofacsSet = isOfacsSet(selectedCountryCodes, true);
  if (ofacsSet) {
    return [];
  }

  const rawSuggestedLanguageCodes = uniq(
    getCountries()
      .filter((country) => {
        return selectedCountryCodes.includes(country.alpha2);
      })
      .map((country) => {
        return country.languages;
      })
  );
  return uniq(flatten(rawSuggestedLanguageCodes)).map((code) => {
    return code;
  });
};
