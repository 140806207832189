import { useEffect, useState, Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme, IconButton, Drawer, Button, Link } from "@mui/material";
import type { IconButtonProps } from "@mui/material/IconButton";
import { Link as RouterLink, useLocation } from "react-router-dom";

import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useTrueBizApi } from "../../api";
import { useIdentity } from "../IdentityProvider";
import { useDomainFinder } from "../DomainFinder";
import { useWebsiteStatusCheck } from "../WebsiteStatusCheck";

export interface Props extends Exclude<IconButtonProps, "onClick"> {
  closeOnNavigate?: boolean;
  invert?: boolean;
}

export default function HamburgerMenu({
  style = {},
  closeOnNavigate = true,
  invert = false,
  ...etc
}: Props) {
  const identity = useIdentity();
  const theme = useTheme();
  const api = useTrueBizApi();
  const location = useLocation();

  const { show: showDomainFinder } = useDomainFinder();
  const { show: showWebsiteStatusCheck } = useWebsiteStatusCheck();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!closeOnNavigate) return;
    setShowMenu(false);
  }, [location, closeOnNavigate]);

  const webhooksUrlQuery = useQuery({
    queryKey: ["getWebhooksUrl"],
    queryFn: async (): Promise<{ url?: string | null }> => {
      const accountInfo = await api.accountLookup();
      if (!accountInfo.webhook_auth_url) return { url: null };

      const webhookInfo = await api.webhookLookup(accountInfo.webhook_auth_url);
      if (!webhookInfo.dashboard_url) return { url: null };

      return { url: webhookInfo.dashboard_url };
    },
  });

  return (
    <>
      <IconButton
        style={{
          color: invert ? "white" : "#959595",
          ...style,
        }}
        onClick={() => setShowMenu(true)}
        title="Settings/Navigation Menu"
        {...etc}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        open={showMenu}
        anchor="right"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        slotProps={{
          backdrop: { invisible: true },
        }}
        onClose={() => setShowMenu(false)}
      >
        <div
          style={{
            position: "relative",
            width: "350px",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "0.5em",
              left: "0.5em",
            }}
            onClick={() => setShowMenu(false)}
            title="Close Menu"
          >
            <CloseOutlinedIcon style={{ color: "white" }} />
          </IconButton>
          <div
            style={{
              padding: "1.5em",
              color: "white",
              display: "flex",
              flexDirection: "column",
              marginTop: "2.75em",
              maxWidth: "85%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                marginBottom: "3em",
                textAlign: "center",
              }}
            >
              {identity.user?.email && (
                <div
                  style={{
                    fontSize: "1.3em",
                    wordBreak: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {/* allow long emails to break at the "@" */}
                  {identity.user.email
                    .split("@")
                    .map((part, i) => (
                      <Fragment key={`${part}${i}`}>
                        {part}&#8203;{/*zero-width space*/}
                      </Fragment>
                    ))
                    // "intersperse", but shitty, cuz js
                    // https://stackoverflow.com/questions/37128624/terse-way-to-intersperse-element-between-all-elements-in-javascript-array
                    .flatMap((e, i) => [
                      <Fragment key={`at${i}`}>@</Fragment>,
                      e,
                    ])
                    .slice(1)}
                </div>
              )}
              {identity.user?.accountName && (
                <div
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    fontSize: "85%",
                    fontWeight: 600,
                  }}
                >
                  {identity.user.accountName}
                </div>
              )}

              <Button
                variant="outlined"
                sx={{
                  borderColor: "white",
                  "&:hover": {
                    borderColor: "white",
                    backgroundColor: "rgba(255,255,255,0.08)",
                  },
                  color: "white",
                  textTransform: "unset",
                  width: "100%",
                  fontSize: "1em",
                  marginTop:
                    identity.user?.email || identity.user?.accountName
                      ? "1.5em"
                      : undefined,
                }}
                onClick={() => identity.logout()}
              >
                Log Out
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.5em",
              }}
            >
              <div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "85%",
                    letterSpacing: "1px",
                    opacity: 0.65,
                    marginBottom: "0.75em",
                  }}
                >
                  Account
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75em",
                  }}
                >
                  <Link
                    component={RouterLink}
                    to="/searches"
                    sx={{ display: "flex", gap: "0.5em", color: "white" }}
                  >
                    <WatchLaterOutlinedIcon /> Search History
                  </Link>
                  <Link
                    component={RouterLink}
                    to="/settings"
                    sx={{ display: "flex", gap: "0.5em", color: "white" }}
                  >
                    <AssignmentOutlinedIcon /> Risk Profiles
                  </Link>
                </div>
              </div>

              <div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "85%",
                    letterSpacing: "1px",
                    opacity: 0.65,
                    marginBottom: "0.75em",
                  }}
                >
                  Tools
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75em",
                  }}
                >
                  <Link
                    component={RouterLink}
                    to="/monitoring"
                    sx={{ display: "flex", gap: "0.5em", color: "white" }}
                  >
                    <MonitorHeartOutlinedIcon /> Monitoring
                  </Link>
                  <Link
                    onClick={() => {
                      setShowMenu(false);
                      showDomainFinder();
                    }}
                    sx={{
                      display: "flex",
                      gap: "0.5em",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ExploreOutlinedIcon /> Domain Finder
                  </Link>
                  <Link
                    onClick={() => {
                      setShowMenu(false);
                      showWebsiteStatusCheck();
                    }}
                    sx={{
                      display: "flex",
                      gap: "0.5em",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ElectricalServicesOutlinedIcon /> Website Status Check
                  </Link>
                  <Link
                    component={RouterLink}
                    to="/blocked"
                    sx={{ display: "flex", gap: "0.5em", color: "white" }}
                  >
                    <PsychologyOutlinedIcon /> Smart Blocklist
                  </Link>
                </div>
              </div>

              <div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "85%",
                    letterSpacing: "1px",
                    opacity: 0.65,
                    marginBottom: "0.75em",
                  }}
                >
                  Developer
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75em",
                  }}
                >
                  <Link
                    href="https://ae.truebiz.io/api/v1/docs"
                    target="_blank"
                    sx={{ display: "flex", gap: "0.5em", color: "white" }}
                  >
                    <MenuBookOutlinedIcon /> API Documentation
                  </Link>
                  {webhooksUrlQuery.data?.url && (
                    <Link
                      href={webhooksUrlQuery.data.url}
                      target="_blank"
                      sx={{ display: "flex", gap: "0.5em", color: "white" }}
                    >
                      <WebhookOutlinedIcon /> Webhooks
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
