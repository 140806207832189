import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { SocialIcon } from "react-social-icons";
import APIPerson from "../../types/APIPerson";

const DEFAULT_NUM_PEOPLE_TO_SHOW = 8;

function Person({ person }: { person: APIPerson }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Box paddingBottom={2}>
        <Box marginBottom={1}>
          {person.profile_picture ? (
            <Avatar
              src={person.profile_picture}
              alt={person.name}
              sx={{ width: 60, height: 60 }}
            />
          ) : (
            <Avatar sx={{ width: 60, height: 60 }}>
              {person.name.slice(0, 1)}
            </Avatar>
          )}
        </Box>

        <Typography fontWeight="700">
          {person.name}{" "}
          {!!person.linkedin_url && (
            <SocialIcon
              target="_blank"
              url={person.linkedin_url}
              style={{
                width: 20,
                height: 20,
                marginRight: 2,
                marginBottom: 6,
                marginTop: 1,
                marginLeft: 5,
              }}
            />
          )}
        </Typography>

        {person.job_title ? (
          <Typography fontSize={15}>{person.job_title}</Typography>
        ) : (
          <Typography color="gray" fontSize={15}>
            No Job Title Found
          </Typography>
        )}

        {getLocationString(person) ? (
          <Typography fontSize={15}>{getLocationString(person)}</Typography>
        ) : (
          <Typography color="gray" fontSize={15}>
            No Location Found
          </Typography>
        )}

        {person.start_date ? (
          <Typography fontSize={15}>
            {"Since: " + dayjs(person.start_date).format("MMMM, YYYY")}
          </Typography>
        ) : (
          <Typography color="gray" fontSize={15}>
            No Start Date Found
          </Typography>
        )}
      </Box>
    </Grid>
  );
}

export default function KeyPeople({ people }: { people: APIPerson[] | null }) {
  const [showMore, setShowMore] = useState<boolean>(false);

  if (!people || people.length === 0) return null;

  return (
    <Box data-testid="people-wrapper">
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          component="h3"
          sx={{ fontWeight: "normal", mb: 2 }}
        >
          Key People
        </Typography>

        <Grid container spacing={2}>
          {people.slice(0, DEFAULT_NUM_PEOPLE_TO_SHOW).map((person) => (
            <Person person={person} key={Math.random()} />
          ))}

          {showMore &&
            people
              .slice(DEFAULT_NUM_PEOPLE_TO_SHOW)
              .map((person) => <Person person={person} key={Math.random()} />)}
        </Grid>

        {people.length > DEFAULT_NUM_PEOPLE_TO_SHOW && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button onClick={() => setShowMore((prev) => !prev)}>
              Show {people.length - DEFAULT_NUM_PEOPLE_TO_SHOW}{" "}
              {showMore ? "Less" : "More"}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export const getLocationString = (person: APIPerson): string | null => {
  const location = [person?.city, person?.state_province, person?.country]
    .filter((part) => !!part)
    .join(", ");
  return location ? location : null;
};
