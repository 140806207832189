/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

import FilterControlContext from "./context";

export function useApply(applyFn: () => void) {
  const ctx = useContext(FilterControlContext);
  if (!ctx) throw new Error("useApply must be used within a FilterControl");

  const { applyFinalizers } = ctx;

  useEffect(() => {
    applyFinalizers.current.add(applyFn);

    return () => {
      applyFinalizers.current.delete(applyFn);
    };
  }, [applyFn, applyFinalizers]);
}
