import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIEcommerce from "../../types/APIEcommerce";
import { formatCurrency, humanizeDateString } from "../../utilities/formatting";

export default function Ecommerce({
  ecommerce,
  ecommerceProperties,
}: {
  ecommerce: APIEcommerce | null;
  ecommerceProperties: null | any;
}) {
  return (
    <Grid
      container
      spacing={2}
      marginTop={1}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 0 }}
    >
      <Grid item xs={4}>
        <Attribute
          label="Platform"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.platform.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {ecommerce?.platform}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Product Count"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.product_count.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {ecommerce?.product_count?.toLocaleString("en-US")}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Vendor Count"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.vendor_count.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {ecommerce?.vendor_count?.toLocaleString("en-US")}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Average Price"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.average_price.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {formatCurrency(
            ecommerce?.average_price || null,
            ecommerce?.currency_code || null
          )}
        </Attribute>
      </Grid>
      <Grid item xs={4}>
        <Attribute
          label="Minimum Price"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.minimum_price.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {formatCurrency(
            ecommerce?.minimum_price || null,
            ecommerce?.currency_code || null
          )}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Maximum Price"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.maximum_price.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {formatCurrency(
            ecommerce?.maximum_price || null,
            ecommerce?.currency_code || null
          )}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Currency Code"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.currency_code.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {ecommerce?.currency_code}
        </Attribute>
      </Grid>

      <Grid item xs={4}>
        <Attribute
          label="Last Update Date"
          labelIcon={
            <Tooltip
              title={ecommerceProperties?.last_updated_date.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {humanizeDateString(ecommerce?.last_updated_date || null)}
        </Attribute>
      </Grid>
    </Grid>
  );
}
