import { object, string } from "yup";

export const MonitoringCreateFormSchema = object({
  domain: string()
    .test(
      "domain",
      "Enter a valid domain name (e.g. example.com)",
      function (value) {
        if (!value) {
          return false;
        }
        const regexp = new RegExp(
          /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
        );
        return regexp.test(value);
      }
    )
    .required(),
  // merchantName: string().nullable(),
  // user_description: string().nullable(),
  // mcc: string().required(), // TODO: validate MCC
  profile_id: string().nullable(),
  external_ref_id: string().nullable(),
  // emailAddress: string().email().nullable(),
  // phoneNumber: string()
  //   .test("phone number", "Enter a valid phone number", function (value) {
  //     if (!value) {
  //       return true;
  //     }

  //     try {
  //       let phoneNumber: any;

  //       try {
  //         phoneNumber = phoneUtil.parse(value, "US");
  //       } catch (e) {
  //         phoneNumber = undefined;
  //       }

  //       if (!phoneNumber) {
  //         try {
  //           phoneNumber = phoneUtil.parse(value);
  //         } catch (e) {
  //           phoneNumber = undefined;
  //         }
  //       }

  //       return phoneUtil.isPossibleNumber(phoneNumber);
  //     } catch (e) {
  //       return false;
  //     }
  //   })
  //   .nullable(),
  // address: object({
  //   line1: string().required(),
  //   line2: string().nullable(),
  //   city: string().required(),
  //   stateProvince: string().nullable(),
  //   postalCode: string().required(),
  //   country: string().required(),
  // })
  //   .transform((v) => {
  //     // only validate the address if we attempted to fill out any sub-fields
  //     if (isEmpty(pickBy(v))) return null;
  //     return v;
  //   })
  //   .nullable(),
});
