import { Badge } from "@mui/material";
import type { BadgeProps } from "@mui/material/Badge";
import { useLocation, useResolvedPath } from "react-router-dom";

export interface Props extends BadgeProps {
  to: string;
}

export default function NavigationBadge({
  children,
  color,
  sx = {},
  to,
  ...etc
}: Props) {
  const location = useLocation();
  const resolvedPath = useResolvedPath(to);
  const selected = location.pathname.startsWith(resolvedPath.pathname);

  return (
    <Badge
      color={selected ? "primary" : undefined}
      sx={{
        ...sx,
        "& .MuiBadge-badge": {
          top: "50%",
          right: 0,
          transform: "scale(1) translate(calc(100% + 0.5em), -50%)",
          border: "1px solid currentcolor",
        },
      }}
      {...etc}
    >
      {children}
    </Badge>
  );
}
