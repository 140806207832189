import { useState } from "react";
import { Box } from "@mui/material";
import { LoadingIframe } from "./LoadingIframe";
import { IframeEmbed } from "./IframeEmbed";

export function ProtectedIframeEmbed({ url }: { url: string }) {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Box>
      {loading && <LoadingIframe />}
      <IframeEmbed
        loading={loading}
        setLoading={setLoading}
        url={url}
        key={url}
      />
    </Box>
  );
}
