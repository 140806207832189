import queryString from "query-string";
import { BaseApi } from "./base";

import accountInformationResponse from "../fixtures/accountInformationResponse.json";
import createMatchProfileResponse from "../fixtures/createMatchProfileResponse.json";
// import companyResponse from "../fixtures/companyResponse.json";
import offlineCompanyResponse from "../fixtures/offlineCompanyResponse.json";
import companyMatchProfiles from "../fixtures/companyMatchProfiles.json";
import pagedCompanyHistory from "../fixtures/pagedCompanyHistory.json";
import pagedBlockedDomains from "../fixtures/pagedBlockedDomains.json";
import pagedNotifications from "../fixtures/pagedNotifications.json";
import monitoringStats from "../fixtures/monitoringStats.json";
import pagedEnquiries from "../fixtures/pagedEnquiries.json";
import pagedMonitors from "../fixtures/pagedMonitors.json";
import websiteStatusResponse from "../fixtures/websiteStatusResponse.json";
import openApiSchema from "../fixtures/openApiSchema.json";
import accountSearchStats from "../fixtures/accountSearchStats.json";
import domainFinderResponse from "../fixtures/domainFinderResponse.json";
import APIBlockedDomainInput from "../types/APIBlockedDomainInput";
import APIAccountSearchSummary from "../types/APIAccountSearchSummary";
import { MatchProfileOverrides } from "../components/MatchProfile/MatchProfileForm";
import APIMonitor, { APIMonitorType } from "../types/APIMonitor";
import { dataUrlToBlob } from "../utilities/testing";
import APISearchQuery from "../types/APISearchQuery";
import APINotification, {
  APINotificationFilterBy,
  APINotificationOrderBy,
  APINotificationType,
} from "../types/APINotification";
import APICompanyMatchProfile from "../types/APICompanyMatchProfile";
import APINotificationComment from "../types/APINotificationComment";
import APINotificationActivity from "../types/APINotificationActivity";
import APIMonitorActivity from "../types/APIMonitorActivity";
import APIEnquiry from "../types/APIEnquiry";
import APIUser from "../types/APIUser";
import APIPagedMonitors from "../types/APIPagedMonitors";
import APIAccountInfo from "../types/APIAccountInfo";
import APICompany from "../types/APICompany";

const screenshot = require("../fixtures/screenshot.png");

export class TrueBizApi extends BaseApi {
  rootUrl = `${process.env.REACT_APP_TRUEBIZ_API_HOSTNAME}`;

  domainLookup(query: APISearchQuery) {
    return this.authenticated()
      .json()
      .stubbedWith(offlineCompanyResponse, { delayMs: 5_000 })
      .post(`${this.rootUrl}/company/search`, query) as Promise<APICompany>;
  }

  schemaLookup() {
    return this.anonymous()
      .json()
      .stubbedWith(openApiSchema, { delayMs: 250 })
      .get(`${this.rootUrl}/openapi.json`);
  }

  accountLookup() {
    return this.authenticated()
      .json()
      .stubbedWith(accountInformationResponse, { delayMs: 1_000 })
      .get(`${this.rootUrl}/account/`) as Promise<APIAccountInfo>;
  }

  webhookLookup(authUrl: string) {
    // This will return a non-response, which should trigger an alert in the UI.
    return this.authenticated()
      .json()
      .stubbedWith({}, { delayMs: 250 })
      .get(authUrl) as { dashboard_url?: string };
  }

  getDomainFinder(
    email: string | null,
    phone: string | null,
    businessName: string | null,
    addressLine1: string | null,
    addressLine2: string | null,
    city: string | null,
    stateProvince: string | null,
    postalCode: string | null,
    country: string | null
  ) {
    const queryParams = new URLSearchParams({
      email: String(email),
      phone: String(phone),
      business_name: String(businessName),
      address_line_1: String(addressLine1),
      address_line_2: String(addressLine2),
      city: String(city),
      state_province: String(stateProvince),
      postal_code: String(postalCode),
      country: String(country),
    });

    return this.authenticated()
      .json()
      .stubbedWith(domainFinderResponse)
      .get(`${this.rootUrl}/domain_finder/?${queryParams.toString()}`);
  }

  getCompanyLookups(
    searchTerm: string,
    minDate: string | null,
    maxDate: string | null,
    offset: number,
    limit: number
  ) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
      ...(minDate ? { min_datetime: minDate } : {}),
      ...(maxDate ? { max_datetime: maxDate } : {}),
      ...(searchTerm ? { search_term: searchTerm } : {}),
    });

    return this.authenticated()
      .json()
      .stubbedWith(pagedCompanyHistory)
      .get(`${this.rootUrl}/history/company?${queryParams.toString()}`);
  }

  getCompanyLookup(requestId: string) {
    return this.authenticated()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(`${this.rootUrl}/history/company/${requestId}`);
  }

  getCompanyLookupPdfSummary(requestId: string) {
    return this.authenticated()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" }) // TODO: create empty pdf fixture
      .get(`${this.rootUrl}/pdf_summary/${requestId}`, {
        unwrapResponse: false,
      })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, blob: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          blob: blob || null,
        };
      });
  }

  getPassfortCompanyLookup(
    requestId: string,
    passfortParameters: URLSearchParams | null
  ) {
    const url = new URL(
      `${this.rootUrl}/passfort/company/${requestId}?` + passfortParameters
    );

    return this.anonymous()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(url);
  }

  getAuthRelayCompanyLookup(
    requestId: string,
    relayParameters: URLSearchParams | null
  ) {
    const url = new URL(
      `${this.rootUrl}/relay/company/${requestId}?` + relayParameters
    );

    return this.anonymous()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(url);
  }

  getScreenshot(
    src: string,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    return this.authenticated()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(src, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getPassfortScreenshot(
    src: string,
    passfortParameters: URLSearchParams | null,
    passfortRequestId: string | null,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    const url = new URL(src);
    if (!url.pathname.toString().startsWith("/api/v1/passfort/")) {
      url.pathname = url.pathname.replace("/api/v1/", "/api/v1/passfort/");
    }
    url.pathname += `/${passfortRequestId}`;
    url.search = new URLSearchParams({
      ...Object.fromEntries(url.searchParams),
      ...(passfortParameters ? Object.fromEntries(passfortParameters) : {}),
    }).toString();

    return this.anonymous()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(url, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getRelayScreenshot(
    src: string,
    relayParameters: URLSearchParams | null,
    relayRequestId: string | null,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    const url = new URL(src);
    if (!url.pathname.toString().startsWith("/api/v1/relay/")) {
      url.pathname = url.pathname.replace("/api/v1/", "/api/v1/relay/");
    }
    url.pathname += `/${relayRequestId}`;
    url.search = new URLSearchParams({
      ...Object.fromEntries(url.searchParams),
      ...(relayParameters ? Object.fromEntries(relayParameters) : {}),
    }).toString();

    return this.anonymous()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(url, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getBlockedDomains(offset: number, limit: number) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
    });

    const url = `${this.rootUrl}/company/block?${queryParams.toString()}`;

    return this.authenticated()
      .json()
      .stubbedWith(pagedBlockedDomains)
      .get(url);
  }

  deleteBlockedDomain(domain: string) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Block deleted for ${domain}.` })
      .delete(`${this.rootUrl}/company/block`, { domain: domain });
  }

  createBlockedDomain(formData: APIBlockedDomainInput) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Block created for ${formData.domain}.` })
      .post(`${this.rootUrl}/company/block`, formData);
  }

  getMonitoringStats(
    offset: number,
    limit: number,
    monitor_type_in: APIMonitorType[] = []
  ) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
    });

    for (const monitorType of monitor_type_in) {
      queryParams.append("monitor_type_in", monitorType);
    }

    const url = `${this.rootUrl}/monitoring/?${queryParams.toString()}`;

    return this.authenticated().json().stubbedWith(monitoringStats).get(url);
  }

  getPendingNotifications(
    offset: number,
    limit: number,
    type_in: APINotificationType[] = [],
    monitor_type_in: APIMonitorType[] = []
  ) {
    // TODO: use query-string for params munging
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
      pending: String(true),
    });

    for (const notificationType of type_in) {
      queryParams.append("type_in", notificationType);
    }

    for (const monitorType of monitor_type_in) {
      queryParams.append("monitor_type_in", monitorType);
    }

    const url = `${this.rootUrl}/notifications/?${queryParams.toString()}`;

    return this.authenticated().json().stubbedWith(pagedNotifications).get(url);
  }

  getNotifications({
    limit,
    offset = 0,
    orderBy = [],
    filterBy = [],
    fuzzySearch = "",
  }: {
    limit?: number;
    offset?: number;
    orderBy?: APINotificationOrderBy[];
    filterBy?: APINotificationFilterBy[];
    fuzzySearch?: string;
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }

            // we need a special empty array value to differentiate from None
            const qsValue = !Array.isArray(value)
              ? value
              : value.length === 0
              ? ["[]"]
              : value;

            return [`${column}_${operator}`, qsValue];
          })
        ),
        ...(fuzzySearch ? { fuzzy_search: fuzzySearch } : {}),
        ...(limit ? { limit } : {}),
        ...(offset ? { offset } : {}),
        ...(orderBy ? { order_by: orderBy } : {}),
      },
      {
        arrayFormat: "none", // repeated keys
        encode: true, // make sure things like emails get % encoded
      }
    );

    return this.authenticated()
      .json()
      .stubbedWith({ items: [], count: 0, limit: 0, offset: 0 })
      .get(`${this.rootUrl}/notifications/?${qs}`) as Promise<{
      items: APINotification[];
      count: number;
      limit: number;
      offset: number;
    }>;
  }

  getNotification(notificationId: UUID) {
    return this.authenticated()
      .json()
      .stubbedWith(pagedNotifications.items[0])
      .get(
        `${this.rootUrl}/notifications/${notificationId}/`
      ) as Promise<APINotification>;
  }

  getNotificationComments(notificationId: UUID) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/notifications/${notificationId}/comments`
      ) as Promise<APINotificationComment[]>;
  }

  postNewNotificationComment(notificationId: UUID, message: string) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .post(`${this.rootUrl}/notifications/${notificationId}/comments`, {
        message,
      }) as Promise<APINotificationComment>;
  }

  getNotificationActivity(notificationId: UUID) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/notifications/${notificationId}/activity`
      ) as Promise<APINotificationActivity[]>;
  }

  getNotificationsMetaUniqueValuesForDomain({
    filterBy = [],
  }: {
    filterBy?: APINotificationFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/notifications/_meta/unique_values_for/domain?${qs}`
      ) as Promise<string[]>;
  }

  getNotificationsMetaUniqueValuesForMessages({
    filterBy = [],
  }: {
    filterBy?: APINotificationFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/notifications/_meta/unique_values_for/messages?${qs}`
      ) as Promise<string[]>;
  }

  getNotificationsMetaUniqueValuesForStatus({
    filterBy = [],
  }: {
    filterBy?: APINotificationFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/notifications/_meta/unique_values_for/status?${qs}`
      ) as Promise<string[]>;
  }

  acknowledgeNotification(id: string, reason: string, notes: string | null) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Notification acknowledged.` })
      .post(`${this.rootUrl}/acknowledgements/notifications/`, {
        object_id: id,
        acknowledged_reason: reason,
        acknowledged_notes: notes,
      });
  }

  getEnquiries(
    offset: number,
    limit: number,
    flagged: boolean | null = null,
    domain: string | null = null,
    monitorType: string | null = null
  ) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
    });

    let additionalFilters = flagged !== null ? `&flagged=${flagged}` : "";
    additionalFilters += domain !== null ? `&domain=${domain}` : "";
    additionalFilters +=
      monitorType !== null ? `&monitor_type=${monitorType}` : "";
    const url = `${
      this.rootUrl
    }/monitoring/enquiries?${queryParams.toString()}${additionalFilters}`;

    return this.authenticated()
      .json()
      .stubbedWith(() => {
        // Let's make a deep copy of enquiries to work with
        let enquiries = JSON.parse(JSON.stringify(pagedEnquiries));
        // console.log(`Stubbing response for request url: ${url}`);
        if (flagged !== null) {
          console.log(`Filtering enquiries by flagged: ${flagged}`);
          enquiries.items = enquiries.items.filter(
            (item: any) => item.flagged === flagged
          );
        }

        if (domain !== null) {
          console.log(`Filtering enquiries by domain: ${domain}`);
          enquiries.items = enquiries.items.filter(
            (item: any) => item.domain === domain
          );
        }

        if (monitorType !== null) {
          console.log(`Filtering enquiries by monitorType: ${monitorType}`);
          enquiries.items = enquiries.items.filter(
            (item: any) => item.monitor_type === monitorType
          );
        }

        enquiries.count = enquiries.items.length;

        return enquiries;
      })
      .get(url);
  }

  getEnquiry(enquiryId: string) {
    return this.authenticated()
      .json()
      .stubbedWith(() =>
        pagedEnquiries.items.find((item: any) => item.id === enquiryId)
      )
      .get(`${this.rootUrl}/monitoring/enquiries/${enquiryId}/`);
  }

  getEnquiriesByMonitor(
    offset: number,
    limit: number,
    monitor: { id: UUID; domain?: UUID },
    state: string | null = null,
    flagged: boolean | null = null
  ) {
    let queryParamData: { [key: string]: any } = {
      offset: String(offset),
      limit: String(limit),
    };

    if (state) {
      queryParamData.state = state;
      console.log(`Filtering enquiries by state: ${state}`);
    }
    if (flagged !== null) {
      queryParamData.flagged = flagged;
    }
    const queryParams = new URLSearchParams(queryParamData);

    const url = `${this.rootUrl}/monitoring/items/${
      monitor.id
    }/enquiries/?${queryParams.toString()}`;

    return this.authenticated()
      .json()
      .stubbedWith(() => {
        // Let's make a deep copy of enquiries to work with
        const lowerBound = offset;
        const upperBound = offset + limit;
        const enquiries = JSON.parse(JSON.stringify(pagedEnquiries));
        enquiries.items = enquiries.items
          .filter((item: any) => {
            const matchRequirements = [
              item.domain.toLowerCase() === monitor.domain?.toLowerCase(),
              item.monitor_id === monitor.id,
            ];
            if (state) {
              matchRequirements.push(item.state.toLowerCase() === state);
            }
            if (flagged !== null) {
              matchRequirements.push(item.flagged === flagged);
            }

            return matchRequirements.every((value) => {
              return value;
            });
          })
          .slice(lowerBound, upperBound);

        enquiries.count = enquiries.items.length;

        return enquiries;
      })
      .get(url) as Promise<{
      count: number;
      offset: number;
      limit: number;
      items: APIEnquiry[];
    }>;
  }

  getMonitors(
    offset: number,
    limit: number,
    search: string | null,
    monitor_type_in: APIMonitorType[] = [],
    domain: string | null = null
  ) {
    let queryParamData: { [key: string]: any } = {
      offset: String(offset),
      limit: String(limit),
    };

    if (search && search.length) {
      queryParamData.search = search;
    }

    if (domain) {
      queryParamData.domain = domain;
    }

    const queryParams = new URLSearchParams(queryParamData);

    if (monitor_type_in.length > 0) {
      for (const monitor_type of monitor_type_in) {
        queryParams.append("monitor_type_in", monitor_type);
      }
    }

    const url = `${this.rootUrl}/monitoring/items/?${queryParams.toString()}`;

    return this.authenticated()
      .json()
      .stubbedWith(() => structuredClone(pagedMonitors))
      .get(url) as Promise<APIPagedMonitors>;
  }

  getMonitor(monitor_type: APIMonitorType | undefined, monitor_id: string) {
    return this.authenticated()
      .json()
      .stubbedWith(() =>
        pagedMonitors.items.find((monitor) => monitor.id === monitor_id)
      )
      .get(
        `${this.rootUrl}/monitoring/items/${monitor_id}/`
      ) as Promise<APIMonitor>;
  }

  updateMonitor(monitor_id: string, payload: any) {
    return this.authenticated()
      .json()
      .stubbedWith(payload)
      .put(`${this.rootUrl}/monitoring/items/${monitor_id}/`, payload);
  }

  deleteMonitor(monitor_id: string) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Monitor deleted` })
      .delete(`${this.rootUrl}/monitoring/items/${monitor_id}/`, undefined, {
        unwrapResponse: false,
      });
  }

  createMonitor(payload: any) {
    return this.authenticated()
      .json()
      .stubbedWith(payload)
      .post(`${this.rootUrl}/monitoring/items/`, payload);
  }

  getMonitorActivity(monitorId: UUID) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(`${this.rootUrl}/monitoring/items/${monitorId}/activity`) as Promise<
      APIMonitorActivity[]
    >;
  }

  getCompanyMatchProfiles() {
    return this.authenticated()
      .json()
      .stubbedWith(companyMatchProfiles)
      .get(`${this.rootUrl}/company/match_profiles`) as Promise<
      APICompanyMatchProfile[]
    >;
  }

  createMatchProfile(matchProfileFormData: MatchProfileOverrides) {
    return this.authenticated()
      .json()
      .stubbedWith(createMatchProfileResponse)
      .post(`${this.rootUrl}/company/match_profiles`, {
        countries: matchProfileFormData.countries.values,
        countries_override_type: matchProfileFormData.countries.managementType,
        languages: matchProfileFormData.languages.values,
        languages_override_type: matchProfileFormData.languages.managementType,
        currencies: matchProfileFormData.currencies.values,
        currencies_override_type:
          matchProfileFormData.currencies.managementType,
        industries: matchProfileFormData.highRiskIndustries,
      });
  }

  getWebsiteStatus(website: string) {
    return this.authenticated()
      .json()
      .stubbedWith(websiteStatusResponse)
      .post(`${this.rootUrl}/website/status`, {
        website: website,
        request_timeout: 7.5,
      });
  }

  getAccountSearchStats(
    minDateTime?: string,
    maxDateTime?: string
  ): Promise<APIAccountSearchSummary> {
    const searchParams = new URLSearchParams();

    if (minDateTime) {
      searchParams.set("min_datetime", minDateTime);
    }

    if (maxDateTime) {
      searchParams.set("max_datetime", maxDateTime);
    }

    const search = searchParams.toString();

    return this.authenticated()
      .json()
      .stubbedWith(accountSearchStats)
      .get(`${this.rootUrl}/stats/searches${search ? `?${search}` : ""}`);
  }

  getNotificationsEligibleAssignees() {
    return this.authenticated()
      .json()
      .stubbedWith([
        {
          id: "00000000-0000-0000-0000-000000000000",
          email: "schmee@truebiz.io",
          full_name: null,
        },
      ])
      .get(`${this.rootUrl}/notifications/eligible_assignees`) as Promise<
      APIUser[]
    >;
  }

  bulkAssignNotifications(
    notificationIds: UUID[],
    assigneeId: UUID | null | undefined
  ) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .put(`${this.rootUrl}/notifications/_bulk_assign`, {
        notification_ids: notificationIds,
        assignee_id: assigneeId,
      });
  }
}
