import { Box, Paper } from "@mui/material";
import { useState, useEffect } from "react";

const localStorageKey = "adminSettings";
const defaultSettings = {};

export default function Admin() {
  const cached = localStorage.getItem(localStorageKey);
  const intialSettings = cached ? JSON.parse(cached) : defaultSettings;
  const [settings, setSettings] = useState(intialSettings);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(settings));
  }, [settings]);

  return (
    <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
      {Object.keys(defaultSettings).map((key) => (
        <Box sx={{ p: 3 }} key={key}>
          <label>{key}</label>
          <input
            type="checkbox"
            checked={settings[key]}
            onChange={() => setSettings({ ...settings, [key]: !settings[key] })}
          />
        </Box>
      ))}
    </Paper>
  );
}
