import * as React from "react";
import type { SVGProps } from "react";
const SvgNr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1365.333}
    height={1365.333}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="m512 26.9-25.5 6.8-18.1 16.5C454 63.4 448.9 68.8 443 77.1c-5.7 8.1-13.3 16.2-33.9 36.3-14.6 14.3-42.6 41.8-62.1 61.2-26.8 26.6-37.8 36.8-44.8 41.7-7.6 5.2-10.3 7.8-14 13.4-5.7 8.5-11.2 13.4-40.2 36.3-12.4 9.8-24.8 20.4-27.5 23.5-3.2 3.8-15.3 13.6-34.9 28.3-35.5 26.6-102.4 80.8-114.9 93.1-12.9 12.7-27.2 35.5-42.2 67.6-11.9 25.5-16.7 39-23.8 68l-3.5 14L.5 604l-.7 43.5 4 17c2.3 9.7 5.6 28.7 7.7 44.3l3.8 27.3L20 740c4.5 3.8 5.2 4 11.2 4 5.8 0 6.7.3 9.2 2.9l2.7 2.9-3.1 6.1-3.1 6.2 4.7 16.3 4.6 16.3 26.4 27.2c22.6 23.3 26.4 27.7 26.9 30.9.4 2 .8 4.2 1 4.8.2.6 20.1 15.3 44.3 32.7l43.9 31.6 18.7 4.7 18.7 4.7 10.7 8.5c5.9 4.7 11.2 8.9 11.8 9.4.7.5 12.6 4.9 26.5 9.9 18.6 6.5 39.7 12.8 78.4 23.3l53 14.4 43.8 3.7 43.8 3.7 9.7-4c11.5-4.7 24.7-7.9 51.5-12.7l19.8-3.5 20.2-9.4c16.8-7.7 24.7-12.2 47.2-26.6 14.9-9.6 47.2-29.4 71.9-44.2l45-26.8 5.5.6c3.1.3 6.8.7 8.2.9 2 .3 4.9-1.2 12.1-6.3 7.4-5.2 12.3-7.8 22.7-11.7l13.2-5 16.5-19.6 16.4-19.7v-12.4c0-9.3-.5-14.5-2-20.4-1.6-5.9-2.3-13.3-3-29.4-.5-11.8-1.6-31.6-2.6-44-1-13.5-1.4-27.2-1.1-34.2.6-11.7.7-11.9 8.5-33 4.4-11.7 8.5-23.8 9.2-26.8.6-3 3.1-11.8 5.5-19.5 2.4-7.7 6-20.3 7.9-28 3.5-14 11.4-38.2 14.1-43.6.8-1.5 2-5.1 2.6-7.9.7-3.1 2.5-6.9 4.7-9.8 2-2.6 7.6-11.9 12.5-20.7 7.8-14.1 9.9-17.1 17.7-25 8.5-8.6 9.4-9.2 17.6-12 8.6-3 8.8-3.1 14.4-10.5 3.2-4.1 11.5-13.8 18.5-21.5 7-7.7 17.6-20 23.6-27.2 8.9-10.8 10.9-13.9 10.9-16.5 0-1.8 1.2-5.1 2.5-7.3 2.1-3.3 2.5-5.3 2.5-11.8 0-6.7.4-8.6 3.1-13.8l3-6.1-.7-14.2-.6-14.1-7-19c-6.2-17.1-7.1-20.4-8.5-32.5l-1.7-13.5-4.1-4.5c-3.8-4.1-4.8-6.3-10.1-23.3l-5.9-18.8-7.7-4.5c-7.1-4.2-9.2-6.3-25.2-25.2-25.6-30.4-35.3-39.6-56.6-53.5l-17.6-11.4-2.8-7.4c-1.6-4.1-3-7.5-3.1-7.7-.1-.1-8.9-3.4-19.5-7.2-19-6.9-19.4-7.1-27.7-14.2-4.6-4-10-8.6-11.9-10.2-2.4-2-11.4-5.6-30.4-12.1l-26.9-9.3-59.4-1.6c-55.6-1.5-60.2-1.8-70.9-4-8.9-1.9-19-2.9-47.3-4.5-19.8-1.2-37.1-2.1-38.5-2-1.4 0-14 3.1-28 6.8" />
  </svg>
);
export default SvgNr;
