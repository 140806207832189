import { useOutletContext } from "react-router-dom";
import { ReactNode } from "react";

export default function SearchParameters() {
  const { searchParametersDisplay } = useOutletContext<{
    searchParametersDisplay?: ReactNode | null | undefined;
  }>();

  if (!searchParametersDisplay) return null;

  return (
    <div
      style={{
        paddingTop: "1em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: "600px", width: "100%" }}>
        {searchParametersDisplay || null}
      </div>
    </div>
  );
}
