import { useState } from "react";
import { Skeleton } from "@mui/material";
import type { SkeletonProps } from "@mui/material/Skeleton";

export interface Props extends SkeletonProps {
  min?: number;
  max?: number;
}

export default function RaggedTextSkeleton({
  min = 30,
  max = 80,
  variant,
  style = {},
  ...etc
}: Props) {
  const [width] = useState(() => Math.random());
  return (
    <Skeleton
      variant="text"
      animation="wave"
      style={{
        ...style,
        maxWidth: `${min + width * (max - min)}%`,
      }}
      {...etc}
    />
  );
}
