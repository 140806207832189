import { Box, CircularProgress, Typography, Alert } from "@mui/material";

export default function Confirmation({
  loading,
  error,
}: {
  loading?: boolean;
  error?: boolean;
}) {
  const titleText = `Match Profile ${error ? "Creation Failed" : "Created"}`;
  const bodyText = error
    ? "Your new match profile failed to create.  Please contact your account manager for support."
    : "Your account manager has been notified about your new match profile.  They should get back to you with information about it within the next business day.";

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h4" align="center">
            {titleText}
          </Typography>
          <Alert severity={error ? "error" : "success"} sx={{ mt: 5 }}>
            {bodyText}
          </Alert>
        </>
      )}
    </Box>
  );
}
