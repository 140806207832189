import { createContext, ReactElement, useContext, useMemo } from "react";

import { TrueBizApi } from "./trueBiz";
import { useIdentity } from "../components/IdentityProvider";

const TrueBizApiContext = createContext<TrueBizApi | null>(null);

export function TrueBizApiProvider({ children }: { children: ReactElement }) {
  const { getAuthorizationHeaders } = useIdentity();
  const api = useMemo(
    () => new TrueBizApi({ getAuthorizationHeaders }),
    [getAuthorizationHeaders]
  );

  return (
    <TrueBizApiContext.Provider value={api}>
      {children}
    </TrueBizApiContext.Provider>
  );
}

export function useTrueBizApi() {
  const api = useContext(TrueBizApiContext);
  if (!api)
    throw new Error(`useTrueBizApi must be used inside a TrueBizApiProvider`);

  return api;
}
