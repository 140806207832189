import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  IconButton,
  Collapse,
  Divider,
} from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Add as AddIcon,
  KeyboardArrowDown as ArrowDownwardIcon,
  KeyboardArrowUp as ArrowUpwardIcon,
} from "@mui/icons-material";
import AddDomainModal from "./AddDomainModal";
import { useTrueBizApi } from "../../api";
import { tzAwareHumanizeTimeString } from "../../utilities/formatting";
import APIPagedBlockedDomain from "../../types/APIPagedBlockedDomain";
import APIBlockedDomainInput from "../../types/APIBlockedDomainInput";
import APIBlockedDomain from "../../types/APIBlockedDomain";

const RESULTS_PER_PAGE = 15;

export default function BlockedDomains() {
  const api = useTrueBizApi();

  const [searchParams, setSearchParams] = useSearchParams();

  const [showAddDomainForm, setShowAddDomainForm] = useState<boolean>(false);

  const [blockedDomains, setBlockedDomains] =
    useState<APIPagedBlockedDomain | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const { page: pageString = "1" } = Object.fromEntries(searchParams);

  const page = parseInt(pageString, 10);

  const onLoadBlockedDomains = useCallback(
    async (page: number) => {
      try {
        const offset = (page - 1) * RESULTS_PER_PAGE;
        setLoading(true);
        const blockedDomains = await api.getBlockedDomains(
          offset,
          RESULTS_PER_PAGE
        );
        setBlockedDomains(blockedDomains);
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    onLoadBlockedDomains(page);

    return () => {
      // TODO: Cancelable promise
    };
  }, [page, onLoadBlockedDomains]);

  useEffect(() => {
    if (page) window.scrollTo(0, 0);
  }, [page]);

  const setPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(page),
    });
  };

  const handleDeleteBlock = async (domain: string) => {
    if (!window.confirm("Are you sure?")) return;

    setLoading(true);
    await api.deleteBlockedDomain(domain);
    await onLoadBlockedDomains(page);
  };

  const handleAddDomain = async (formData: APIBlockedDomainInput) => {
    setLoading(true);

    await api.createBlockedDomain(formData);
    await onLoadBlockedDomains(page);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
      >
        <Box>
          <Typography variant="h4" component="h1" fontWeight={700}>
            Smart Blocklist{" "}
            {loading && <CircularProgress size={28} sx={{ marginLeft: 1 }} />}
          </Typography>
        </Box>

        <Box>
          <Button
            onClick={() => setShowAddDomainForm(true)}
            startIcon={<AddIcon />}
          >
            Add New Block
          </Button>
        </Box>
      </Box>

      <Paper elevation={2} sx={{ p: 0 }}>
        <TableContainer component={Box} sx={{ marginTop: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />

                <TableCell>
                  <strong>Domain</strong>
                </TableCell>

                <TableCell>
                  <strong>Blocked Since</strong>
                </TableCell>

                <TableCell>
                  <strong>Blocked Until</strong>
                </TableCell>

                <TableCell>
                  <strong>Blocked Reason</strong>
                </TableCell>

                <TableCell align="right">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(blockedDomains?.items || []).map((item) => {
                return (
                  <DataTableRow
                    block={item}
                    loading={loading}
                    onDelete={handleDeleteBlock}
                    key={item.domain}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {blockedDomains && (
        <Box
          display="flex"
          justifyContent="center"
          paddingTop={3}
          paddingBottom={2}
        >
          <Pagination
            siblingCount={3}
            count={1000}
            page={page}
            onChange={(_: React.ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
          />
        </Box>
      )}

      <AddDomainModal
        visible={showAddDomainForm}
        onClose={() => setShowAddDomainForm(false)}
        onCreateBlockedDomain={handleAddDomain}
      />
    </Box>
  );
}

function DataTableRow({
  block,
  loading,
  onDelete,
}: {
  block: APIBlockedDomain;
  loading: boolean;
  onDelete: (domain: string) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const canOpen = !!block.other_explanation || !!block.notes;

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {canOpen ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}

        <TableCell component="th" scope="row">
          <Typography fontWeight={600}>{block.domain}</Typography>
        </TableCell>

        <TableCell>{tzAwareHumanizeTimeString(block.created_at)}</TableCell>

        <TableCell>{tzAwareHumanizeTimeString(block.expires_at)}</TableCell>

        <TableCell>{block.reason}</TableCell>

        <TableCell align="right">
          <Button
            disabled={loading}
            onClick={() => onDelete(block.domain)}
            size="small"
            aria-label={`Unblock ${block.domain}`}
          >
            Unblock
          </Button>
        </TableCell>
      </TableRow>
      {canOpen && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ mt: 2, mb: 2, ml: 5, mr: 5 }}>
                {!!block.other_explanation && (
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      sx={{ fontWeight: "bold", m: 1 }}
                    >
                      Other explanation
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      component="div"
                      sx={{ m: 1 }}
                    >
                      {block.other_explanation}
                    </Typography>
                  </React.Fragment>
                )}
                {!!block.other_explanation && !!block.notes && (
                  <Divider variant="middle" />
                )}
                {!!block.notes && (
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      sx={{ fontWeight: "bold", m: 1 }}
                    >
                      Notes
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      component="div"
                      sx={{ m: 1 }}
                    >
                      {block.notes}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
