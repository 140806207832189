import { useEffect } from "react";
import styles from "./Oops.module.css";
import logoImage from "../../images/logo.svg";

export interface Props {
  message?: string;
}

export default function Oops({
  message = "We’ve encountered an unexpected error.",
}) {
  useEffect(() => {
    document.body.classList.add("oops");

    return () => {
      document.body.classList.remove("oops");
    };
  }, []);
  return (
    <div className={styles.oops}>
      <img src={logoImage} className={styles.logo} alt="TrueBiz logo" />
      <div className={styles.message}>
        {message}
        <br />
      </div>
      <div>
        <a href="/" className={styles.retry}>
          Please retry
        </a>
        .
      </div>
    </div>
  );
}
