import { Paper, Grid, Box, Typography } from "@mui/material";
import { SocialIcon } from "react-social-icons";
import pluralize from "pluralize";
import { parseDomainFromUrl } from "./utils/parseDomainFromUrl";
import Tooltip from "../../Tooltip";
import PrintOnly from "../../PrintOnly";
import APISocialMediaProfile from "../../../types/APISocialMediaProfile";
import {
  humanizeDateString,
  tzAwareHumanizeTimeString,
} from "../../../utilities/formatting";

export default function SocialMedia({
  socialMediaLinks,
  socialMediaProfiles,
}: {
  socialMediaLinks: string[] | null;
  socialMediaProfiles: APISocialMediaProfile[] | null;
}) {
  const totalCount =
    (socialMediaProfiles?.length || 0) + (socialMediaLinks?.length || 0);

  const title = (
    <Typography
      variant="h6"
      component="h3"
      sx={{ fontWeight: "normal", mb: 1 }}
    >
      Social Media
    </Typography>
  );

  if (totalCount === 0) {
    return (
      <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
        {title}

        <Typography marginTop={1}>No social media found</Typography>
      </Paper>
    );
  }

  const detailedSocialMediaUrls = new Set(
    socialMediaProfiles?.map((p) => p.url) || []
  );

  const plainProfileUrls = (socialMediaLinks || []).filter(
    (link) => !detailedSocialMediaUrls.has(link)
  );

  return (
    <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
      {title}

      <Grid container spacing={2} marginTop={-2} columnSpacing={{ md: 6 }}>
        {!!socialMediaProfiles &&
          socialMediaProfiles.length > 0 &&
          socialMediaProfiles
            .filter((profile) => {
              try {
                new URL(profile.url);
                return true;
              } catch {
                return false;
              }
            })
            .map((profile) => (
              <Grid key={profile.url} item xs={12}>
                <SocialMediaProfileRow
                  url={profile.url}
                  likeCount={profile.like_count}
                  lastPostedAt={profile.last_posted_content_at}
                  pageCreatedAt={profile.page_created_at}
                />
              </Grid>
            ))}

        {plainProfileUrls.length > 0 &&
          plainProfileUrls.map((url) => (
            <Grid key={url} item xs={12}>
              <SocialMediaProfileRow url={url} />
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
}

const SOCIAL_MEDIA_NETWORK_NAMES = {
  "facebook.com": "Facebook",
  "instagram.com": "Instagram",
  "twitter.com": "Twitter",
  "youtube.com": "YouTube",
  "yelp.com": "Yelp",
  "tripadvisor.com": "TripAdvisor",
  "foursquare.com": "Foursquare",
  "pinterest.com": "Pinterest",
  "linkedin.com": "LinkedIn",
  "tiktok.com": "TikTok",
  "snapchat.com": "Snapchat",
  "tumblr.com": "Tumblr",
  "reddit.com": "Reddit",
  "twitch.tv": "Twitch",
  "soundcloud.com": "SoundCloud",
  "vimeo.com": "Vimeo",
  "flickr.com": "Flickr",
  "medium.com": "Medium",
  "quora.com": "Quora",
  "google.com": "Google",
  "crunchbase.com": "Crunchbase",
};

const likeCountText = (likeCount: number) => {
  const likeCountFormatted = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(likeCount);

  return `${likeCountFormatted} ${pluralize("like", likeCount)}`;
};

function SocialMediaProfileRow({
  url,
  likeCount = null,
  lastPostedAt = null,
  pageCreatedAt = null,
}: {
  url: string;
  likeCount?: number | null;
  lastPostedAt?: string | null;
  pageCreatedAt?: string | null;
}) {
  const nakedDomain = parseDomainFromUrl(
    url
  ) as keyof typeof SOCIAL_MEDIA_NETWORK_NAMES;

  const properName = SOCIAL_MEDIA_NETWORK_NAMES[nakedDomain];

  const lastPostedDateTime = lastPostedAt
    ? tzAwareHumanizeTimeString(lastPostedAt)
    : null;
  const pageCreatedDate = pageCreatedAt
    ? humanizeDateString(pageCreatedAt)
    : null;

  return (
    <Box display="flex" alignItems="center">
      <Box paddingRight={2}>
        <Tooltip key={url} title={url} placement="bottom" disablePrintOnlyMagic>
          <span>
            <SocialIcon
              target="_blank"
              url={url}
              style={{
                width: 45,
                height: 45,
              }}
            />
          </span>
        </Tooltip>
      </Box>

      <Box>
        <Typography variant="body1" fontWeight="bold" component="div">
          {properName || nakedDomain}
        </Typography>

        <PrintOnly>
          <Typography variant="body2" component="div">
            {url}
          </Typography>
        </PrintOnly>

        <Typography variant="body1" fontSize={15} component="div">
          {likeCount !== null && (
            <Box display="inline-block" marginRight={3}>
              {likeCountText(likeCount)}
            </Box>
          )}

          {pageCreatedDate !== null && (
            <Box display="inline-block" marginRight={3}>
              Page created {pageCreatedDate}
            </Box>
          )}

          {lastPostedDateTime !== null && (
            <Box display="inline-block">Last post {lastPostedDateTime}</Box>
          )}
        </Typography>
      </Box>
    </Box>
  );
}
