import * as React from "react";
import type { SVGProps } from "react";
const SvgSz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1365.333}
    height={1365.333}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="m492 5.8-27.5 5.7L446.8 33l-17.6 21.5-40 27.5-40 27.4L333 153l-16.2 43.5-14.2 24.5c-7.9 13.5-23.9 41.1-35.6 61.3-19.5 33.8-21.8 37.3-27.4 42.2-3.4 2.9-14 12-23.6 20.2-16.8 14.3-17.8 15.3-24.4 25.8-5 8.1-9.8 14-17.8 22.1l-10.9 11.1-7.1 18.9c-13.2 35.3-13.1 35.1-20.2 90.9l-6.4 50 .9 52.5c.8 43.1 1.3 53.6 2.6 58.9l1.7 6.3 5-.6c4.5-.6 5.6-1.2 9.6-5.6 4.2-4.7 4.8-5 9.1-5 3.8 0 5.6-.8 11.4-4.8 6.4-4.4 7-5.1 9.1-11.2 1.2-3.6 2.3-6.2 2.3-5.9.1.3 1.2 8.8 2.5 19 1.4 10.1 3.2 20.3 4 22.7 2 5.5 11.5 16.5 18.1 20.8 2.8 1.9 7.9 6 11.4 9.2l6.4 5.9-1.2 12.4c-1 9.5-2.4 16.3-6.1 28.6l-4.9 16.3 5.6 13.6 5.6 13.6 21.1 19.5 21.2 19.5 21.9 34.3 22 34.3 95 43.6 95 43.6 135.1 9.5c74.3 5.2 135.8 9.5 136.6 9.5 2.7 0 3-7.2 3.3-64l.2-54.5 5.5-22.5c4.4-18 5.7-24.8 6.1-34 .5-9.8 1.4-14 5.7-28.7 2.7-9.4 5.9-22.9 7-30 1-7 5-26.4 8.6-43.1l6.8-30.4 4.3-3.5c3.9-3.2 4.5-3.4 7.1-2.4 1.7.7 3.6 2.7 4.6 4.8 1.6 3.4 1.8 3.5 6.4 3 2.6-.2 6.5-1.2 8.7-2 4-1.6 15.9-2.2 20.3-1.1 1.8.5 2.2 1.3 2.2 4.3 0 5.6 4.2 13.7 7.5 14.5 1.5.4 2.9.5 3.1.3.2-.2 4.2-44.9 8.8-99.3l8.3-98.9-14.5-37-14.5-37 2-37 2-37 10.9-38.8 10.8-38.9-3.2-41.9-3.2-42 4-8c2.9-5.6 3.7-8.1 2.8-8.4-.7-.2-10.7-2.9-22.3-5.9-19.9-5.3-21-5.5-23.4-3.9-1.4.9-2.6 1.2-2.8.7-1.8-5-13.5-29-14.3-29.2-.5-.2-14.3 4.4-30.5 10.3-20.3 7.4-29.9 10.4-30.8 9.8-.6-.6-56-44.1-123-96.7S522.2 0 521.2.1c-.9.1-14.1 2.6-29.2 5.7" />
  </svg>
);
export default SvgSz;
