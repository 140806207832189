import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { Box, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";

import type { TimeWindow } from "./types";
import StatsDisplay from "./StatsDisplay";

export default function SearchStatsSummary() {
  const now = useMemo(() => new Date(), []);
  const today = useMemo(() => dayjs(now).startOf("day"), [now]);
  const twoWeeksAgo = useMemo(
    () => dayjs(now).subtract(2, "week").startOf("day"),
    [now]
  );
  const oneYearAgo = useMemo(
    () => dayjs(now).subtract(1, "year").startOf("day"),
    [now]
  );

  const [timeWindow, setTimeWindow] = useState<TimeWindow>("today");

  const endTime = useMemo(
    () => dayjs(now).add(1, "day").startOf("day").toISOString(),
    [now]
  );
  const startTime = useMemo(
    () =>
      timeWindow === "today"
        ? dayjs(now).startOf("day").toISOString()
        : timeWindow === "2weeks"
        ? twoWeeksAgo.toISOString()
        : oneYearAgo.toISOString(),
    [timeWindow, now, twoWeeksAgo, oneYearAgo]
  );

  return (
    <Paper elevation={2} sx={{ mt: 1, mb: 3, p: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <div>Account search summary</div>
          <div style={{ fontWeight: "bold" }}>
            {timeWindow === "today"
              ? `${today.format("MMM D, YYYY")}`
              : timeWindow === "2weeks"
              ? `${twoWeeksAgo.format("MMM D, YYYY")}–${today.format(
                  "MMM D, YYYY"
                )}`
              : timeWindow === "1year"
              ? `${oneYearAgo.format("MMM D, YYYY")}–${today.format(
                  "MMM D, YYYY"
                )}`
              : "Since account inception"}
          </div>
        </Box>
        <Box sx={{ display: "flex" }}>
          <ToggleButtonGroup
            value={timeWindow}
            onChange={(e, value) => {
              if (value === null || value === undefined) return;
              setTimeWindow(value);
            }}
            exclusive
          >
            <ToggleButton value="1year">1yr</ToggleButton>
            <ToggleButton value="2weeks">2wk</ToggleButton>
            <ToggleButton value="today">Today</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box sx={{ marginTop: "1em" }}>
        <StatsDisplay
          isoStartDateTime={startTime}
          isoEndDateTime={endTime}
          timeWindow={timeWindow}
        />
      </Box>
    </Paper>
  );
}
