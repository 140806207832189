import { Button, Stack, Typography } from "@mui/material";

import SuccessIcon from "./success-icon.svg";

export interface Props {
  domain: string;
  nextRun?: Date | null | undefined;
  onDone: () => void;
}

export default function Success({ domain, nextRun, onDone }: Props) {
  return (
    <>
      <Stack
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "6em",
        }}
      >
        <img src={SuccessIcon} width={175} alt="" />
        <h3 style={{ color: "#66996C" }}>{domain} updated</h3>
        <Typography color="#66996C" align="center">
          The enrollment information was successfully updated.
        </Typography>
      </Stack>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onDone();
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
}
