import { Button, Stack, Typography } from "@mui/material";

import FailureIcon from "./failure-icon.svg";

export interface Props {
  domain: string;
  reason?: string | null | undefined;
  onDone: () => void;
}

export default function Error({ domain, reason, onDone }: Props) {
  return (
    <>
      <Stack
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "6em",
        }}
      >
        <img src={FailureIcon} width={175} alt="" />
        <h3 style={{ textAlign: "center", color: "#CC3333" }}>
          Enrollment of <br />
          {domain} failed
        </h3>
        {reason != null && (
          <Typography align="center" color="#CC3333">
            {reason}
          </Typography>
        )}
      </Stack>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onDone();
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
}
