import { Box, Paper, Grid, Typography } from "@mui/material";
import Attribute from "./Attribute";
import APIMetrics from "../../types/APIMetrics";

export default function Firmographics({
  opengraphImageLink,
  foundedYear,
  metrics,
  summary,
  formationType,
}: {
  opengraphImageLink: string | null;
  foundedYear: number | null;
  metrics: APIMetrics | null;
  summary: string | null;
  formationType: string | null;
}) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 0,
        overflow: "hidden",
      }}
    >
      {opengraphImageLink && (
        <Box
          height="fit-content"
          maxHeight={150}
          position="relative"
          overflow="hidden"
        >
          <Box
            sx={{
              position: "absolute",
              left: "-10%",
              right: "-10%",
              bottom: "10%",
              top: "-10%",
              zIndex: 1,
              display: "block",
              backgroundSize: "cover",
              backgroundImage: `url('${opengraphImageLink}')`,
              backgroundPosition: "center center",
              filter: "blur(35px)",
            }}
          />

          <img
            style={{
              position: "relative",
              zIndex: 2,
              maxWidth: "100%",
              maxHeight: 150,
              display: "block",
              margin: "auto",
              background: "#fff",
            }}
            src={opengraphImageLink}
            alt="Opengraph"
          />
        </Box>
      )}

      <Box p={3}>
        <Typography variant="h6" component="h3" sx={{ fontWeight: "normal" }}>
          Profile
        </Typography>

        <Box sx={{ mt: 1 }}>
          <Grid
            container
            spacing={2}
            marginTop={1}
            columnSpacing={{ md: 6 }}
            rowSpacing={{ md: 0 }}
          >
            <Grid item xs={12}>
              <Attribute label="Summary">
                {summary || "No description"}
              </Attribute>
            </Grid>

            <Grid item xs={6}>
              <Attribute label="Year founded">{foundedYear}</Attribute>
            </Grid>

            <Grid item xs={6}>
              <Attribute label="Estimated annual revenue">
                {metrics?.estimated_annual_revenue_range}
              </Attribute>
            </Grid>

            <Grid item xs={6}>
              <Attribute label="Estimated employee count">
                {metrics?.estimated_employee_count_range}
              </Attribute>
            </Grid>

            <Grid item xs={6}>
              <Attribute label="Formation type">{formationType}</Attribute>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
