import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";
import {
  Unsubscribe,
  UnsubscribeOutlined,
  Lock,
  LockOutlined,
  Payments,
  PaymentsOutlined,
  Inventory2,
  Inventory2Outlined,
  LocalShipping,
  LocalShippingOutlined,
  Gavel,
  GavelOutlined,
  Article,
  ArticleOutlined,
} from "@mui/icons-material";

import { APIPolicyAnalysisSourceType } from "../../../types/APIPolicyAnalysis";

export interface SourceTypeIcon {
  outlined: typeof SvgIcon;
  filled: typeof SvgIcon;
}

export const SourceTypeIcons: {
  [k in APIPolicyAnalysisSourceType]: SourceTypeIcon;
} = {
  cancellation: { outlined: UnsubscribeOutlined, filled: Unsubscribe },
  privacy: { outlined: LockOutlined, filled: Lock },
  refunds: { outlined: PaymentsOutlined, filled: Payments },
  returns: { outlined: Inventory2Outlined, filled: Inventory2 },
  shipping: { outlined: LocalShippingOutlined, filled: LocalShipping },
  tos: { outlined: GavelOutlined, filled: Gavel },
};

export const DefaultSourceTypeIcon: SourceTypeIcon = {
  outlined: ArticleOutlined,
  filled: Article,
};

export interface FilledSourceIconProps extends SvgIconProps {
  type: APIPolicyAnalysisSourceType;
}

export function FilledSourceIcon({ type, ...etc }: FilledSourceIconProps) {
  const Icon = (SourceTypeIcons[type] || DefaultSourceTypeIcon).filled;
  return <Icon {...etc} />;
}

export interface OutlinedSourceIconProps extends SvgIconProps {
  type: APIPolicyAnalysisSourceType;
}

export function OutlinedSourceIcon({ type, ...etc }: OutlinedSourceIconProps) {
  const Icon = (SourceTypeIcons[type] || DefaultSourceTypeIcon).outlined;
  return <Icon {...etc} />;
}
