import { useFormik } from "formik";
import {
  Button,
  Divider,
  Stack,
  Typography,
  TextField,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { MonitoringEditFormSchema } from "./schema";
import MonitoringIcon from "./monitoring-icon.svg";
import APIMonitor, {
  APIUpdateMonitorInput,
} from "../../../../types/APIMonitor";
import APICompanyMatchProfile from "../../../../types/APICompanyMatchProfile";

export type FormState = ReturnType<
  typeof MonitoringEditFormSchema.validateSync
>;

export interface Props {
  monitor: APIMonitor;
  matchProfiles: APICompanyMatchProfile[];
  onCancel: () => void;
  onSuccess: (domain: string) => void;
  onError: (domain: string, reason?: string) => void;
  onEditMonitor: (formData: APIUpdateMonitorInput) => Promise<APIMonitor>;
}

export default function MonitorEditForm({
  monitor,
  matchProfiles,
  onCancel,
  onSuccess,
  onError,
  onEditMonitor,
}: Props) {
  const formik = useFormik({
    initialValues: {
      // merchantName: monitor.merchantName || "",
      // user_description: monitor.user_description || "",
      // mcc: monitor.mcc || "",
      external_ref_id: monitor.external_ref_id || "",

      // hidden
      domain: monitor.domain || "",
      monitor_type: monitor.monitor_type || "",
      frequency: monitor.frequency || "",
      active_at: monitor.active_at || "",
      profile_id: monitor.profile_id || "",
    },
    onSubmit: async (values: FormState, { resetForm }) => {
      try {
        await onEditMonitor(values as any);
        resetForm();
        onSuccess(monitor.domain);
      } catch (e) {
        let description = "An unknown error";
        try {
          if (e instanceof Response) {
            const j = await e.json();
            description = j?.message || JSON.stringify(j);
          }
        } catch (e) {
          // pass
        }

        onError(values.domain!, description);
      }
    },
    validationSchema: MonitoringEditFormSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* we need to track silently track these since we don't allow partial updates */}
      <input type="hidden" name="domain" />
      <input type="hidden" name="monitor_type" />
      <input type="hidden" name="frequency" />
      <input type="hidden" name="active_at" />

      <Stack
        sx={{
          alignItems: "center",
          mb: "2em",
        }}
      >
        <img src={MonitoringIcon} width={100} alt="" />
        <Typography align="center">
          Update TrueBiz
          <br />
          merchant monitoring
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: "1em",
        }}
      >
        <FormControl>
          <TextField fullWidth disabled value={monitor.domain} />
        </FormControl>

        {/* <FormControl>
          <TextField
            fullWidth
            name="user_description"
            label="Merchant Description"
            onChange={formik.handleChange}
            value={formik.values.user_description}
            error={
              !!(
                formik.touched.user_description &&
                formik.errors.user_description
              )
            }
          />
          {formik.errors.user_description &&
            formik.touched.user_description && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.user_description}
              </Alert>
            )}
        </FormControl> */}

        {/* TODO: make this a searchable dropdown w descriptions */}
        {/* <FormControl>
          <TextField
            fullWidth
            name="mcc"
            label="Merchant MCC (required)"
            required
            onChange={formik.handleChange}
            value={formik.values.mcc}
            error={!!(formik.touched.mcc && formik.errors.mcc)}
          />
          {formik.errors.mcc && formik.touched.mcc && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.mcc}
            </Alert>
          )}
        </FormControl> */}

        {matchProfiles?.length > 0 && (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="match-profile-label"
              shrink
              sx={{ background: "white" }}
            >
              Risk Profile
            </InputLabel>
            <Select
              labelId="match-profile-label"
              label="Risk Profile"
              id="match-profile-select"
              defaultValue={formik.initialValues.profile_id}
              value={formik.values.profile_id ? formik.values.profile_id : ""}
              onChange={formik.handleChange}
              name="profile_id"
              displayEmpty
            >
              <MenuItem value={""}>TrueBiz Default</MenuItem>
              {matchProfiles?.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.name}
                </MenuItem>
              ))}
            </Select>
            {formik.errors.profile_id && formik.touched.profile_id && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.profile_id}
              </Alert>
            )}
          </FormControl>
        )}

        <FormControl>
          <TextField
            fullWidth
            name="external_ref_id"
            label="External Reference ID"
            onChange={formik.handleChange}
            value={formik.values.external_ref_id}
            error={
              !!(
                formik.touched.external_ref_id && formik.errors.external_ref_id
              )
            }
          />
          {formik.errors.external_ref_id && formik.touched.external_ref_id && (
            <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
              {formik.errors.external_ref_id}
            </Alert>
          )}
        </FormControl>

        <Divider />
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "1.5em",
          marginTop: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={formik.isSubmitting}
        >
          Modify Enrollment
        </LoadingButton>
      </div>
    </form>
  );
}
