import { Stack } from "@mui/material";

export default function Whisker() {
  return (
    <Stack
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      color="#dad9d9"
    >
      |
    </Stack>
  );
}
