import { Tooltip as MaterialTooltip, Typography } from "@mui/material";
import type { TooltipProps, TypographyVariant } from "@mui/material";

import { useIsPrint } from "./PrintOnly";

export interface Props extends TooltipProps {
  variant?: TypographyVariant;
  disablePrintOnlyMagic?: boolean;
}

export default function Tooltip({
  variant = "subtitle2",
  disablePrintOnlyMagic,
  ...etc
}: Props) {
  const isPrint = useIsPrint();

  if (!isPrint || !etc.title || disablePrintOnlyMagic)
    return <MaterialTooltip {...etc} />;
  return (
    <Typography variant={variant} sx={{ fontSize: "80%" }}>
      {etc.title}
    </Typography>
  );
}
