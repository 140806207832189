import { Grid, Typography, Link, Box } from "@mui/material";
import type { GridProps } from "@mui/material";
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  WarningAmberSharp as WarningAmberSharpIcon,
} from "@mui/icons-material";

import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIConnectedEntity from "../../types/APIConnectedEntity";

export interface Props extends GridProps {
  connectedEntity: APIConnectedEntity;
}

export default function ConnectedEntityDetail({
  connectedEntity,
  ...etc
}: Props) {
  return (
    <Grid
      container
      spacing={2}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 2 }}
      {...etc}
    >
      <Grid item xs={4}>
        <Attribute label="Entity Name">{connectedEntity.name}</Attribute>
      </Grid>
      <Grid item xs={4}>
        <Attribute label="Entity Domain">
          {connectedEntity.domain && (
            <Link
              href={`https://${connectedEntity.domain}`}
              target="_blank"
              rel="noopener"
            >
              <Typography variant="subtitle1">
                {connectedEntity.domain}
              </Typography>
            </Link>
          )}
        </Attribute>
      </Grid>
      <Grid item xs={4}>
        <Attribute
          label="Primary Language"
          labelIcon={
            <Tooltip
              title={"Evaluation of the primary language used for web content."}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {connectedEntity.primary_language}
        </Attribute>
      </Grid>
      <Grid item xs={4}>
        <Attribute
          label="Multi-Level-Marketing Name"
          labelIcon={
            <Tooltip
              title={
                "The name of a known multi-level-marketing brand found in the web content."
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {connectedEntity.multi_level_marketing_name ? (
            <>
              <WarningAmberSharpIcon color="warning" sx={{ mb: -0.9, mr: 1 }} />
              {connectedEntity.multi_level_marketing_name}
            </>
          ) : null}
        </Attribute>
      </Grid>
      <Grid item xs={8}>
        <Attribute
          label="Content Flags"
          labelIcon={
            <Tooltip
              title={
                "A list of potentially concerning flags in the web content."
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {connectedEntity.content_flags &&
          connectedEntity.content_flags.length ? (
            <>
              <WarningAmberSharpIcon color="warning" sx={{ mb: -0.9, mr: 1 }} />
              {connectedEntity.content_flags?.join(", ")}
            </>
          ) : null}
        </Attribute>
      </Grid>
      <Grid item xs={12}>
        <Attribute label="Connection Source">
          {connectedEntity.sources.map((source) => (
            <Box key={JSON.stringify(source)} sx={{ mt: 0.75 }}>
              <strong>{source.type}:</strong>{" "}
              {source.url ? (
                <Link href={source.url} target="_blank" rel="noopener">
                  {source.context}
                </Link>
              ) : (
                source.context
              )}
            </Box>
          ))}
        </Attribute>
      </Grid>
    </Grid>
  );
}
