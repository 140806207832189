import { useEffect, useMemo, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";

export interface Props {
  value: string;
  onChange: (nextValue: string) => void;
  onClear: () => void;
  description?: string;
  debounceMs?: number;
}

export default function GlobalFilter({
  value,
  onChange,
  onClear,
  description,
  debounceMs = 300,
}: Props) {
  const [buffer, setBuffer] = useState(value);

  const debounced = useMemo(
    () => debounce(onChange, debounceMs, { maxWait: 1_000 }),
    [onChange, debounceMs]
  );

  useEffect(() => {
    debounced(buffer);
  }, [debounced, buffer]);

  return (
    <TextField
      style={{ width: "100%" }}
      value={buffer}
      onChange={(e) => {
        setBuffer(e.target.value);
      }}
      InputProps={{
        endAdornment:
          buffer === "" ? undefined : (
            <IconButton
              onClick={() => {
                setBuffer("");
                onClear();
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
      }}
      placeholder={`Search${description ? ` ${description}` : ""}...`}
    />
  );
}
