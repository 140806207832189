import { MonitoringFrequency } from "./APIMonitoringFrequency";

export interface APICreateMonitorInput {
  domain: string;
  frequency: MonitoringFrequency;
  external_ref_id?: string | null | undefined;
  profile_id?: UUID | null | undefined;
}

export interface APIUpdateMonitorInput {
  external_ref_id?: string | null | undefined;
  frequency?: string | null | undefined;
  active_at?: string | null | undefined;
  profile_id?: string | null | undefined;
}

export enum APIMonitorType {
  Dummy = "dummy_monitor",
  HelloWorld = "hello_world_monitor",
  WebPresence = "web_presence_monitor",
  WebsiteContent = "website_content_monitor",
  WebsiteStatus = "website_status_monitor",
}

export default interface APIMonitor {
  id: UUID;
  monitor_type: APIMonitorType;
  name: string;
  description: string;
  domain: string;
  frequency: MonitoringFrequency;
  last_run_at: string | null;
  not_before: string | null;
  profile_id: UUID | null | undefined;
  active_at: ISODateTime;
  created_at: ISODateTime;
  updated_at: ISODateTime;
  external_ref_id: string | null;
  link?: string | null | undefined;
}
