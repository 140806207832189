import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BlockReason } from "../../types/APIBlockReason";
import APIBlockedDomainInput from "../../types/APIBlockedDomainInput";

const defaultAddDomainInput: APIBlockedDomainInput = {
  domain: "",
  reason: null,
  other_explanation: "",
  expires_at: dayjs().endOf("day").add(5, "year"),
  notes: "",
};

export default function AddDomainModal({
  visible,
  onCreateBlockedDomain,
  onClose,
  specifiedDomain = null,
}: {
  visible: boolean;
  onCreateBlockedDomain: (formData: APIBlockedDomainInput) => Promise<void>;
  onClose: () => void;
  specifiedDomain?: string | null;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<APIBlockedDomainInput>({
    ...defaultAddDomainInput,
    ...(specifiedDomain ? { domain: specifiedDomain } : {}),
  });

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    onCreateBlockedDomain(formData).then(() => {
      // Since this component stays mounted when the modal is closed,
      // we need to manually clear the local input value so it's not
      // filled in when the modal is next opened.
      setFormData({ ...defaultAddDomainInput });
      setLoading(false);
      onClose();
    });
  };

  useEffect(() => {
    if (Boolean(specifiedDomain) && specifiedDomain !== formData.domain) {
      setFormData({ ...formData, domain: specifiedDomain });
    }
  }, [specifiedDomain, formData]);

  return (
    <Dialog open={visible} onClose={onClose} fullWidth={true}>
      <DialogTitle>
        <strong>Enter a domain to block</strong>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <form onSubmit={onSubmitForm}>
            <TextField
              type="text"
              fullWidth
              required
              label="Domain"
              disabled={loading || Boolean(specifiedDomain)}
              value={formData.domain}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  domain: e.target.value ? e.target.value : null,
                });
              }}
            />

            <TextField
              select
              fullWidth
              required
              value={formData.reason || ""}
              label="Reason"
              sx={{ mt: 2 }}
              onChange={(e) => {
                const updatedFormData: APIBlockedDomainInput = {
                  ...formData,
                  reason: e.target.value as BlockReason,
                };
                if (updatedFormData.reason !== BlockReason.OTHER) {
                  updatedFormData.other_explanation = null;
                }
                setFormData(updatedFormData);
              }}
            >
              <MenuItem
                value={BlockReason.COMPLIANCE_AND_REGULATORY}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.COMPLIANCE_AND_REGULATORY}
                </Typography>
                <Typography variant="caption">
                  Interacting with this business would cause regulatory or
                  compliance issues.
                </Typography>
              </MenuItem>
              <MenuItem
                value={BlockReason.CREDIT_AND_FINANCIAL}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.CREDIT_AND_FINANCIAL}
                </Typography>
                <Typography variant="caption">
                  This business is not creditworthy or interacting with them
                  represents a financial risk to your business.
                </Typography>
              </MenuItem>
              <MenuItem
                value={BlockReason.BUSINESS_PROFILE_RISK}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.BUSINESS_PROFILE_RISK}
                </Typography>
                <Typography variant="caption">
                  This business's profile and behavior represents a risk to
                  interact with.
                </Typography>
              </MenuItem>
              <MenuItem
                value={BlockReason.INTERNAL_POLICY}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.INTERNAL_POLICY}
                </Typography>
                <Typography variant="caption">
                  Interacting with this business is forbidden due to internal
                  policies.
                </Typography>
              </MenuItem>
              <MenuItem
                value={BlockReason.FRAUDULENT_APPLICATION}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.FRAUDULENT_APPLICATION}
                </Typography>
                <Typography variant="caption">
                  The person applying on behalf does not have a legitimate
                  reason to request services.
                </Typography>
              </MenuItem>
              <MenuItem
                value={BlockReason.FRAUDULENT_BUSINESS}
                sx={{ display: "block" }}
              >
                <Typography variant="h6">
                  {BlockReason.FRAUDULENT_BUSINESS}
                </Typography>
                <Typography variant="caption">
                  The business in question appears to be fraudulent.
                </Typography>
              </MenuItem>
              <MenuItem value={BlockReason.OTHER} sx={{ display: "block" }}>
                <Typography variant="h6">{BlockReason.OTHER}</Typography>
                <Typography variant="caption">
                  Any other reason that does not fit into other categories.
                </Typography>
              </MenuItem>
            </TextField>

            {formData.reason === BlockReason.OTHER && (
              <TextField
                fullWidth
                required
                type="text"
                inputProps={{ minLength: 8 }}
                label="Explanation"
                disabled={loading}
                sx={{ mt: 2 }}
                value={formData.other_explanation}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    other_explanation: e.target.value ? e.target.value : null,
                  });
                }}
              />
            )}

            <DatePicker
              label="Expiry date"
              value={formData.expires_at}
              sx={{ mt: 2 }}
              minDate={dayjs().endOf("day").add(1, "month")}
              maxDate={dayjs().endOf("day").add(10, "year")}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />

            <TextField
              fullWidth
              type="text"
              multiline
              rows={4}
              inputProps={{ maxLength: 10485760 }}
              label="Notes"
              disabled={loading}
              sx={{ mt: 2 }}
              value={formData.notes}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  notes: e.target.value ? e.target.value : null,
                });
              }}
            />

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button onClick={onClose} sx={{ mr: 2 }}>
                Cancel
              </Button>

              <LoadingButton
                disabled={loading}
                loading={loading}
                variant="contained"
                type="submit"
              >
                Block Domain
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
