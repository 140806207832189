import { useCallback, useState } from "react";
import { MenuItem, Select, Stack } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { useApply } from "./hooks";

export type DateThresholdComparator = "lte" | "gte";

export interface DateThresholdFilterValue {
  cutoffDate: Date;
  comparator: DateThresholdComparator;
}

export interface Props {
  onApply: (
    cutoffDate: Date | null | undefined,
    comparator: DateThresholdComparator
  ) => void;
  cutoffDate: Date | null | undefined;
  comparator: DateThresholdComparator | null | undefined;
}

export default function DateThresholdFilter({
  onApply,
  cutoffDate,
  comparator,
}: Props) {
  const [selectedComparator, setSelectedComparator] =
    useState<DateThresholdComparator>(comparator || "lte");
  const [selectedCutoffDate, setSelectedCutoffDate] = useState<Date | null>(
    cutoffDate || null
  );

  const apply = useCallback(() => {
    onApply(selectedCutoffDate, selectedComparator);
  }, [onApply, selectedComparator, selectedCutoffDate]);

  useApply(apply);

  return (
    <Stack sx={{ gap: "0.5em", mt: "1em" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Select
          value={selectedComparator}
          onChange={(e) => {
            const value = e.target.value;

            if (value === "gte" || value === "lte") {
              setSelectedComparator(value);
            }
          }}
        >
          <MenuItem value="lte">Before</MenuItem>
          <MenuItem value="gte">After</MenuItem>
        </Select>
        <DateTimePicker
          value={selectedCutoffDate}
          onChange={(d) => {
            setSelectedCutoffDate(d);
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}
