import { uniq } from "lodash";
import { getCountries } from "./getCountries";
import { isOfacsSet } from "./isOfacsSet";

export const getSuggestedCurrencies = (
  selectedCountryCodes: string[]
): string[] => {
  const suggestedCurrencies = uniq(
    getCountries()
      .filter((country) => {
        return selectedCountryCodes.includes(country.alpha2);
      })
      .map((country) => {
        return country.currency; // this is a currency code...
      })
  );
  const ofacsSet = isOfacsSet(selectedCountryCodes, true);
  if (ofacsSet) {
    return suggestedCurrencies.filter((currencyCode) => {
      return !["usd", "eur"].includes(currencyCode.toLowerCase());
    });
  }
  return suggestedCurrencies;
};
