import type {
  RowData,
  HeaderContext,
  CellContext,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { Checkbox } from "@mui/material";
import ColumnHeader from "./ColumnHeader";
import { getRowRange } from "./utils";

export default class SelectColumn<TData extends RowData, TValue = unknown> {
  id = "select";
  private lastSelectedId: string;

  constructor() {
    this.lastSelectedId = "";
  }

  // these must be defined as bound instance methods, otherwise
  // react-table will not check/find them on the prototype 🙄

  header = ({ table }: HeaderContext<TData, TValue>) => {
    const anyRowsSelected = !isEmpty(table.getState().rowSelection);

    return (
      <ColumnHeader
        checked={false}
        indeterminate={anyRowsSelected}
        onChange={(e, _) => {
          e.stopPropagation();

          if (table.getIsAllRowsSelected()) {
            table.toggleAllPageRowsSelected(false);
            return;
          }

          table.toggleAllPageRowsSelected(true);
        }}
        onSelectAllOnPage={() => table.toggleAllPageRowsSelected(true)}
        onSelectNoneOnPage={() => table.toggleAllPageRowsSelected(false)}
        onSelectNone={() => table.resetRowSelection()}
        anySelected={anyRowsSelected}
        anySelectedOnPage={
          table.getIsSomePageRowsSelected() || table.getIsAllPageRowsSelected()
        }
        allSelectedOnPage={table.getIsAllPageRowsSelected()}
      />
    );
  };

  cell = ({ table, row }: CellContext<TData, TValue>) => {
    return (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        onClick={(e) => {
          e.stopPropagation();

          if (e.shiftKey) {
            const { rows, rowsById } = table.getRowModel();
            const rowsToToggle = getRowRange(rows, row.id, this.lastSelectedId);
            const isLastSelected =
              rowsById[this.lastSelectedId].getIsSelected();
            rowsToToggle.forEach((row) => row.toggleSelected(isLastSelected));
          }

          this.lastSelectedId = row.id;
        }}
      />
    );
  };
}
