import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";

import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Navigation from "./Navigation";
import IssueDetailModal, { useOpenIssueDetailModal } from "./IssueDetailModal";

import MyIssues, { PrefetchMyIssues } from "./views/MyIssues";
import OpenIssues, { PrefetchOpenIssues } from "./views/OpenIssues";
import ResolvedIssues, { PrefetchResolvedIssues } from "./views/ResolvedIssues";
import AllIssues, { PrefetchAllIssues } from "./views/AllIssues";
import { useTrueBizApi } from "../../api";

export interface Props {}

export default function MonitoringV2() {
  const queryClient = useQueryClient();
  const api = useTrueBizApi();

  const openModal = useOpenIssueDetailModal();
  const [searchParams] = useSearchParams();

  const onOpenIssueDetail = useCallback(
    (issueId: string, monitorId?: string | null | undefined) => {
      if (monitorId) {
        queryClient.prefetchQuery({
          queryKey: ["getMonitor", monitorId],
          queryFn: () => api.getMonitor(undefined, monitorId),
        });

        queryClient.prefetchQuery({
          queryKey: ["getMonitorActivity", monitorId],
          queryFn: () => api.getMonitorActivity(monitorId),
        });

        queryClient.prefetchQuery({
          queryKey: ["getMonitorEnquiries", { limit: 50, offset: 0 }],
          queryFn: () => api.getEnquiriesByMonitor(0, 50, { id: monitorId }),
        });
      }

      queryClient.prefetchQuery({
        queryKey: ["getNotification", issueId],
        queryFn: () => api.getNotification(issueId),
      });

      queryClient.prefetchQuery({
        queryKey: ["getNotificationActivity", issueId],
        queryFn: () => api.getNotificationActivity(issueId),
      });

      queryClient.prefetchQuery({
        queryKey: ["getNotificationComments", issueId],
        queryFn: () => api.getNotificationComments(issueId),
      });

      setTimeout(() => openModal(issueId, monitorId), 200);
    },
    [openModal, queryClient, api]
  );

  return (
    <>
      <h1>Monitoring Issues</h1>
      <Grid container>
        <Grid item xs={2} mt={"2.25em"}>
          <Navigation />
        </Grid>
        <Grid item xs={10}>
          <Routes>
            <Route path="issues/*">
              <Route
                path="mine"
                element={<MyIssues onOpenIssueDetail={onOpenIssueDetail} />}
              />
              <Route
                path="open"
                element={<OpenIssues onOpenIssueDetail={onOpenIssueDetail} />}
              />
              <Route
                path="resolved"
                element={
                  <ResolvedIssues onOpenIssueDetail={onOpenIssueDetail} />
                }
              />
              <Route
                path="all"
                element={<AllIssues onOpenIssueDetail={onOpenIssueDetail} />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={{ pathname: "mine", search: searchParams.toString() }}
                  />
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <Navigate
                  to={{ pathname: "issues", search: searchParams.toString() }}
                />
              }
            />
          </Routes>
          <PrefetchMyIssues />
          <PrefetchAllIssues />
          <PrefetchOpenIssues />
          <PrefetchResolvedIssues />
        </Grid>
      </Grid>
      <IssueDetailModal />
    </>
  );
}
