import { useState, MouseEvent } from "react";
import { Paper, Grid, Typography, Button, Menu, MenuItem } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { grey, lightBlue } from "@mui/material/colors";

import BooleanAttribute from "./BooleanAttribute";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIEmail from "../../types/APIEmail";
import { humanizeDateString } from "../../utilities/formatting";

function SourceUrls({
  internalSourceUrls,
  externalSourceUrls,
}: {
  internalSourceUrls: string[];
  externalSourceUrls: string[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const total = internalSourceUrls.length + externalSourceUrls.length;

  if (total === 0) {
    return null;
  }

  return (
    <>
      <Button onClick={handleClick} style={{ marginLeft: -8 }}>
        {total} source URL{total > 1 && "s"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          style={{ pointerEvents: "none" }}
          sx={{ mt: -1, backgroundColor: grey[200] }}
        >
          <Typography variant="subtitle1">
            URLs where the email address was found.
          </Typography>
        </MenuItem>
        <MenuItem style={{ pointerEvents: "none" }}>
          <Typography color={grey[600]} variant="overline">
            External
          </Typography>
        </MenuItem>
        {externalSourceUrls.length ? (
          externalSourceUrls.map((url) => (
            <MenuItem
              color={lightBlue[400]}
              component="a"
              target="_blank"
              href={url}
              key={url}
            >
              <Typography color={lightBlue[800]}>{url}</Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ pointerEvents: "none" }}>
            <Typography>Not Found</Typography>
          </MenuItem>
        )}
        <MenuItem style={{ pointerEvents: "none" }}>
          <Typography color={grey[600]} variant="overline">
            Internal
          </Typography>
        </MenuItem>
        {internalSourceUrls.length ? (
          internalSourceUrls.map((url) => (
            <MenuItem component="a" target="_blank" href={url} key={url}>
              <Typography color={lightBlue[800]}>{url}</Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem style={{ pointerEvents: "none" }}>
            <Typography>Not Found</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default function SubmittedEmail({ email }: { email: APIEmail }) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <Typography variant="h6" component="h3" marginBottom={1}>
        Submitted Email
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <BooleanAttribute
            label="Valid?"
            labelIcon={
              <Tooltip
                title="Is the primary address known to and confirmed as valid by the postal service?"
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            // only show validity signals, no validation failures
            value={email.is_valid}
            trueIsPositive
            failureText="Unknown"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Attribute label="First seen at">
            {humanizeDateString(email.date_first_seen || null)}
          </Attribute>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Attribute label="Velocity">
            {email.velocity ? `${email.velocity} / 10` : "Not Found"}
          </Attribute>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Attribute label="Popularity">
            {email.popularity ? `${email.popularity} / 10` : "Not Found"}
          </Attribute>
        </Grid>

        <Grid item xs={12} sm={12}>
          <SourceUrls
            internalSourceUrls={email.internal_source_urls || []}
            externalSourceUrls={email.external_source_urls || []}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
