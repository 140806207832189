import Ad from "./Ad";
import Ae from "./Ae";
import Af from "./Af";
import Ag from "./Ag";
import Ai from "./Ai";
import Al from "./Al";
import Am from "./Am";
import Ao from "./Ao";
import Aq from "./Aq";
import Ar from "./Ar";
import As from "./As";
import At from "./At";
import Au from "./Au";
import Aw from "./Aw";
import Ax from "./Ax";
import Az from "./Az";
import Ba from "./Ba";
import Bb from "./Bb";
import Bd from "./Bd";
import Be from "./Be";
import Bf from "./Bf";
import Bg from "./Bg";
import Bh from "./Bh";
import Bi from "./Bi";
import Bj from "./Bj";
import Bl from "./Bl";
import Bm from "./Bm";
import Bn from "./Bn";
import Bo from "./Bo";
import Bq from "./Bq";
import Br from "./Br";
import Bs from "./Bs";
import Bt from "./Bt";
import Bv from "./Bv";
import Bw from "./Bw";
import By from "./By";
import Bz from "./Bz";
import Ca from "./Ca";
import Cc from "./Cc";
import Cd from "./Cd";
import Cf from "./Cf";
import Cg from "./Cg";
import Ch from "./Ch";
import Ci from "./Ci";
import Ck from "./Ck";
import Cl from "./Cl";
import Cm from "./Cm";
import Cn from "./Cn";
import Co from "./Co";
import Cr from "./Cr";
import Cu from "./Cu";
import Cv from "./Cv";
import Cw from "./Cw";
import Cx from "./Cx";
import Cy from "./Cy";
import Cz from "./Cz";
import De from "./De";
import Dj from "./Dj";
import Dk from "./Dk";
import Dm from "./Dm";
import Do from "./Do";
import Dz from "./Dz";
import Ec from "./Ec";
import Ee from "./Ee";
import Eg from "./Eg";
import Eh from "./Eh";
import Er from "./Er";
import Es from "./Es";
import Et from "./Et";
import Fi from "./Fi";
import Fj from "./Fj";
import Fk from "./Fk";
import Fo from "./Fo";
import Fr from "./Fr";
import Ga from "./Ga";
import Gb from "./Gb";
import Gd from "./Gd";
import Ge from "./Ge";
import Gf from "./Gf";
import Gg from "./Gg";
import Gh from "./Gh";
import Gi from "./Gi";
import Gl from "./Gl";
import Gm from "./Gm";
import Gn from "./Gn";
import Gp from "./Gp";
import Gq from "./Gq";
import Gr from "./Gr";
import Gs from "./Gs";
import Gt from "./Gt";
import Gu from "./Gu";
import Gw from "./Gw";
import Gy from "./Gy";
import Hk from "./Hk";
import Hm from "./Hm";
import Hn from "./Hn";
import Hr from "./Hr";
import Ht from "./Ht";
import Hu from "./Hu";
import Id from "./Id";
import Ie from "./Ie";
import Il from "./Il";
import Im from "./Im";
import In from "./In";
import Io from "./Io";
import Iq from "./Iq";
import Ir from "./Ir";
import Is from "./Is";
import It from "./It";
import Jm from "./Jm";
import Jo from "./Jo";
import Jp from "./Jp";
import Ke from "./Ke";
import Kg from "./Kg";
import Kh from "./Kh";
import Ki from "./Ki";
import Km from "./Km";
import Kn from "./Kn";
import Kp from "./Kp";
import Kr from "./Kr";
import Kw from "./Kw";
import Ky from "./Ky";
import Kz from "./Kz";
import La from "./La";
import Lb from "./Lb";
import Lc from "./Lc";
import Li from "./Li";
import Lk from "./Lk";
import Lr from "./Lr";
import Ls from "./Ls";
import Lt from "./Lt";
import Lu from "./Lu";
import Lv from "./Lv";
import Ly from "./Ly";
import Ma from "./Ma";
import Mc from "./Mc";
import Md from "./Md";
import Me from "./Me";
import Mf from "./Mf";
import Mg from "./Mg";
import Mk from "./Mk";
import Ml from "./Ml";
import Mm from "./Mm";
import Mn from "./Mn";
import Mo from "./Mo";
import Mq from "./Mq";
import Mr from "./Mr";
import Ms from "./Ms";
import Mt from "./Mt";
import Mu from "./Mu";
import Mv from "./Mv";
import Mw from "./Mw";
import Mx from "./Mx";
import My from "./My";
import Mz from "./Mz";
import Na from "./Na";
import Nc from "./Nc";
import Ne from "./Ne";
import Nf from "./Nf";
import Ng from "./Ng";
import Ni from "./Ni";
import Nl from "./Nl";
import No from "./No";
import Np from "./Np";
import Nr from "./Nr";
import Nu from "./Nu";
import Nz from "./Nz";
import Om from "./Om";
import Pa from "./Pa";
import Pe from "./Pe";
import Pf from "./Pf";
import Pg from "./Pg";
import Ph from "./Ph";
import Pk from "./Pk";
import Pl from "./Pl";
import Pm from "./Pm";
import Pn from "./Pn";
import Pr from "./Pr";
import Pt from "./Pt";
import Pw from "./Pw";
import Py from "./Py";
import Qa from "./Qa";
import Re from "./Re";
import Ro from "./Ro";
import Rs from "./Rs";
import Ru from "./Ru";
import Rw from "./Rw";
import Sa from "./Sa";
import Sb from "./Sb";
import Sc from "./Sc";
import Sd from "./Sd";
import Se from "./Se";
import Sg from "./Sg";
import Sh from "./Sh";
import Si from "./Si";
import Sj from "./Sj";
import Sk from "./Sk";
import Sl from "./Sl";
import Sm from "./Sm";
import Sn from "./Sn";
import So from "./So";
import Sr from "./Sr";
import Ss from "./Ss";
import St from "./St";
import Sv from "./Sv";
import Sx from "./Sx";
import Sy from "./Sy";
import Sz from "./Sz";
import Tc from "./Tc";
import Td from "./Td";
import Tf from "./Tf";
import Tg from "./Tg";
import Th from "./Th";
import Tj from "./Tj";
import Tk from "./Tk";
import Tl from "./Tl";
import Tm from "./Tm";
import Tn from "./Tn";
import To from "./To";
import Tr from "./Tr";
import Tt from "./Tt";
import Tw from "./Tw";
import Tz from "./Tz";
import Ua from "./Ua";
import Ug from "./Ug";
import Us from "./Us";
import Uy from "./Uy";
import Uz from "./Uz";
import Va from "./Va";
import Vc from "./Vc";
import Ve from "./Ve";
import Vg from "./Vg";
import Vi from "./Vi";
import Vn from "./Vn";
import Vu from "./Vu";
import Wf from "./Wf";
import Ws from "./Ws";
import Ye from "./Ye";
import Yt from "./Yt";
import Za from "./Za";
import Zm from "./Zm";
import Zw from "./Zw";

export { default as Ad } from "./Ad";
export { default as Ae } from "./Ae";
export { default as Af } from "./Af";
export { default as Ag } from "./Ag";
export { default as Ai } from "./Ai";
export { default as Al } from "./Al";
export { default as Am } from "./Am";
export { default as Ao } from "./Ao";
export { default as Aq } from "./Aq";
export { default as Ar } from "./Ar";
export { default as As } from "./As";
export { default as At } from "./At";
export { default as Au } from "./Au";
export { default as Aw } from "./Aw";
export { default as Ax } from "./Ax";
export { default as Az } from "./Az";
export { default as Ba } from "./Ba";
export { default as Bb } from "./Bb";
export { default as Bd } from "./Bd";
export { default as Be } from "./Be";
export { default as Bf } from "./Bf";
export { default as Bg } from "./Bg";
export { default as Bh } from "./Bh";
export { default as Bi } from "./Bi";
export { default as Bj } from "./Bj";
export { default as Bl } from "./Bl";
export { default as Bm } from "./Bm";
export { default as Bn } from "./Bn";
export { default as Bo } from "./Bo";
export { default as Bq } from "./Bq";
export { default as Br } from "./Br";
export { default as Bs } from "./Bs";
export { default as Bt } from "./Bt";
export { default as Bv } from "./Bv";
export { default as Bw } from "./Bw";
export { default as By } from "./By";
export { default as Bz } from "./Bz";
export { default as Ca } from "./Ca";
export { default as Cc } from "./Cc";
export { default as Cd } from "./Cd";
export { default as Cf } from "./Cf";
export { default as Cg } from "./Cg";
export { default as Ch } from "./Ch";
export { default as Ci } from "./Ci";
export { default as Ck } from "./Ck";
export { default as Cl } from "./Cl";
export { default as Cm } from "./Cm";
export { default as Cn } from "./Cn";
export { default as Co } from "./Co";
export { default as Cr } from "./Cr";
export { default as Cu } from "./Cu";
export { default as Cv } from "./Cv";
export { default as Cw } from "./Cw";
export { default as Cx } from "./Cx";
export { default as Cy } from "./Cy";
export { default as Cz } from "./Cz";
export { default as De } from "./De";
export { default as Dj } from "./Dj";
export { default as Dk } from "./Dk";
export { default as Dm } from "./Dm";
export { default as Do } from "./Do";
export { default as Dz } from "./Dz";
export { default as Ec } from "./Ec";
export { default as Ee } from "./Ee";
export { default as Eg } from "./Eg";
export { default as Eh } from "./Eh";
export { default as Er } from "./Er";
export { default as Es } from "./Es";
export { default as Et } from "./Et";
export { default as Fi } from "./Fi";
export { default as Fj } from "./Fj";
export { default as Fk } from "./Fk";
export { default as Fo } from "./Fo";
export { default as Fr } from "./Fr";
export { default as Ga } from "./Ga";
export { default as Gb } from "./Gb";
export { default as Gd } from "./Gd";
export { default as Ge } from "./Ge";
export { default as Gf } from "./Gf";
export { default as Gg } from "./Gg";
export { default as Gh } from "./Gh";
export { default as Gi } from "./Gi";
export { default as Gl } from "./Gl";
export { default as Gm } from "./Gm";
export { default as Gn } from "./Gn";
export { default as Gp } from "./Gp";
export { default as Gq } from "./Gq";
export { default as Gr } from "./Gr";
export { default as Gs } from "./Gs";
export { default as Gt } from "./Gt";
export { default as Gu } from "./Gu";
export { default as Gw } from "./Gw";
export { default as Gy } from "./Gy";
export { default as Hk } from "./Hk";
export { default as Hm } from "./Hm";
export { default as Hn } from "./Hn";
export { default as Hr } from "./Hr";
export { default as Ht } from "./Ht";
export { default as Hu } from "./Hu";
export { default as Id } from "./Id";
export { default as Ie } from "./Ie";
export { default as Il } from "./Il";
export { default as Im } from "./Im";
export { default as In } from "./In";
export { default as Io } from "./Io";
export { default as Iq } from "./Iq";
export { default as Ir } from "./Ir";
export { default as Is } from "./Is";
export { default as It } from "./It";
export { default as Jm } from "./Jm";
export { default as Jo } from "./Jo";
export { default as Jp } from "./Jp";
export { default as Ke } from "./Ke";
export { default as Kg } from "./Kg";
export { default as Kh } from "./Kh";
export { default as Ki } from "./Ki";
export { default as Km } from "./Km";
export { default as Kn } from "./Kn";
export { default as Kp } from "./Kp";
export { default as Kr } from "./Kr";
export { default as Kw } from "./Kw";
export { default as Ky } from "./Ky";
export { default as Kz } from "./Kz";
export { default as La } from "./La";
export { default as Lb } from "./Lb";
export { default as Lc } from "./Lc";
export { default as Li } from "./Li";
export { default as Lk } from "./Lk";
export { default as Lr } from "./Lr";
export { default as Ls } from "./Ls";
export { default as Lt } from "./Lt";
export { default as Lu } from "./Lu";
export { default as Lv } from "./Lv";
export { default as Ly } from "./Ly";
export { default as Ma } from "./Ma";
export { default as Mc } from "./Mc";
export { default as Md } from "./Md";
export { default as Me } from "./Me";
export { default as Mf } from "./Mf";
export { default as Mg } from "./Mg";
export { default as Mk } from "./Mk";
export { default as Ml } from "./Ml";
export { default as Mm } from "./Mm";
export { default as Mn } from "./Mn";
export { default as Mo } from "./Mo";
export { default as Mq } from "./Mq";
export { default as Mr } from "./Mr";
export { default as Ms } from "./Ms";
export { default as Mt } from "./Mt";
export { default as Mu } from "./Mu";
export { default as Mv } from "./Mv";
export { default as Mw } from "./Mw";
export { default as Mx } from "./Mx";
export { default as My } from "./My";
export { default as Mz } from "./Mz";
export { default as Na } from "./Na";
export { default as Nc } from "./Nc";
export { default as Ne } from "./Ne";
export { default as Nf } from "./Nf";
export { default as Ng } from "./Ng";
export { default as Ni } from "./Ni";
export { default as Nl } from "./Nl";
export { default as No } from "./No";
export { default as Np } from "./Np";
export { default as Nr } from "./Nr";
export { default as Nu } from "./Nu";
export { default as Nz } from "./Nz";
export { default as Om } from "./Om";
export { default as Pa } from "./Pa";
export { default as Pe } from "./Pe";
export { default as Pf } from "./Pf";
export { default as Pg } from "./Pg";
export { default as Ph } from "./Ph";
export { default as Pk } from "./Pk";
export { default as Pl } from "./Pl";
export { default as Pm } from "./Pm";
export { default as Pn } from "./Pn";
export { default as Pr } from "./Pr";
export { default as Pt } from "./Pt";
export { default as Pw } from "./Pw";
export { default as Py } from "./Py";
export { default as Qa } from "./Qa";
export { default as Re } from "./Re";
export { default as Ro } from "./Ro";
export { default as Rs } from "./Rs";
export { default as Ru } from "./Ru";
export { default as Rw } from "./Rw";
export { default as Sa } from "./Sa";
export { default as Sb } from "./Sb";
export { default as Sc } from "./Sc";
export { default as Sd } from "./Sd";
export { default as Se } from "./Se";
export { default as Sg } from "./Sg";
export { default as Sh } from "./Sh";
export { default as Si } from "./Si";
export { default as Sj } from "./Sj";
export { default as Sk } from "./Sk";
export { default as Sl } from "./Sl";
export { default as Sm } from "./Sm";
export { default as Sn } from "./Sn";
export { default as So } from "./So";
export { default as Sr } from "./Sr";
export { default as Ss } from "./Ss";
export { default as St } from "./St";
export { default as Sv } from "./Sv";
export { default as Sx } from "./Sx";
export { default as Sy } from "./Sy";
export { default as Sz } from "./Sz";
export { default as Tc } from "./Tc";
export { default as Td } from "./Td";
export { default as Tf } from "./Tf";
export { default as Tg } from "./Tg";
export { default as Th } from "./Th";
export { default as Tj } from "./Tj";
export { default as Tk } from "./Tk";
export { default as Tl } from "./Tl";
export { default as Tm } from "./Tm";
export { default as Tn } from "./Tn";
export { default as To } from "./To";
export { default as Tr } from "./Tr";
export { default as Tt } from "./Tt";
export { default as Tw } from "./Tw";
export { default as Tz } from "./Tz";
export { default as Ua } from "./Ua";
export { default as Ug } from "./Ug";
export { default as Us } from "./Us";
export { default as Uy } from "./Uy";
export { default as Uz } from "./Uz";
export { default as Va } from "./Va";
export { default as Vc } from "./Vc";
export { default as Ve } from "./Ve";
export { default as Vg } from "./Vg";
export { default as Vi } from "./Vi";
export { default as Vn } from "./Vn";
export { default as Vu } from "./Vu";
export { default as Wf } from "./Wf";
export { default as Ws } from "./Ws";
export { default as Ye } from "./Ye";
export { default as Yt } from "./Yt";
export { default as Za } from "./Za";
export { default as Zm } from "./Zm";
export { default as Zw } from "./Zw";

export type CountryCode =
  | "ad"
  | "ae"
  | "af"
  | "ag"
  | "ai"
  | "al"
  | "am"
  | "ao"
  | "aq"
  | "ar"
  | "as"
  | "at"
  | "au"
  | "aw"
  | "ax"
  | "az"
  | "ba"
  | "bb"
  | "bd"
  | "be"
  | "bf"
  | "bg"
  | "bh"
  | "bi"
  | "bj"
  | "bl"
  | "bm"
  | "bn"
  | "bo"
  | "bq"
  | "br"
  | "bs"
  | "bt"
  | "bv"
  | "bw"
  | "by"
  | "bz"
  | "ca"
  | "cc"
  | "cd"
  | "cf"
  | "cg"
  | "ch"
  | "ci"
  | "ck"
  | "cl"
  | "cm"
  | "cn"
  | "co"
  | "cr"
  | "cu"
  | "cv"
  | "cw"
  | "cx"
  | "cy"
  | "cz"
  | "de"
  | "dj"
  | "dk"
  | "dm"
  | "do"
  | "dz"
  | "ec"
  | "ee"
  | "eg"
  | "eh"
  | "er"
  | "es"
  | "et"
  | "fi"
  | "fj"
  | "fk"
  | "fo"
  | "fr"
  | "ga"
  | "gb"
  | "gd"
  | "ge"
  | "gf"
  | "gg"
  | "gh"
  | "gi"
  | "gl"
  | "gm"
  | "gn"
  | "gp"
  | "gq"
  | "gr"
  | "gs"
  | "gt"
  | "gu"
  | "gw"
  | "gy"
  | "hk"
  | "hm"
  | "hn"
  | "hr"
  | "ht"
  | "hu"
  | "id"
  | "ie"
  | "il"
  | "im"
  | "in"
  | "io"
  | "iq"
  | "ir"
  | "is"
  | "it"
  | "jm"
  | "jo"
  | "jp"
  | "ke"
  | "kg"
  | "kh"
  | "ki"
  | "km"
  | "kn"
  | "kp"
  | "kr"
  | "kw"
  | "ky"
  | "kz"
  | "la"
  | "lb"
  | "lc"
  | "li"
  | "lk"
  | "lr"
  | "ls"
  | "lt"
  | "lu"
  | "lv"
  | "ly"
  | "ma"
  | "mc"
  | "md"
  | "me"
  | "mf"
  | "mg"
  | "mk"
  | "ml"
  | "mm"
  | "mn"
  | "mo"
  | "mq"
  | "mr"
  | "ms"
  | "mt"
  | "mu"
  | "mv"
  | "mw"
  | "mx"
  | "my"
  | "mz"
  | "na"
  | "nc"
  | "ne"
  | "nf"
  | "ng"
  | "ni"
  | "nl"
  | "no"
  | "np"
  | "nr"
  | "nu"
  | "nz"
  | "om"
  | "pa"
  | "pe"
  | "pf"
  | "pg"
  | "ph"
  | "pk"
  | "pl"
  | "pm"
  | "pn"
  | "pr"
  | "pt"
  | "pw"
  | "py"
  | "qa"
  | "re"
  | "ro"
  | "rs"
  | "ru"
  | "rw"
  | "sa"
  | "sb"
  | "sc"
  | "sd"
  | "se"
  | "sg"
  | "sh"
  | "si"
  | "sj"
  | "sk"
  | "sl"
  | "sm"
  | "sn"
  | "so"
  | "sr"
  | "ss"
  | "st"
  | "sv"
  | "sx"
  | "sy"
  | "sz"
  | "tc"
  | "td"
  | "tf"
  | "tg"
  | "th"
  | "tj"
  | "tk"
  | "tl"
  | "tm"
  | "tn"
  | "to"
  | "tr"
  | "tt"
  | "tw"
  | "tz"
  | "ua"
  | "ug"
  | "us"
  | "uy"
  | "uz"
  | "va"
  | "vc"
  | "ve"
  | "vg"
  | "vi"
  | "vn"
  | "vu"
  | "wf"
  | "ws"
  | "ye"
  | "yt"
  | "za"
  | "zm"
  | "zw";

const all = {
  ad: Ad,
  ae: Ae,
  af: Af,
  ag: Ag,
  ai: Ai,
  al: Al,
  am: Am,
  ao: Ao,
  aq: Aq,
  ar: Ar,
  as: As,
  at: At,
  au: Au,
  aw: Aw,
  ax: Ax,
  az: Az,
  ba: Ba,
  bb: Bb,
  bd: Bd,
  be: Be,
  bf: Bf,
  bg: Bg,
  bh: Bh,
  bi: Bi,
  bj: Bj,
  bl: Bl,
  bm: Bm,
  bn: Bn,
  bo: Bo,
  bq: Bq,
  br: Br,
  bs: Bs,
  bt: Bt,
  bv: Bv,
  bw: Bw,
  by: By,
  bz: Bz,
  ca: Ca,
  cc: Cc,
  cd: Cd,
  cf: Cf,
  cg: Cg,
  ch: Ch,
  ci: Ci,
  ck: Ck,
  cl: Cl,
  cm: Cm,
  cn: Cn,
  co: Co,
  cr: Cr,
  cu: Cu,
  cv: Cv,
  cw: Cw,
  cx: Cx,
  cy: Cy,
  cz: Cz,
  de: De,
  dj: Dj,
  dk: Dk,
  dm: Dm,
  do: Do,
  dz: Dz,
  ec: Ec,
  ee: Ee,
  eg: Eg,
  eh: Eh,
  er: Er,
  es: Es,
  et: Et,
  fi: Fi,
  fj: Fj,
  fk: Fk,
  fo: Fo,
  fr: Fr,
  ga: Ga,
  gb: Gb,
  gd: Gd,
  ge: Ge,
  gf: Gf,
  gg: Gg,
  gh: Gh,
  gi: Gi,
  gl: Gl,
  gm: Gm,
  gn: Gn,
  gp: Gp,
  gq: Gq,
  gr: Gr,
  gs: Gs,
  gt: Gt,
  gu: Gu,
  gw: Gw,
  gy: Gy,
  hk: Hk,
  hm: Hm,
  hn: Hn,
  hr: Hr,
  ht: Ht,
  hu: Hu,
  id: Id,
  ie: Ie,
  il: Il,
  im: Im,
  in: In,
  io: Io,
  iq: Iq,
  ir: Ir,
  is: Is,
  it: It,
  jm: Jm,
  jo: Jo,
  jp: Jp,
  ke: Ke,
  kg: Kg,
  kh: Kh,
  ki: Ki,
  km: Km,
  kn: Kn,
  kp: Kp,
  kr: Kr,
  kw: Kw,
  ky: Ky,
  kz: Kz,
  la: La,
  lb: Lb,
  lc: Lc,
  li: Li,
  lk: Lk,
  lr: Lr,
  ls: Ls,
  lt: Lt,
  lu: Lu,
  lv: Lv,
  ly: Ly,
  ma: Ma,
  mc: Mc,
  md: Md,
  me: Me,
  mf: Mf,
  mg: Mg,
  mk: Mk,
  ml: Ml,
  mm: Mm,
  mn: Mn,
  mo: Mo,
  mq: Mq,
  mr: Mr,
  ms: Ms,
  mt: Mt,
  mu: Mu,
  mv: Mv,
  mw: Mw,
  mx: Mx,
  my: My,
  mz: Mz,
  na: Na,
  nc: Nc,
  ne: Ne,
  nf: Nf,
  ng: Ng,
  ni: Ni,
  nl: Nl,
  no: No,
  np: Np,
  nr: Nr,
  nu: Nu,
  nz: Nz,
  om: Om,
  pa: Pa,
  pe: Pe,
  pf: Pf,
  pg: Pg,
  ph: Ph,
  pk: Pk,
  pl: Pl,
  pm: Pm,
  pn: Pn,
  pr: Pr,
  pt: Pt,
  pw: Pw,
  py: Py,
  qa: Qa,
  re: Re,
  ro: Ro,
  rs: Rs,
  ru: Ru,
  rw: Rw,
  sa: Sa,
  sb: Sb,
  sc: Sc,
  sd: Sd,
  se: Se,
  sg: Sg,
  sh: Sh,
  si: Si,
  sj: Sj,
  sk: Sk,
  sl: Sl,
  sm: Sm,
  sn: Sn,
  so: So,
  sr: Sr,
  ss: Ss,
  st: St,
  sv: Sv,
  sx: Sx,
  sy: Sy,
  sz: Sz,
  tc: Tc,
  td: Td,
  tf: Tf,
  tg: Tg,
  th: Th,
  tj: Tj,
  tk: Tk,
  tl: Tl,
  tm: Tm,
  tn: Tn,
  to: To,
  tr: Tr,
  tt: Tt,
  tw: Tw,
  tz: Tz,
  ua: Ua,
  ug: Ug,
  us: Us,
  uy: Uy,
  uz: Uz,
  va: Va,
  vc: Vc,
  ve: Ve,
  vg: Vg,
  vi: Vi,
  vn: Vn,
  vu: Vu,
  wf: Wf,
  ws: Ws,
  ye: Ye,
  yt: Yt,
  za: Za,
  zm: Zm,
  zw: Zw,
};

const Fallback = () => <>?</>;

const isCountryCode = (x: string): x is CountryCode => x in all;

export const getDisplayComponentByCode = (code: string) => {
  const cleanedCode = code.toLowerCase();

  if (isCountryCode(cleanedCode)) {
    return all[cleanedCode];
  }

  return Fallback;
};
