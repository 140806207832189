import { ReactElement } from "react";

import { SvgIcon, Grid, Box, Typography, useTheme } from "@mui/material";

export interface Props {
  name?: string;
  value: string | ReactElement | null | undefined;
  isEmpty?: boolean;
  icon: typeof SvgIcon;
  isSuspicious?: boolean | null | undefined;
}

export default function PolicyFact({
  name,
  value,
  isEmpty = false,
  icon,
  isSuspicious = false,
}: Props) {
  const theme = useTheme();
  const Icon = icon;

  return (
    <Grid container sx={{ mb: "0.5em" }}>
      <Grid item xs={1}>
        <Icon
          sx={{
            color: isSuspicious
              ? theme.palette.error.main
              : theme.palette.grey[500],
          }}
          fontSize="medium"
        />
      </Grid>
      <Grid item xs={11}>
        <Box sx={{ pl: "0.5em" }}>
          {name && (
            <Typography
              variant="body2"
              sx={{
                fontSize: "70%",
                color: isSuspicious
                  ? theme.palette.error.main
                  : theme.palette.grey[700],
              }}
            >
              {name}
            </Typography>
          )}
          <Typography
            variant="body2"
            sx={{
              color: isSuspicious
                ? theme.palette.error.main
                : isEmpty || value == null
                ? theme.palette.grey[500]
                : undefined,
              fontWeight:
                isSuspicious || (isEmpty !== undefined ? isEmpty : !!value)
                  ? 600
                  : 400,
            }}
          >
            {value || "Unknown"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
