import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AccountBalance,
  Error,
  MoneyOff,
  ReportGmailerrorredOutlined,
} from "@mui/icons-material";

import APINewsArticle from "../../types/APINewsArticle";
import { APINewsArticleType } from "../../types/APINewsArticleType";
import { getRelativeTimeString } from "../../utilities/formatting";

export const NewsArticles = ({ articles }: { articles: APINewsArticle[] }) => {
  return (
    <Grid
      container
      spacing={2}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 2 }}
    >
      <Grid item xs={12}>
        {articles.map((article, index) => {
          return <Article article={article} index={index} key={article.link} />;
        })}
      </Grid>
    </Grid>
  );
};

const Article = ({
  article,
  index,
}: {
  article: APINewsArticle;
  index: number;
}) => {
  const publishedDate = article.date ? new Date(article.date) : null;

  return (
    <Card sx={{ display: "flex", mt: index === 0 ? "0px" : "16px" }}>
      <CardActionArea component={Link} href={article.link} target="_blank">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h6">
              {article.title}
            </Typography>
            {article.snippet && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                marginTop="4px"
              >
                {article.snippet}
              </Typography>
            )}
            <Box mt="4px">
              <Grid
                container
                spacing={2}
                columnSpacing={{ md: 6 }}
                rowSpacing={{ md: 2 }}
              >
                <Grid item xs={6}>
                  {article.concerns ? (
                    article.concerns.map((concern, index) => {
                      return (
                        <ConcernChip
                          concern={concern}
                          index={index}
                          key={concern}
                        />
                      );
                    })
                  ) : (
                    <Typography variant="caption">
                      No concerning content found
                    </Typography>
                  )}
                </Grid>
                {publishedDate && (
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        paddingTop: "7px",
                        paddingRight: "8px",
                        textAlign: "right",
                      }}
                    >
                      Published {getRelativeTimeString(publishedDate, "en-US")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const getConcernIcon = (concernType: APINewsArticleType) => {
  switch (concernType) {
    case APINewsArticleType.BANKRUPTCY:
      return <MoneyOff />;
    case APINewsArticleType.FRAUD:
      return <ReportGmailerrorredOutlined />;
    case APINewsArticleType.LEGAL:
      return <AccountBalance />;
    default:
      return <Error />;
  }
};

const ConcernChip = ({
  concern,
  index,
}: {
  concern: APINewsArticleType;
  index: number;
}) => {
  const theme = useTheme();
  const iconClass = getConcernIcon(concern);
  return (
    <Chip
      icon={iconClass}
      label={concern}
      variant="outlined"
      sx={{ borderColor: theme.palette.grey[300], ml: index === 0 ? 0 : 1 }}
    />
  );
};
