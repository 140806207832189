import { ReactNode, useMemo, useCallback, useState } from "react";

import { SideEffect } from "./utils";
import Authenticate from "./Authenticate";

import { IdentityContext, AuthenticationRedirectContext } from "../context";
import { Identity } from "../types";

export default function ApiKeyIdentity({
  children,
  credential = "",
  persistCredential,
  removePersistedCredential,
}: {
  children: ReactNode;
  credential?: string;
  persistCredential?: (key: string) => void;
  removePersistedCredential?: () => void;
}) {
  const [apiKey, setApiKey] = useState(credential);

  const api = useMemo<Identity>(
    () => ({
      isAuthenticated: !!apiKey,
      loading: false,
      getAuthorizationHeaders: () => (apiKey ? { "X-API-KEY": apiKey } : {}),
      login: async () => {
        setShowAuthentication(true);
      },
      logout: async () => {
        removePersistedCredential?.();
        setApiKey("");
      },
    }),
    [apiKey, removePersistedCredential]
  );

  const [showAuthentication, setShowAuthentication] = useState(false);
  const Redirect = useCallback(
    () => <SideEffect onMount={() => setShowAuthentication(true)} />,
    []
  );

  return (
    <IdentityContext.Provider value={api}>
      <AuthenticationRedirectContext.Provider value={Redirect}>
        {showAuthentication ? (
          <Authenticate
            onValidKeyEntered={(key) => {
              setApiKey(key);
              persistCredential?.(key);
              setShowAuthentication(false);
            }}
          />
        ) : (
          children
        )}
      </AuthenticationRedirectContext.Provider>
    </IdentityContext.Provider>
  );
}
