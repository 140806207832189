import type { Row } from "@tanstack/react-table";

export function getRowRange<T>(rows: Row<T>[], idA: string, idB: string) {
  //  see https://github.com/TanStack/table/discussions/3068#discussioncomment-4245586
  const range: Row<T>[] = [];
  let foundStart = false;
  let foundEnd = false;
  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index];
    if (row.id === idA || row.id === idB) {
      if (foundStart) {
        foundEnd = true;
      }
      if (!foundStart) {
        foundStart = true;
      }
    }

    if (foundStart) {
      range.push(row);
    }

    if (foundEnd) {
      break;
    }
  }

  return range;
}
