import { Button, Menu, MenuItem, Typography, Box } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { useState, MouseEvent } from "react";

export default function DropdownList({ domains }: { domains: string[] }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const total = domains.length;

  if (total === 0) {
    return null;
  }

  return (
    <>
      <Box>
        {total}
        <Button
          variant="outlined"
          size="small"
          onClick={handleClick}
          sx={{
            padding: 0.3,
            pl: 0,
            ml: 1.5,
          }}
        >
          VIEW
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {domains.map((domain) => (
          <MenuItem
            color={lightBlue[400]}
            component="a"
            target="_blank"
            href={`https://${domain}`}
            key={domain}
          >
            <Typography color={lightBlue[800]}>{domain}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
