export const breakLabelLines = (label: string, maxLineLength = 20) => {
  const words = label.split(" ");
  const lines = [];

  let currentLine = "";
  for (const word of words) {
    const maybeNextLine = currentLine.length ? `${currentLine} ${word}` : word;

    if (maybeNextLine.length >= maxLineLength) {
      lines.push(maybeNextLine);
      currentLine = "";
    } else {
      currentLine = maybeNextLine;
    }
  }

  if (currentLine.length) {
    lines.push(currentLine);
    currentLine = "";
  }

  return lines;
};
