import { Badge, Stack } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { first, last, orderBy, sum } from "lodash";
import { startOfToday } from "date-fns";
import IssueDetail from "./IssueDetail";
import Whisker from "./Whisker";
import { formatDate, formatMonitorType, maxDate } from "./utils";
import styles from "./MonitorTimelineBody.module.css";
import { partitionBy } from "../views/utils";
import APIEnquiry, {
  APIEnquirySiteContentRemark,
} from "../../../types/APIEnquiry";
import APIMonitor from "../../../types/APIMonitor";
import APIMonitorActivity, {
  APIMonitorActivityAction,
} from "../../../types/APIMonitorActivity";
import APINotification, {
  APINotificationResourceType,
  APINotificationStatus,
  APINotificationType,
} from "../../../types/APINotification";

function Header({
  domain,
  unresolvedIssueCount = 0,
  prevRun,
  nextRun,
}: {
  domain: string;
  unresolvedIssueCount?: number;
  prevRun?: ISODateTime | null | undefined;
  nextRun?: ISODateTime | null | undefined;
}) {
  return (
    <div className={styles.header}>
      <h2 style={{ marginTop: 0, marginBottom: "0.25em" }}>
        <span>{domain}</span>
      </h2>
      <div className={styles.meta}>
        {unresolvedIssueCount > 0 && (
          <div
            style={{
              display: "flex",
              gap: "0.25em",
            }}
          >
            <Badge
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  border: "1px solid currentcolor",
                  position: "initial",
                  transform: "translateY(2px)",
                },
              }}
              badgeContent={unresolvedIssueCount}
            />
            <div>
              <strong>Open Issue{unresolvedIssueCount === 1 ? "" : "s"}</strong>
            </div>
          </div>
        )}
        <div className={styles.runInfo}>
          <div className={styles.next}>
            Next Run:{" "}
            {nextRun ? <strong>{formatDate(nextRun)}</strong> : "unscheduled"}
          </div>
          <div className={styles.previous}>
            Previous Run:{" "}
            {prevRun ? <strong>{formatDate(prevRun)}</strong> : "never"}
          </div>
        </div>
      </div>
    </div>
  );
}

export interface Props {
  monitor: APIMonitor;
  activity: APIMonitorActivity[];
  enquiries: APIEnquiry[];
  notifications: APINotification[];
  focusedNotificationId?: UUID | null | undefined;
}

export default function MonitorTimelineBody({
  monitor,
  activity,
  enquiries,
  notifications,
  focusedNotificationId,
}: Props) {
  // block lazy loading while we're scrolling, so we don't accidentally trigger a bunch of queries
  // while we're scrolling past
  const [suspendIssueLoading, setSuspendIssueLoading] = useState(
    focusedNotificationId != null
  );
  useEffect(() => {
    if (!focusedNotificationId) return;

    const el = document.getElementById(`issue_${focusedNotificationId}`);
    if (!el) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setSuspendIssueLoading(false);
          intersectionObserver.disconnect();
          return;
        }
      }
    });

    intersectionObserver.observe(el);

    el.scrollIntoView({
      behavior: "instant" as ScrollBehavior,
      block: "center",
    });
  }, [focusedNotificationId]);

  const tl = useMemo(() => {
    const unsorted: {
      timestamp: ISODateTime;
      id: UUID;
      description: string;
      issues: {
        body: APINotification;
        relatedContentRemarks: APIEnquirySiteContentRemark[];
      }[];
    }[] = [];

    for (const activityEvent of activity) {
      const timestamp = activityEvent.created_at;
      const id = activityEvent.id;

      if (activityEvent.action === APIMonitorActivityAction.Create) {
        unsorted.push({
          timestamp,
          id,
          description: "Enrolled in monitoring",
          issues: [],
        });
      }

      if (activityEvent.action === APIMonitorActivityAction.Update) {
        unsorted.push({
          timestamp,
          id,
          description: "Enrollment modified",
          issues: [],
        });
      }

      if (activityEvent.action === APIMonitorActivityAction.Delete) {
        unsorted.push({
          timestamp,
          id,
          description: "Unenrolled in monitoring",
          issues: [],
        });
      }
    }

    for (const enquiry of enquiries) {
      const issues = orderBy(
        notifications.filter(
          (n) =>
            n.type === APINotificationType.SiteContent &&
            n.resource_type === APINotificationResourceType.MonitorEnquiry &&
            n.resource_id === enquiry.id
        ),
        ["created_at"],
        ["desc"]
      );

      unsorted.push({
        timestamp: enquiry.started_at,
        id: enquiry.id,
        description: `${formatMonitorType(enquiry.monitor_type)}: ${
          issues.length === 0 ? "no issues detected" : issues.length
        } issue${issues.length === 1 ? "" : "s"} detected`,
        issues: issues.map((issue) => ({
          body: issue,
          relatedContentRemarks: enquiries
            .flatMap((e) => e.remarks)
            .filter(
              (r) =>
                r.subject === "site_content" &&
                issue.UNSAFE_INTERNAL_UNSTABLE_DO_NOT_USE_remark_ids.includes(
                  r.id
                )
            ) as APIEnquirySiteContentRemark[],
        })),
      });
    }

    const sorted = orderBy(unsorted, ["timestamp"], ["desc"]);

    // group repeated events
    return partitionBy(sorted, (event) => [
      event.description,
      event.issues.length,
    ]);
  }, [activity, enquiries, notifications]);

  return (
    <Stack minHeight="600px" alignItems="center" justifyContent="flex-start">
      <Header
        domain={monitor.domain}
        prevRun={monitor.last_run_at}
        nextRun={
          monitor.not_before
            ? maxDate(monitor.not_before, startOfToday())
            : undefined
        }
        unresolvedIssueCount={
          notifications.filter(
            (n) =>
              n.type === APINotificationType.SiteContent &&
              n.status !== APINotificationStatus.Resolved
          ).length
        }
      />
      {tl.map((eventGroup, i) => {
        if (!eventGroup.length) return <>{null}</>;

        const isLast = i === tl.length - 1;

        const hasIssues = sum(eventGroup.map((g) => g.issues.length)) > 0;
        if (hasIssues) {
          return eventGroup.map((event) => (
            <Stack key={event.id} sx={{ width: "100%" }}>
              <Stack>
                <div className={styles.description}>{event.description}</div>
                <div className={styles.timestamp}>
                  {formatDate(event.timestamp)}
                </div>
                {event.issues.map((issue, i, issues) => {
                  const isLastIssue = i === issues.length - 1;

                  return (
                    <Fragment key={issue.body.id}>
                      <Stack
                        alignItems="center"
                        sx={
                          issue.body.id === focusedNotificationId
                            ? {
                                borderRadius: "10px",
                                border: "1px solid #ccc",
                                background: "rgba(30, 30, 30, 0.1)",
                              }
                            : {}
                        }
                        pt={1}
                      >
                        <IssueDetail
                          issue={issue.body}
                          relatedContentRemarks={issue.relatedContentRemarks}
                          id={`issue_${issue.body.id}`}
                          focused={issue.body.id === focusedNotificationId}
                          suspendLoading={
                            issue.body.id === focusedNotificationId
                              ? false
                              : suspendIssueLoading
                          }
                        />
                      </Stack>
                      {!isLastIssue && <Whisker />}
                    </Fragment>
                  );
                })}
              </Stack>
              {!isLast && <Whisker />}
            </Stack>
          ));
        }

        // a bunch of dupes
        const firstEvent = first(eventGroup)!;
        const firstEventDate = formatDate(firstEvent.timestamp);

        const lastEvent = last(eventGroup)!;
        const lastEventDate = formatDate(lastEvent.timestamp);

        const dupeId = `${firstEvent.id}_${lastEvent.id}`;

        return (
          <Stack key={dupeId} sx={{ width: "100%" }}>
            <Stack>
              <div className={styles.description}>
                {firstEvent.description}{" "}
                {eventGroup.length > 1 && <>(×{eventGroup.length})</>}
              </div>
              <div className={styles.timestamp}>
                {firstEventDate === lastEventDate
                  ? firstEventDate
                  : `${firstEventDate}–${lastEventDate}`}
              </div>
            </Stack>
            {!isLast && <Whisker />}
          </Stack>
        );
      })}
    </Stack>
  );
}
