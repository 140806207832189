import { Optional } from "../utilities/types";

export enum CustomerReviewAnalysisLevelOfConcern {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  CRITICAL = "critical",
}

export enum CustomerReviewAnalysisFlag {
  CUSTOMER_SERVICE = "customer_service_concerns",
  PRODUCT_QUALITY = "product_quality_concerns",
  PRODUCT_AUTHENTICITY = "product_authenticity_concerns",
  DELIVERY = "delivery_concerns",
  RETURN_OR_REFUND = "return_or_refund_concerns",
  FRAUD = "fraud_concerns",
}

export interface CustomerReviewAnalysis {
  text: Optional<string>;
  level_of_concern: Optional<CustomerReviewAnalysisLevelOfConcern>;
  flags: Optional<CustomerReviewAnalysisFlag[]>;
}

export interface APICustomerReviewProviderMeta {
  description?: string;
  url: string;
  name: string;
  review_count: number;
  review_average: number;
}

/*
  -- all possible review providers + frequencies

  select
      provider_name,
      count(provider_name) as n 
  from (
      select jsonb_path_query((external_response ->> 'data')::jsonb,
                              '$.customer_reviews[*].provider') as provider_name
      from company_match_requests
  ) providers group by provider_name order by 2 desc;

*/
export enum WellKnownReviewProvider {
  GOOGLE = "google",
  YELP = "yelp",
  TRUSTPILOT = "trustpilot",
  FACEBOOK = "facebook",
}

type OtherReviewProvider = string;

export type ReviewProvider = WellKnownReviewProvider | OtherReviewProvider;

export interface APICustomerReviewProvider {
  provider: ReviewProvider;
  meta: APICustomerReviewProviderMeta;
}

export default interface APICustomerReviewsData {
  providers: Optional<APICustomerReviewProvider[]>;
  analysis: Optional<CustomerReviewAnalysis>;
}
