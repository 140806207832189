import { Alert, Box, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";

export default function TrustRecommendation({
  pass,
}: {
  pass: boolean | null;
}) {
  if (pass === null) {
    return null;
  }

  const IconClass = pass ? VerifiedIcon : ErrorIcon;

  return (
    <Box marginBottom={3} marginTop={0} data-testid="recommendation-container">
      <Alert
        icon={<IconClass />}
        severity={pass ? "success" : "error"}
        sx={{
          borderWidth: 1,
          borderColor: pass ? "success.light" : "error.light",
          borderStyle: "solid",
        }}
      >
        {/* Using AlertTitle will cause centering issues. */}
        {/* You may also want to be aware that icon size=large
            and fontSize=large do not produce a consistent line height */}
        <Typography fontWeight={600} color={pass ? "success" : "error"}>
          {pass ? "Pass" : "Fail"}
        </Typography>
      </Alert>
    </Box>
  );
}
