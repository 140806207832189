import { Box, Paper, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { OverrideListType } from "./types";
import CountryStep from "./CountryStep";
import CurrenciesStep from "./CurrenciesStep";
import LanguagesStep from "./LanguagesStep";
import IndustriesStep from "./IndustriesStep";
import Confirmation from "./Confirmation";
import { useTrueBizApi } from "../../api";

export interface MatchProfileOverrides {
  countries: {
    managementType: OverrideListType;
    values: string[];
  };
  currencies: {
    managementType: OverrideListType;
    values: string[];
  };
  languages: {
    managementType: OverrideListType;
    values: string[];
  };
  highRiskIndustries: string[];
}

const DEFAULT_MATCH_PROFILE_OVERRIDES: MatchProfileOverrides = {
  countries: {
    managementType: OverrideListType.PERMIT,
    values: [],
  },
  currencies: {
    managementType: OverrideListType.PERMIT,
    values: [],
  },
  languages: {
    managementType: OverrideListType.PERMIT,
    values: [],
  },
  highRiskIndustries: [],
};

const STEPS_DATA = [
  {
    keyFragment: "high-risk-industries",
    label: "Configure High Risk Industries",
  },
  { keyFragment: "countries", label: "Configure Countries" },
  { keyFragment: "currencies", label: "Configure Currencies" },
  { keyFragment: "languages", label: "Configure Languages" },
];

export default function MatchProfileForm() {
  const api = useTrueBizApi();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formData, setFormData] = useState<MatchProfileOverrides>({
    ...DEFAULT_MATCH_PROFILE_OVERRIDES,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({ ...DEFAULT_MATCH_PROFILE_OVERRIDES });
  };

  return (
    <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
      <Box sx={{ p: 3 }}>
        <Stepper activeStep={activeStep}>
          {STEPS_DATA.map((stepData) => {
            return (
              <Step key={`profile-step-${stepData.keyFragment}`}>
                <StepLabel>{stepData.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ flexGrow: 1, mt: 8, mb: 1 }}>
          {activeStep === 0 && (
            <IndustriesStep
              selectedValues={formData.highRiskIndustries}
              onStepCompletion={(industryCategories) => {
                setFormData({
                  ...formData,
                  highRiskIndustries: industryCategories,
                });
                handleNext();
              }}
            />
          )}
          {activeStep === 1 && (
            <CountryStep
              selectedValues={formData.countries.values}
              overrideListType={formData.countries.managementType}
              onStepCompletion={(countries, overrideListType) => {
                setFormData({
                  ...formData,
                  countries: {
                    values: countries,
                    managementType: overrideListType,
                  },
                });
                handleNext();
              }}
              onStepBack={handleBack}
              onReset={handleReset}
            />
          )}
          {activeStep === 2 && (
            <CurrenciesStep
              selectedValues={formData.currencies.values}
              overrideListType={formData.currencies.managementType}
              onStepCompletion={(currencies, overrideListType) => {
                setFormData({
                  ...formData,
                  currencies: {
                    values: currencies,
                    managementType: overrideListType,
                  },
                });
                handleNext();
              }}
              onStepBack={handleBack}
              onReset={handleReset}
              countriesSelections={formData.countries.values}
            />
          )}
          {activeStep === 3 && (
            <LanguagesStep
              selectedValues={formData.languages.values}
              overrideListType={formData.languages.managementType}
              onStepCompletion={async (languages, overrideListType) => {
                const finalFormData = {
                  ...formData,
                  languages: {
                    values: languages,
                    managementType: overrideListType,
                  },
                };
                setFormData(finalFormData);

                try {
                  setError(false);
                  setLoading(true);
                  handleNext();
                  await api.createMatchProfile(finalFormData);
                } catch {
                  setError(true);
                } finally {
                  setLoading(false);
                }
              }}
              onStepBack={handleBack}
              onReset={handleReset}
              countriesSelections={formData.countries.values}
            />
          )}
          {activeStep >= 4 && <Confirmation loading={loading} error={error} />}
        </Box>
      </Box>
    </Paper>
  );
}
