import { useQuery } from "@tanstack/react-query";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTrueBizApi } from "../../../api";
import MonitorTimeline from "../MonitorTimeline";
import { extractMonitorIdFromNotificationMonitorLink } from "../views/utils";

export interface Props {
  issueId: UUID;
  monitorId?: UUID | null | undefined;
}

export default function IssueDetails({ issueId, monitorId }: Props) {
  const api = useTrueBizApi();

  const issueQuery = useQuery({
    queryKey: ["getNotification", issueId],
    queryFn: () => api.getNotification(issueId!),
    enabled: monitorId == null, // look up only if we're missing it
    throwOnError: true,
  });

  const loading =
    monitorId == null ? issueQuery.isLoading || !issueQuery.data : false;
  const resolvedMonitorId =
    monitorId ||
    extractMonitorIdFromNotificationMonitorLink(
      issueQuery.data?.links?.monitor
    );

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (!resolvedMonitorId) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="h6">Could not load monitor</Typography>
        <Typography variant="body1">
          There was a problem loading the requested monitor timeline
        </Typography>
      </Stack>
    );
  }

  return (
    <MonitorTimeline monitorId={resolvedMonitorId} focusedIssueId={issueId} />
  );
}
