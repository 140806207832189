import { useState, useMemo, ChangeEvent } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Chip,
  TablePagination,
  TableContainer,
  IconButton,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  WarningAmberSharp as WarningAmberSharpIcon,
  FindInPage as FindInPageIcon,
} from "@mui/icons-material";

import ConnectedEntityDetailModal from "./ConnectedEntityDetailModal";
import ConnectedEntityDetail from "./ConnectedEntityDetail";
import Tooltip from "../Tooltip";
import APIConnectedEntity from "../../types/APIConnectedEntity";
import isReadOnly from "../../utilities/isReadOnly";
import PrintOnly, { useIsPrint } from "../PrintOnly";
import WebOnly from "../WebOnly";

const DEFAULT_ROWS_PER_PAGE = 5;

function getTooltipLabel(sourceType: string) {
  switch (sourceType) {
    case "External email domain":
      return "Company uses an email address hosted by this website.";
    case "Referring site":
      return "This website refers more than 25% of the company's traffic.";
    case "Duplicate website text":
      return "This website shares a meaningful amount of text context with company's website";
    case "Shared executive":
      return "A company executive is also an active executive of this entity.";
    case "Shared phone number":
      return "Company's primary phone number is used by another entity.";
    case "Shared credentials":
      return "Private credentials for a website service are used by this entity.";
    case "Cohosted domain":
      return "This domain is hosted at the same private IP address at the company's website.";
    case "Redirect domain":
      return "This domain redirects to the company's website.";
    case "Searched domain redirects":
      return "The searched domain redirects to a different domain.";
    case "Associated person":
      return "Records indicate this person is linked to the company or is an associate of someone directly linked to the company";
    default:
      return null;
  }
}

function isAlert(connectedEntity: APIConnectedEntity) {
  if (connectedEntity.content_flags && connectedEntity.content_flags.length) {
    return true;
  }
  if (connectedEntity.multi_level_marketing_name) {
    return true;
  }
  return false;
}

function NameLink({
  name,
  domain,
}: {
  name: string | null;
  domain: string | null;
}) {
  if (name && domain) {
    return (
      <Link href={`https://${domain}`} target="_blank" rel="noopener">
        <Typography variant="subtitle1">{name}</Typography>
      </Link>
    );
  }

  if (domain && name === null) {
    return (
      <Link href={`https://${domain}`} target="_blank" rel="noopener">
        <Typography variant="subtitle1">{domain}</Typography>
      </Link>
    );
  }

  if (name && domain === null) {
    return <Typography variant="subtitle1">{name}</Typography>;
  }

  return null;
}

export default function ConnectedEntities({
  connectedEntities,
}: {
  connectedEntities: APIConnectedEntity[];
}) {
  const isPrint = useIsPrint();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    isPrint ? connectedEntities.length : DEFAULT_ROWS_PER_PAGE
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pageEntities = useMemo(() => {
    const start = page * rowsPerPage;
    const end = Math.min(
      page * rowsPerPage + rowsPerPage,
      connectedEntities.length
    );

    return connectedEntities.slice(start, end);
  }, [page, rowsPerPage, connectedEntities]);

  return (
    <Box data-testid="website-content-wrapper">
      <Grid container>
        <TableContainer>
          <Table size="small" aria-label="contact info table">
            <TableBody>
              {pageEntities.map((connectedEntity) => (
                <>
                  <TableRow
                    key={connectedEntity.name || connectedEntity.domain}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ pl: 0, pr: 0 }}>
                      {isAlert(connectedEntity) && (
                        <WarningAmberSharpIcon color="warning" />
                      )}
                    </TableCell>
                    <TableCell sx={{ pr: 0 }}>
                      <NameLink
                        name={connectedEntity.name}
                        domain={connectedEntity.domain}
                      />
                    </TableCell>
                    <TableCell sx={{ pl: 0 }} align="right">
                      {connectedEntity.sources.map((source) => (
                        <Box
                          key={JSON.stringify(source)}
                          sx={{
                            display:
                              connectedEntity.sources.length > 2
                                ? "block"
                                : "inline-block",
                          }}
                        >
                          <Tooltip
                            key={source.type}
                            title={getTooltipLabel(source.type)}
                            placement="right"
                            arrow
                          >
                            <Chip
                              sx={{ ml: 1, mt: 0.5 }}
                              label={
                                <Box>
                                  {source.type}
                                  <HelpOutlineOutlinedIcon
                                    fontSize="inherit"
                                    sx={{ ml: 0.25 }}
                                  />
                                </Box>
                              }
                              color="info"
                              variant="outlined"
                            />
                          </Tooltip>
                        </Box>
                      ))}
                    </TableCell>
                    <WebOnly>
                      <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
                        <ConnectedEntityDetailModal
                          connectedEntity={connectedEntity}
                        />
                      </TableCell>
                      {!isReadOnly() && (
                        <TableCell align="right" sx={{ pl: 0, pr: 0 }}>
                          {connectedEntity.domain && (
                            <Link
                              href={`${window.location.origin}/domain/${connectedEntity.domain}?run=1`}
                              target="_blank"
                            >
                              <Tooltip
                                title={
                                  "Run full web presence search on this domain in a new tab."
                                }
                                placement="right"
                                arrow
                              >
                                <IconButton>
                                  <FindInPageIcon
                                    color="primary"
                                    fontSize="medium"
                                  />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                        </TableCell>
                      )}
                    </WebOnly>
                  </TableRow>
                  <PrintOnly>
                    <ConnectedEntityDetail
                      connectedEntity={connectedEntity}
                      sx={{ display: "table-row" }}
                    />
                    <Divider sx={{ display: "table-row" }} />
                    <div style={{ display: "table-row", height: "2.5em" }} />
                  </PrintOnly>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {connectedEntities.length > DEFAULT_ROWS_PER_PAGE && (
          <Box sx={{ backgroundColor: grey[100], width: "100%" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component="div"
              count={connectedEntities.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
}
