import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { Grid, Typography, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { Scatter } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartjsTooltip,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
} from "chart.js";
import "chartjs-adapter-dayjs-4";

import Tooltip from "../../Tooltip";
import { APITechSpendRecord } from "../../../types/APIDomain";

ChartJS.register(
  ChartjsTooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  Colors,
  TimeScale
);
ChartJS.defaults.datasets.line.showLine = true;

const compactNumberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact" as const,
  compactDisplay: "short" as const,
});

const historyLineOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context: any) {
          const dt = dayjs(context.raw.x).format("MMMM D YYYY");
          const spend = context.raw.y;
          return `${dt}: $${spend}`;
        },
      },
    },
  },
  scales: {
    x: {
      type: "time" as any,
      time: {
        unit: "month",
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: function (value: string | number) {
          const num = typeof value === "string" ? parseInt(value) : value;
          return "$" + compactNumberFormatter.format(num);
        },
      },
    },
  },
};

export default function EstimatedSpendLineChart({
  spendHistory,
  estimatedTechSpend,
  domainProperties,
}: {
  spendHistory: APITechSpendRecord[];
  estimatedTechSpend: number | null;
  domainProperties: null | any;
}) {
  const theme = useTheme();

  const historyLineData = spendHistory.map((techRecord) => ({
    x: dayjs(techRecord.detected_date, "YYYY-MM-DD").valueOf(),
    y: techRecord.spend,
  }));

  if (estimatedTechSpend) {
    historyLineData.push({
      x: dayjs().valueOf(),
      y: estimatedTechSpend,
    });
  }

  const historyLineDatasets = {
    datasets: [
      {
        data: historyLineData,
        borderColor: theme.palette.primary.main,
        showLine: true,
        spanGaps: true,
      },
    ],
  };

  return (
    <Grid item xs={12} marginTop={1}>
      <Typography
        fontSize={14}
        fontWeight={600}
        color={grey[600]}
        marginTop={0}
        marginBottom={0}
      >
        Estimated Tech Spend
        <Tooltip
          title={domainProperties?.estimated_tech_spend_history.description}
          placement="right"
          arrow
        >
          <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
        </Tooltip>
      </Typography>

      <Box position="relative" height="250px" marginBottom={3}>
        <Scatter data={historyLineDatasets} options={historyLineOptions} />
      </Box>
    </Grid>
  );
}
