import { ReactNode } from "react";

export function useIsWeb() {
  return !window.matchMedia?.("print")?.matches;
}

export interface Props {
  children: ReactNode;
}

export default function WebOnly({ children }: Props) {
  const isWeb = useIsWeb();
  if (!isWeb) return null;
  return <>{children}</>;
}
