import type { SvgIconProps } from "@mui/material";
import { Cancel, Error } from "@mui/icons-material";

import { CustomerReviewAnalysisLevelOfConcern } from "../../../../types/APICustomerReviews";

export interface Props extends SvgIconProps {
  levelOfConcern: CustomerReviewAnalysisLevelOfConcern;
}

export default function LevelOfConcernIcon({ levelOfConcern, ...etc }: Props) {
  if (
    [
      CustomerReviewAnalysisLevelOfConcern.CRITICAL,
      CustomerReviewAnalysisLevelOfConcern.HIGH,
    ].includes(levelOfConcern)
  ) {
    return <Cancel fontSize="small" {...etc} />;
  }

  if (CustomerReviewAnalysisLevelOfConcern.MEDIUM === levelOfConcern) {
    return <Error fontSize="small" {...etc} />;
  }

  return null;
}
