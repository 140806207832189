import { SVGProps } from "react";

import GoogleLogo from "./SimpleIconsGoogleLogo";
import YelpLogo from "./SimpleIconsYelpLogo";
import TrustpilotLogo from "./SimpleIconsTrustpilotLogo";
import FacebookLogo from "./SimpleIconsFacebookLogo";

import {
  ReviewProvider,
  WellKnownReviewProvider,
} from "../../../../types/APICustomerReviews";

export interface Props extends SVGProps<SVGSVGElement> {
  provider: ReviewProvider;
}

export default function ReviewProviderLogo({ provider, ...etc }: Props) {
  switch (provider) {
    case WellKnownReviewProvider.GOOGLE:
      return <GoogleLogo {...etc} />;
    case WellKnownReviewProvider.YELP:
      return <YelpLogo {...etc} />;
    case WellKnownReviewProvider.TRUSTPILOT:
      return <TrustpilotLogo {...etc} />;
    case WellKnownReviewProvider.FACEBOOK:
      return <FacebookLogo {...etc} />;
    default:
      return null;
  }
}
