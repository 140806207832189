import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import type { BoxProps } from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonIcon from "@mui/icons-material/Person";
import { matchSorter } from "match-sorter";
import { useTrueBizApi } from "../../../api";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

export interface Props extends Omit<BoxProps, "onError" | "children"> {
  issueId: string;
  monitorId?: string;
  currentAssigneeId?: string | null | undefined;
  disabled?: boolean;
  onSuccess?: () => void;
  onError?: (reason?: Error) => void;
  listQueryKey?: any[];
  invalidateQueryKey?: any[];
}

export default function AssignButton({
  issueId,
  monitorId,
  currentAssigneeId,
  disabled = false,
  onSuccess,
  onError,
  listQueryKey,
  invalidateQueryKey,
  ...etc
}: Props) {
  const api = useTrueBizApi();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();

  const [el, setEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [working, setWorking] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(
    currentAssigneeId || "__unassigned__"
  );

  const unassigned = useMemo(
    () => ({ id: "__unassigned__", label: "Unassigned" }),
    []
  );

  const allPeopleQuery = useQuery({
    queryKey: ["allPeople"],
    queryFn: () => api.getNotificationsEligibleAssignees(),
  });

  const mutation = useMutation({
    mutationFn: async ({
      ids,
      userId,
      fullName,
    }: {
      ids: string[];
      userId: string | undefined;
      fullName: string | undefined;
    }) => {
      await api.bulkAssignNotifications(ids, userId);
    },
    mutationKey: ["issues", "assignIssuesToUser", issueId, selectedPerson],
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["getNotificationActivity", issueId],
        }),

        queryClient.invalidateQueries({
          queryKey: ["getNotification", issueId],
        }),

        queryClient.invalidateQueries({
          queryKey: ["getNotification", issueId],
        }),

        queryClient.invalidateQueries({
          queryKey: ["my", "issues", "openIssueCount"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["all", "issues", "openIssueCount"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["my issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["open issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["resolved issues"],
        }),

        queryClient.invalidateQueries({
          queryKey: ["all issues"],
        }),

        ...(monitorId
          ? [
              queryClient.invalidateQueries({
                queryKey: ["getMonitorIssues", monitorId],
              }),

              queryClient.invalidateQueries({
                queryKey: ["getMonitorNotifications", monitorId],
              }),

              queryClient.invalidateQueries({
                queryKey: ["getMonitor", monitorId],
              }),
            ]
          : []),
      ]);

      setSelectedPerson(currentAssigneeId || "__unassigned__");
    },
    onError: () => {
      showToast();
    },
  });

  const allValues = useMemo(
    () => [
      unassigned,
      ...orderBy(
        (allPeopleQuery.data || [])
          .filter((p) => !!p.email)
          .map((person) => ({
            id: person.id,
            label: person.email!,
          })),
        "label"
      ),
    ],
    [unassigned, allPeopleQuery.data]
  );

  const assign = useCallback(async () => {
    setWorking(true);
    if (!selectedPerson) return;

    const userId =
      selectedPerson === "__unassigned__" ? undefined : selectedPerson;

    const fullName = !userId
      ? undefined
      : allValues.find((v) => v.id === selectedPerson)?.label || "...";

    try {
      await mutation.mutateAsync({ ids: [issueId], userId, fullName });

      onSuccess?.();
      setWorking(false);
      setEl(null);
      setWorking(false);
      setSelectedPerson("");
      setSearchValue("");
    } catch (e) {
      console.error(e);
      const reason = e instanceof Error ? e : new Error(String(e));
      onError?.(reason);
      setWorking(false);
    }
  }, [onSuccess, issueId, selectedPerson, mutation, allValues, onError]);

  const stickyValues = useMemo(() => [unassigned], [unassigned]);

  const filteredValues = useMemo(() => {
    const filtered = [];
    const searched = matchSorter(allValues || [], searchValue, {
      keys: ["label"],
    });

    for (const stickyValue of stickyValues || []) {
      if (searched.includes(stickyValue)) {
        filtered.push(stickyValue);
      }
    }

    return filtered.concat(searched.filter((f) => !stickyValues?.includes(f)));
  }, [allValues, stickyValues, searchValue]);

  useEffect(() => {
    if (!selectedPerson) return;
    if (allPeopleQuery.isLoading) return;

    // un-select an option if we filter it out of view
    if (!filteredValues.map((v) => v.id).includes(selectedPerson)) {
      setSelectedPerson("");
    }
  }, [selectedPerson, filteredValues, allPeopleQuery.isLoading]);

  return (
    <Box {...etc}>
      <IconButton
        sx={{
          display: "flex",
          flexShrink: 0,
        }}
        onClick={(e) => {
          setEl(e.currentTarget);
        }}
        disabled={disabled}
      >
        <PersonIcon />
      </IconButton>
      <Popover
        open={!disabled && el != null}
        anchorEl={el}
        onClose={
          working
            ? undefined
            : () => {
                setEl(null);
              }
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {allPeopleQuery.isLoading ? (
          <CircularProgress />
        ) : (
          <Stack sx={{ padding: "1em", width: "300px" }}>
            <Input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search for people..."
              endAdornment={
                searchValue === "" ? null : (
                  <IconButton
                    onClick={() => {
                      setSearchValue("");
                      setSelectedPerson(currentAssigneeId || "__unassigned__");
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )
              }
              style={{
                minHeight: "40px",
                width: "100%",
              }}
              disabled={working}
            />
            <Stack
              style={{
                minHeight: "50px",
                maxHeight: "175px",
                overflow: "scroll",
              }}
            >
              <RadioGroup
                value={selectedPerson}
                onChange={(e, value) => {
                  setSelectedPerson(value);
                }}
              >
                {filteredValues.map((value) => (
                  <FormControlLabel
                    key={value.id}
                    value={value.id}
                    control={<Radio />}
                    label={value.label}
                    disabled={working}
                  />
                ))}
              </RadioGroup>
            </Stack>
            <Divider sx={{ marginBottom: "0.5em" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setEl(null);
                }}
                disabled={working}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                loading={working}
                onClick={() => {
                  assign();
                }}
                disabled={
                  !selectedPerson || currentAssigneeId === selectedPerson
                }
              >
                {selectedPerson === "__unassigned__" ? "Unassign" : "Assign"}
              </LoadingButton>
            </div>
          </Stack>
        )}
      </Popover>
    </Box>
  );
}
