import { CSSProperties, useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { round } from "lodash";
import { motion, AnimatePresence, HTMLMotionProps } from "framer-motion";

import TableChart from "./TableChart";
import type { TimeWindow } from "./types";
import { getDisplayComponentByCode } from "../Countries";
import { useTrueBizApi } from "../../api";
import APIAccountSearchSummary from "../../types/APIAccountSearchSummary";

export function StatsDisplayWithoutDataLoader({
  stats,
}: {
  stats: APIAccountSearchSummary;
}) {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textTransform: "uppercase",
              letterSpacing: 1.15,
              fontWeight: 600,
            }}
          >
            Searches Run
          </Typography>
          <Typography variant="h1" component="div">
            {stats.searches_performed}
          </Typography>
        </Box>
        {stats.ranked_risks.length > 0 && (
          <Box
            sx={{
              marginTop: "1em",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "90%" }}>
              Top Rejection Reasons
            </Typography>
            <TableChart showPercentages data={stats.ranked_risks} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column ",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textTransform: "uppercase",
                letterSpacing: 1.15,
                fontWeight: 600,
              }}
            >
              Top Countries
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "90%" }}>
              Primary Addresses
            </Typography>
          </Box>
          <Box sx={{ display: "table", borderSpacing: "0 1em" }}>
            {stats.ranked_countries.map((country) => {
              const CountryDisplay = getDisplayComponentByCode(country.code);
              return (
                <Box sx={{ display: "table-row" }} key={country.code}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1.5em",
                    }}
                  >
                    <CountryDisplay
                      style={{
                        maxWidth: "95px",
                        maxHeight: "95px",
                        fill: theme.palette.primary.main,
                      }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1">{country.name}</Typography>
                      <Typography variant="body2">
                        {round(100 * (country.count / country.total), 0)}%
                      </Typography>
                    </Box>
                  </div>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        {stats.ranked_mcc_codes.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textTransform: "uppercase",
                letterSpacing: 1.15,
                fontWeight: 600,
              }}
            >
              Top Industries
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "90%" }}>
              MCC Classifications
            </Typography>
            <TableChart showPercentages data={stats.ranked_mcc_codes} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

function Message({
  height = "5em",
  centered = true,
  style = {},
  ...etc
}: HTMLMotionProps<"div"> & {
  centered?: boolean;
  height?: CSSProperties["height"];
}) {
  return (
    <motion.div
      style={{
        ...(centered
          ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }
          : {}),
        ...style,
      }}
      initial={{ height: 0, opacity: 0 }}
      animate={{ opacity: 1, height }}
      exit={{ opacity: 0, height: 0 }}
      {...etc}
    />
  );
}

function NoSearches({ timeWindow }: { timeWindow: TimeWindow }) {
  // since changing the time window will always trigger a load
  // (and this component to be un-mounted), we do not want to
  // use any updated prop value, or the text will be change and be
  // visibly wrong during the exit animation

  const [cachedTimeWindow] = useState<TimeWindow>(timeWindow);

  const timeWindowDisplay =
    cachedTimeWindow === "today"
      ? " today"
      : cachedTimeWindow === "2weeks"
      ? " in the last two weeks"
      : " in the last year";

  return (
    <Message key="empty">
      <Typography sx={{ opacity: 0.6 }}>
        No searches run{timeWindowDisplay}
      </Typography>
    </Message>
  );
}

export default function StatsDisplay({
  isoStartDateTime,
  isoEndDateTime,
  timeWindow,
}: {
  isoStartDateTime: string;
  isoEndDateTime: string;
  timeWindow: TimeWindow;
}) {
  const api = useTrueBizApi();

  const [stats, setStats] = useState<APIAccountSearchSummary | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let cancelled = false;

    const loadStats = async (start?: string, end?: string) => {
      try {
        setLoading(true);
        setError(false);
        setStats(null);

        const stats = await api.getAccountSearchStats(start, end);
        if (cancelled) return;

        setStats(stats);
        setLoading(false);
      } catch {
        if (cancelled) return;
        setError(true);
        setLoading(false);
      }
    };

    loadStats(isoStartDateTime, isoEndDateTime);
    return () => {
      cancelled = true;
    };
  }, [api, isoStartDateTime, isoEndDateTime]);

  const failed = error || !stats;
  const empty = !stats ? true : stats.searches_performed === 0;

  const content = loading ? (
    <Message key="loader">
      <CircularProgress />
    </Message>
  ) : failed ? (
    <Message key="error">Search stats could not be loaded</Message>
  ) : empty ? (
    <NoSearches timeWindow={timeWindow} key="empty" />
  ) : (
    <Message height="auto" centered={false} key="content">
      <StatsDisplayWithoutDataLoader stats={stats} />
    </Message>
  );

  return <AnimatePresence>{content}</AnimatePresence>;
}
