import { object, string } from "yup";

export const MonitoringEditFormSchema = object({
  // merchantName: string().nullable(),
  // user_description: string().nullable(),
  // mcc: string().required(),
  external_ref_id: string().nullable(),

  // hidden
  frequency: string().nullable(),
  active_at: string().nullable(),
  profile_id: string().nullable(),
  domain: string(),
  monitor_type: string(),
  // emailAddress: string().email().nullable(),
  // phoneNumber: string()
  //   .test("phone number", "Enter a valid phone number", function (value) {
  //     if (!value) {
  //       return true;
  //     }

  //     try {
  //       let phoneNumber: any;

  //       try {
  //         phoneNumber = phoneUtil.parse(value, "US");
  //       } catch (e) {
  //         phoneNumber = undefined;
  //       }

  //       if (!phoneNumber) {
  //         try {
  //           phoneNumber = phoneUtil.parse(value);
  //         } catch (e) {
  //           phoneNumber = undefined;
  //         }
  //       }

  //       return phoneUtil.isPossibleNumber(phoneNumber);
  //     } catch (e) {
  //       return false;
  //     }
  //   })
  //   .nullable(),
  // address: object({
  //   line1: string().required(),
  //   line2: string().nullable(),
  //   city: string().required(),
  //   stateProvince: string().nullable(),
  //   postalCode: string().required(),
  //   country: string().required(),
  // })
  //   .transform((v) => {
  //     // only validate the address if we attempted to fill out any sub-fields
  //     if (isEmpty(pickBy(v))) return null;
  //     return v;
  //   })
  //   .nullable(),
});
