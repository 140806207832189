import { ReactElement, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

export interface Props {
  to: string;
  startIcon?: ReactElement;
  children: ReactNode;
}

export default function NavigationButton({ to, startIcon, children }: Props) {
  return (
    <Button
      component={NavLink}
      to={to}
      startIcon={startIcon}
      disableRipple
      disableTouchRipple
      disableFocusRipple
      sx={{
        "&:focus": {
          outline: "none",
        },
        "&:hover": {
          background: "transparent",
        },
        "&.active": {
          fontWeight: "bold",
        },
      }}
    >
      {children}
    </Button>
  );
}
