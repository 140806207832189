import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { round } from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import { breakLabelLines } from "./utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend);

export default function TableChart({
  data,
  showPercentages = false,
}: {
  data: {
    rank: number;
    count: number;
    total: number;
    description: string;
  }[];
  showPercentages?: boolean;
}) {
  const options = useMemo(
    () => ({
      maintainAspectRatio: true,
      indexAxis: "y" as const,
      scales: {
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
            format: {
              style: "percent",
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    }),
    []
  );

  const theme = useTheme();

  const labels = useMemo(() => {
    if (!data) return [];
    return data.map((d) =>
      breakLabelLines(
        showPercentages
          ? `${d.description} (${round(100 * (d.count / d.total), 0)}%)`
          : `${d.description} (${d.count})`,
        15
      )
    );
  }, [data, showPercentages]);

  const datasets = useMemo(() => {
    if (!data)
      return [
        {
          data: [],
          backgroundColor: theme.palette.primary.main,
          maxBarThickness: 8,
        },
      ];

    return [
      {
        data: data.map((d) => d.count / d.total),
        backgroundColor: theme.palette.primary.main,
        maxBarThickness: 8,
      },
    ];
  }, [data, theme.palette.primary.main]);

  return (
    <div style={{ paddingLeft: "2.5em", paddingRight: "2.5em" }}>
      <Bar
        width="350px"
        height="275px"
        options={options}
        data={{ labels, datasets }}
      />
    </div>
  );
}
