import { HTMLProps } from "react";
import cn from "classnames";
import { CircularProgress } from "@mui/material";

import styles from "./Loading.module.css";
import Delayed from "../Delayed";

export interface Props extends Exclude<HTMLProps<HTMLDivElement>, "children"> {
  initialDelayMs?: number;
}

export default function Loading({
  initialDelayMs = 750,
  className,
  ...etc
}: Props) {
  return (
    <div className={cn(styles.Loading, className)} {...etc}>
      <Delayed byMs={initialDelayMs}>
        <CircularProgress />
      </Delayed>
    </div>
  );
}
