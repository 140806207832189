import { useTheme, Chip } from "@mui/material";
import { orderBy } from "lodash";

import FlaggedConcernIcon from "./FlaggedConcernIcon";

import { CustomerReviewAnalysisFlag } from "../../../../types/APICustomerReviews";
import { humanizeString } from "../../../../utilities/formatting";
import { Optional } from "../../../../utilities/types";

export const humanizeFlaggedConcern = (
  flaggedConcern: CustomerReviewAnalysisFlag | string
) => {
  switch (flaggedConcern) {
    case CustomerReviewAnalysisFlag.CUSTOMER_SERVICE:
      return "Customer service";
    case CustomerReviewAnalysisFlag.DELIVERY:
      return "Delivery";
    case CustomerReviewAnalysisFlag.FRAUD:
      return "Fraud";
    case CustomerReviewAnalysisFlag.PRODUCT_AUTHENTICITY:
      return "Product authenticity";
    case CustomerReviewAnalysisFlag.PRODUCT_QUALITY:
      return "Product quality";
    case CustomerReviewAnalysisFlag.RETURN_OR_REFUND:
      return "Returns/refunds";
    default:
      return humanizeString(flaggedConcern);
  }
};

export interface Props {
  flaggedConcerns: Optional<CustomerReviewAnalysisFlag[]>;
}

export default function FlaggedConcerns({ flaggedConcerns }: Props) {
  const theme = useTheme();

  if (!flaggedConcerns?.length) return <>{null}</>;

  return (
    <>
      {orderBy(flaggedConcerns, (x) => x, "asc").map((flag) => {
        return (
          <Chip
            key={flag}
            icon={<FlaggedConcernIcon flag={flag} />}
            label={humanizeFlaggedConcern(flag)}
            variant="outlined"
            sx={{ borderColor: theme.palette.grey[300] }}
          />
        );
      })}
    </>
  );
}
