import { Fragment } from "react";

import { Box, Typography } from "@mui/material";

import Citations from "./Citations";
import { orderAnalysisQuestions } from "./utils";
import WebOnly from "../../WebOnly";
import {
  APIPolicyAnalysisAnalysis,
  APIPolicyAnalysisSource,
} from "../../../types/APIPolicyAnalysis";

export interface Props {
  analyses: APIPolicyAnalysisAnalysis[];
  allPolicySources: APIPolicyAnalysisSource[] | null | undefined;
  onSelectPolicyByURL: (url?: string | null | undefined) => void;
}

export default function OverallAnalysis({
  analyses,
  allPolicySources,
  onSelectPolicyByURL,
}: Props) {
  return (
    <Box>
      <WebOnly>
        <Typography variant="h6" sx={{ mb: "0.5em" }} component="h3">
          Policy Analysis
        </Typography>
      </WebOnly>
      {orderAnalysisQuestions(analyses)
        .filter((analysis) => analysis.answer != null)
        .map((analysis) => {
          return (
            <Fragment key={analysis.question}>
              <Typography variant="body1" sx={{ mb: "0.25em" }}>
                <strong>{analysis.question}</strong>
              </Typography>
              <Typography variant="body1" sx={{ mb: "1.5em" }}>
                {analysis.answer}{" "}
                <Citations
                  analysis={analysis}
                  allSources={allPolicySources}
                  onSelectCitationByURL={onSelectPolicyByURL}
                />
              </Typography>
            </Fragment>
          );
        })}
    </Box>
  );
}
