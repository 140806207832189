import { Fade, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  maxHeight: "95%",
  bgcolor: "background.paper",
  borderRadius: 1,
  overflow: "auto",
  outline: "none",
  boxShadow: 24,
  lineHeight: 1,
  p: 1.5,
};

export default function ScreenshotModal({
  imgUrl,
  visible,
  onClose,
}: {
  imgUrl: string;
  visible: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="screenshot-modal-title"
      aria-describedby="screenshot-modal-description"
    >
      <Fade in={visible}>
        <Box sx={modalStyle}>
          <Box
            position="sticky"
            left="calc(100% - 50px)"
            top={10}
            marginBottom={-5}
            borderRadius={20}
            width={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onClose}
            sx={{ cursor: "pointer", backgroundColor: "white" }}
          >
            <CloseIcon fontSize="large" />
          </Box>

          <img
            style={{ width: "100%" }}
            src={imgUrl}
            alt="Website screenshot"
          />
        </Box>
      </Fade>
    </Modal>
  );
}
