import * as React from "react";
import type { SVGProps } from "react";
const SvgTk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1365.333}
    height={1365.333}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M221.1 1.4c-1 1.2-.8 1.7.8 2.5 1.1.6 2.7 1.1 3.6 1.1 1.9 0 6.5-2.9 6.5-4.1 0-1.5-9.6-1-10.9.5M234.7 1.7C232.1 4.2 236.9 9 242 9c2.4 0 2.5-1.1.5-5-1.2-2.3-2.3-3-4.4-3-1.5 0-3.1.3-3.4.7M225 11.5c-1 1.1-1 1.9-.2 2.7 1.5 1.5 4.4.2 4-1.9-.4-2.3-2.2-2.7-3.8-.8M243.2 16.4c.1 2.6-.6 4-2.6 5.8-1.9 1.6-2.5 2.9-2.1 4 1.5 3.7 10.5-1.3 10.5-5.7 0-2.3-3.5-7.5-5-7.5-.5 0-.9 1.5-.8 3.4M224 17.9c0 1.5 2.9 3.3 4.5 2.7 2.8-1 1.7-3.6-1.5-3.6-1.6 0-3 .4-3 .9M227.3 24.7c-1 2.5 2.3 4.8 5.4 3.7 4.1-1.4 3.8-3-.7-4.2-3.4-.8-4.2-.8-4.7.5M472.3 231.7c-2.1.4-5.2 5.8-4 7 .4.3 2.6.9 4.9 1.4 3.9.7 4.5.6 6.5-1.6l2.2-2.5-2.3-2.5c-2.3-2.5-3-2.6-7.3-1.8M451.7 241.8c-2.1 2.3-2.1 3.6-.3 5.1 1.1.9 1.9.5 4-2 1.4-1.7 2.4-3.5 2-4-.9-1.5-4-1-5.7.9M479.5 244c-.3.5.1 1 .9 1 .9 0 1.6-.5 1.6-1 0-.6-.4-1-.9-1-.6 0-1.3.4-1.6 1M480.8 249.6c-.4.3.5 3.8 1.8 7.7 1.3 3.9 2.4 8.1 2.4 9.4 0 2.5 1 2.9 2.8 1.1 1.9-1.9 1.4-6.2-1.3-11.6-1.4-2.8-2.5-5.5-2.5-6.1 0-1.2-2.2-1.5-3.2-.5M452 252c0 1.2-1 3.4-2.2 5-2.1 2.9-2.1 3-.3 5s2 2 3.2.4c1.5-2.1 1.8-12.4.3-12.4-.5 0-1 .9-1 2M457.7 268.7c-1.4 1.3-.6 3.3 1.2 3.3 2.5 0 3.8-2 2-3.1-1.7-1.1-2.4-1.1-3.2-.2M476 271c0 .5.9 1 2.1 1 1.1 0 1.7-.5 1.4-1-.3-.6-1.3-1-2.1-1s-1.4.4-1.4 1M470.5 272c.3.5 1.3 1 2.1 1s1.4-.5 1.4-1c0-.6-.9-1-2.1-1-1.1 0-1.7.4-1.4 1M465.8 273.7c.6.2 1.8.2 2.5 0 .6-.3.1-.5-1.3-.5-1.4 0-1.9.2-1.2.5M727.8 324.4c-2.7 2-2.1 3.3 1.9 4.3 2.9.7 4.3 1.8 6.3 5.3 3.1 5.3 5.4 5.8 5.8 1.5.3-3.3-2.3-6.4-8.8-10.3-2.9-1.7-3.8-1.8-5.2-.8M734 346.2c.1 1.8.7 4.4 1.5 5.8.8 1.4 1.4 4.6 1.5 7.2 0 4.1.3 4.8 2 4.8s2-.7 2-5.1c0-2.8-.6-6.5-1.4-8.2-.8-1.8-1.6-4.1-1.8-5.2-.2-1.1-1.1-2.1-2.1-2.3-1.3-.3-1.7.3-1.7 3M721.1 346.3c-.8 1.1-.6 1.8.8 3.1 1.6 1.5 1.9 1.5 3.1.1 1-1.2 1-1.8 0-3-1.5-1.9-2.4-1.9-3.9-.2M729 357.5c.7.8 1.6 1.2 2.1.9 1.3-.8.1-2.4-1.8-2.4-1.3 0-1.3.3-.3 1.5M733 362c0 .5.5 1 1 1 .6 0 1-.5 1-1 0-.6-.4-1-1-1-.5 0-1 .4-1 1M791.3 1014c-5.1 2.1-.8 10 5.3 10 3.3 0 7.4-3.5 7.4-6.2 0-4-7-6.1-12.7-3.8" />
  </svg>
);
export default SvgTk;
