import { useState, useEffect } from "react";
import { Box, LinearProgress, CircularProgress } from "@mui/material";
import { motion, LayoutGroup } from "framer-motion";
import CheckIcon from "@mui/icons-material/Check";

const LOADING_STEPS = [
  "Reviewing website...",
  "Searching web presence...",
  "Assessing primary industry...",
  "Collecting people data...",
  "Aggregating traffic numbers...",
  "Analyzing social media presence...",
  "Collecting online reviews...",
  "Calculating likely risks...",
  "Generating website content report...",
  "Fetching website screenshot...",
];

const TEXT_ROW_HEIGHT_PX = 35;
const TEXT_SHOW_IN_VIEWPORT = 5;
const ESTIMATED_LOADING_TIME_MS = 45000;

export interface Props {
  loading?: boolean;
}

export default function SearchLoading({ loading = true }: Props) {
  const [progress, setProgress] = useState<number>(0);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [shownSteps, setShownSteps] = useState<number>(1);

  useEffect(() => {
    let timer: any;

    if (loading) {
      timer = setInterval(() => {
        setShownSteps((oldSteps) => {
          if (oldSteps === LOADING_STEPS.length) {
            setIndeterminate(true);
            return LOADING_STEPS.length;
          }

          return oldSteps + 1;
        });
      }, ESTIMATED_LOADING_TIME_MS / LOADING_STEPS.length);
    } else {
      if (timer) clearInterval(timer);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [loading]);

  useEffect(() => {
    let timer: any;

    if (loading) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setIndeterminate(true);
            return 0;
          }

          const diff = Math.random() * 2;
          return Math.min(oldProgress + diff, 100);
        });
      }, ESTIMATED_LOADING_TIME_MS / 100);
    } else {
      if (timer) clearInterval(timer);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setIndeterminate(false);
      setProgress(0);
      setShownSteps(1);
    }
  }, [loading]);

  return (
    <>
      <Box sx={{ width: "550px" }}>
        <LinearProgress
          value={progress}
          {...(indeterminate ? {} : { variant: "determinate" })}
        />
      </Box>

      <Box
        marginTop={5}
        height={TEXT_ROW_HEIGHT_PX * TEXT_SHOW_IN_VIEWPORT}
        width={350}
        position="relative"
        overflow="hidden"
      >
        <LayoutGroup>
          {new Array(shownSteps).fill(null).map((_, index) => {
            const topMargin =
              (index + TEXT_SHOW_IN_VIEWPORT) * TEXT_ROW_HEIGHT_PX -
              shownSteps * TEXT_ROW_HEIGHT_PX;

            const adjustedTopMargin =
              shownSteps < TEXT_SHOW_IN_VIEWPORT
                ? index * TEXT_ROW_HEIGHT_PX
                : topMargin;

            return (
              <motion.div
                key={index}
                style={{
                  position: "absolute",
                  marginBottom: "5px",
                  width: "400px",
                  opacity: 0,
                  top: adjustedTopMargin + TEXT_ROW_HEIGHT_PX / 2,
                }}
                animate={{
                  opacity: 1,
                  top: adjustedTopMargin,
                }}
              >
                <Box display="flex" alignItems="center" sx={{ fontSize: 20 }}>
                  {index === shownSteps - 1 ? (
                    <CircularProgress size={22} sx={{ marginRight: 1.3 }} />
                  ) : (
                    <CheckIcon sx={{ marginRight: 1 }} />
                  )}{" "}
                  {LOADING_STEPS[index]}
                </Box>
              </motion.div>
            );
          })}
        </LayoutGroup>
      </Box>
    </>
  );
}
