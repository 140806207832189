import { Component, ReactNode, ErrorInfo, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IdentityProvider } from "../components/IdentityProvider";
import { TrueBizApiProvider } from "../api";

/**
 * A testing harness to smooth (hack) over some of the limitations of using
 * Chart.js inside of js-dom.
 */
export class ChartJSHarness extends Component<{ children: ReactNode }> {
  caughtTeardownEventListenerError(error: Error, errorInfo: ErrorInfo) {
    // this appears to happen because there is some kind of race condition
    // between when the jsdom canvas nodes are destroyed and the chart
    // components are unmounted, causing the chart to attempt to
    // install event listeners on the destroyed (null) canvas elements.
    //
    // This could probably be resolved with enabling `act` support in out
    // testing environment, but I couldn't figure out a quick and easy way
    // to do that.

    if (!error.message) return false;
    if (!error.stack) return false;

    return (
      error.message.match(
        /Cannot read properties of null \(reading 'addEventListener'\)/
      ) &&
      errorInfo.componentStack.match(/at ChartComponent/) &&
      error.stack.match(/at Chart.update/)
    );
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.caughtTeardownEventListenerError(error, errorInfo)) {
      return;
    }

    throw error;
  }

  render() {
    return this.props.children;
  }
}

export function ApiKeyIdentityHarness({ children }: { children: ReactNode }) {
  return <IdentityProvider type="apiKey">{children}</IdentityProvider>;
}

export function TrueBizApiHarness({ children }: { children: ReactNode }) {
  return (
    <TrueBizApiProvider>
      <>{children}</>
    </TrueBizApiProvider>
  );
}

export function ReactQueryHarness({ children }: { children: ReactNode }) {
  const [client] = useState(() => new QueryClient());
  return (
    <QueryClientProvider client={client}>
      <>{children}</>
    </QueryClientProvider>
  );
}

/**
 * Convert a data: url to a Blob object
 *
 * See: https://stackoverflow.com/a/67551175
 * @param dataUrl
 * @returns Blob
 */
export const dataUrlToBlob = (dataUrl: string) => {
  const [header, body] = dataUrl.split(",");
  const mime = header.match(/:(.*?);/)?.[1];

  const bytes = atob(body);
  const u8arr = new Uint8Array(bytes.length);

  for (let i = 0; i < bytes.length; i++) {
    u8arr[i] = bytes.charCodeAt(i);
  }

  return new Blob([u8arr], { type: mime });
};
