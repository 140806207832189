import APIUser from "./APIUser";

export enum APINotificationStatus {
  Open = "Open",
  Assigned = "Assigned",
  Resolved = "Resolved",
}

export enum APINotificationOrderBy {
  createdAtAsc = "created_at",
  createdAtDesc = "-created_at",
  domainAsc = "domain",
  domainDesc = "-domain",
  statusAsc = "status",
  statusDesc = "-status",
  assigneeAsc = "assignee",
  assigneeDesc = "-assignee",
  messagesAsc = "messages",
  messagesDesc = "-messages",
}

export type APINotificationFilterBy =
  | { column: "created"; operator: "before"; value: ISODateTime }
  | { column: "created"; operator: "after"; value: ISODateTime }
  | { column: "domain"; operator: "in"; value: string[] }
  | { column: "assignee"; operator: "in"; value: string[] }
  | { column: "status"; operator: "in"; value: APINotificationStatus[] }
  | { column: "type"; operator: "in"; value: APINotificationType[] }
  | { column: "messages"; operator: "contains"; value: string[] }
  | { column: "id"; operator: "in"; value: UUID[] }
  | { column: "monitor"; operator: "eq"; value: UUID }
  | { column: "fuzzy_search"; operator: "eq"; value: string };

export enum APINotificationType {
  CustomerReviews = "monitoring.enquiry.remarks.customer_reviews",
  IndustryClassification = "monitoring.enquiry.remarks.industry_classification",
  People = "monitoring.enquiry.remarks.people",
  SiteContent = "monitoring.enquiry.remarks.site_content",
  SiteHealth = "monitoring.enquiry.remarks.site_health",
  SiteTraffic = "monitoring.enquiry.remarks.site_traffic",
}

export enum APINotificationResourceType {
  MonitorEnquiry = "enquiry",
  WebPresenceReview = "companymatchrequest",
}

export default interface APINotification {
  id: string;
  created_at: ISODateTime;
  updated_at: ISODateTime | null;
  type: string;
  messages: string[];
  resource_type: string | null | undefined;
  resource_id: UUID | null | undefined;
  subject_urn: string | null;
  acknowledged_at: ISODateTime | null;
  acknowledged_by: string | null;
  acknowledged_reason: string | null;
  acknowledged_notes: string | null;
  status: APINotificationStatus | null | undefined;

  assigned_to: APIUser | null | undefined;

  links: {
    activity: string;
    comments: string;
    monitor?: string;
  };

  UNSAFE_INTERNAL_UNSTABLE_DO_NOT_USE_remark_ids: UUID[];
}
