import { useCallback } from "react";

import { flatten, identity, isEqual, uniq } from "lodash";
import { TableOrderBy, TableFilterBy, TableRow } from "../IssuesTable/types";
import APINotification, {
  APINotificationOrderBy,
  APINotificationFilterBy,
  APINotificationType,
} from "../../../types/APINotification";
import { useTrueBizApi } from "../../../api";

export const wait = async (timeout = 500) => {
  await new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const messageToAlert = (message: string) =>
  message.match(/^Found site content related to (.+)\.$/)?.[1] || message;

export const alertToMessage = (alert: string) =>
  `Found site content related to ${alert}.`;

export const tableOrderByToServerOrderBy = (
  tableOrderBy: TableOrderBy[] = []
): APINotificationOrderBy[] => {
  const serverOrderBy: APINotificationOrderBy[] = [];

  for (const ordering of tableOrderBy) {
    switch (ordering) {
      case "alerts":
        serverOrderBy.push(APINotificationOrderBy.messagesAsc);
        continue;
      case "-alerts":
        serverOrderBy.push(APINotificationOrderBy.messagesDesc);
        continue;
      case "assignee":
        serverOrderBy.push(APINotificationOrderBy.assigneeAsc);
        continue;
      case "-assignee":
        serverOrderBy.push(APINotificationOrderBy.assigneeDesc);
        continue;
      case "date":
        serverOrderBy.push(APINotificationOrderBy.createdAtAsc);
        continue;
      case "-date":
        serverOrderBy.push(APINotificationOrderBy.createdAtDesc);
        continue;
      case "domain":
        serverOrderBy.push(APINotificationOrderBy.domainAsc);
        continue;
      case "-domain":
        serverOrderBy.push(APINotificationOrderBy.domainDesc);
        continue;
      case "status":
        serverOrderBy.push(APINotificationOrderBy.statusAsc);
        continue;
      case "-status":
        serverOrderBy.push(APINotificationOrderBy.statusDesc);
        continue;
      default:
        if (process.env.NODE_ENV === "development") {
          console.error(
            `Received request to order table column by unsupported ordering "${ordering}"`
          );
        }
    }
  }

  return serverOrderBy;
};

export const tableFilterByToServerFilterBy = (
  tableFilterBy: TableFilterBy[] = []
): APINotificationFilterBy[] => {
  const serverFilterBy: APINotificationFilterBy[] = [];

  for (const filtering of tableFilterBy) {
    if (filtering.column === "alerts" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "messages",
        operator: "contains",
        value: filtering.value.map(alertToMessage),
      });
    }

    if (filtering.column === "assignee" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "assignee",
        operator: "in",
        value: filtering.value,
      });
    }

    if (filtering.column === "date" && filtering.operator === "gt") {
      serverFilterBy.push({
        column: "created",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "date" && filtering.operator === "gte") {
      serverFilterBy.push({
        column: "created",
        operator: "after",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "date" && filtering.operator === "lt") {
      serverFilterBy.push({
        column: "created",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }
    if (filtering.column === "date" && filtering.operator === "lte") {
      serverFilterBy.push({
        column: "created",
        operator: "before",
        value: filtering.value.toISOString(),
      });
    }

    if (filtering.column === "domain" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "domain",
        operator: "in",
        value: filtering.value,
      });
    }

    if (filtering.column === "status" && filtering.operator === "in") {
      serverFilterBy.push({
        column: "status",
        operator: "in",
        value: filtering.value,
      });
    }
  }

  return serverFilterBy;
};

export function partitionBy<T>(
  arr: T[],
  pred: (t: T) => any = identity,
  equal = isEqual
): T[][] {
  // like https://clojuredocs.org/clojure.core/partition-by

  if (!arr.length) {
    return [];
  }

  const result: T[][] = [];

  let lastTestValue = pred(arr[0]);
  let buff: T[] = [];

  for (const a of arr) {
    const testValue = pred(a);

    if (equal(testValue, lastTestValue)) {
      buff.push(a);
      continue;
    }

    result.push(buff);
    lastTestValue = testValue;
    buff = [a];
  }

  if (buff.length) {
    result.push(buff);
  }

  return result;
}

export const mergeServerFilterBy = (
  left: APINotificationFilterBy[],
  right: APINotificationFilterBy[]
): APINotificationFilterBy[] => {
  const merged: APINotificationFilterBy[] = [];

  const grouped = partitionBy([...left, ...right], (f) => [
    f.column,
    f.operator,
  ]);
  for (const dupes of grouped) {
    if (dupes.length === 1) {
      merged.push(...dupes);
      continue;
    }

    merged.push({
      column: dupes[0].column,
      operator: dupes[0].operator,
      value: uniq(flatten(dupes.map((d) => d.value))),
    } as any);
  }

  return merged as APINotificationFilterBy[];
};

export const extractMonitorIdFromNotificationMonitorLink = (
  link?: string | null | undefined
) => {
  if (!link) return;
  return link.match(
    /^https?:\/\/.*\/api\/v1\/monitoring\/items\/([0-9A-Fa-f-]+)\/?$/
  )?.[1];
};

export const serverNotificationToTableIssue = (
  notification: APINotification
): TableRow => ({
  ...notification,
  date: notification.created_at,
  domain: notification.subject_urn?.replace("urn:domain:", ""),
  alerts: notification.messages.map(messageToAlert),
  messages: notification.messages,
  assignee: notification.assigned_to,
  acknowledged_reason: notification.acknowledged_reason,
  acknowledged_by: notification.acknowledged_by,
  acknowledged_at: notification.acknowledged_at,
  acknowledged_notes: notification.acknowledged_notes,
  monitorId: extractMonitorIdFromNotificationMonitorLink(
    notification.links.monitor
  ),
});

export const useGetIssuesByIds = () => {
  const api = useTrueBizApi();

  return useCallback(
    async (ids: string[]) => {
      // this is a hack for now, eventually we should offload this
      // to a server-side csv generator

      const limit = 50;
      let offset = 0;
      let pagesRequested = 0;
      let done = false;

      const issues: TableRow[] = [];

      do {
        const response = await api.getNotifications({
          limit,
          offset,
          filterBy: [
            {
              column: "type",
              operator: "in",
              value: [APINotificationType.SiteContent],
            },
            { column: "id", operator: "in", value: ids },
          ],
        });

        issues.push(...response.items.map(serverNotificationToTableIssue));

        if (response.offset + response.limit >= response.count) {
          done = true;
          break;
        }

        offset = response.offset + response.limit;
        pagesRequested++;
        await wait();
      } while (pagesRequested < 500);

      if (!done) {
        throw new Error("Too many pages requested!");
      }

      return { issues };
    },
    [api]
  );
};
