export const isTruthy = (children: any): boolean => {
  /*
  JavaScript defaults for truthiness can be problematic when rendering content.  Values like zero
  may be renderable.  So, this is a 'isTruthy' helper to solve those particular problems...

  This is *NOT* a universally usable tool.  Use only when appropriate.
   */
  let value = !!children;
  if (typeof children == "number") {
    return children === 0 ? true : value;
  }
  return value;
};
