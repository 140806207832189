import { Box, Typography } from "@mui/material";

import ReviewDetails from "./ReviewDetails";
import NoReviews from "./NoReviews";

import { Optional } from "../../../utilities/types";
import APICustomerReviews from "../../../types/APICustomerReviews";

export interface Props {
  reviews: Optional<APICustomerReviews>;
}

export default function CustomerReviews({ reviews }: Props) {
  const hasReviews = !!reviews?.providers?.length;

  return (
    <Box>
      <Typography
        variant="h6"
        component="h3"
        sx={{ fontWeight: "normal" }}
        mb="1em"
      >
        Reviews
      </Typography>

      {hasReviews ? <ReviewDetails reviews={reviews} /> : <NoReviews />}
    </Box>
  );
}
