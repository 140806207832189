import { useState, useMemo, useCallback } from "react";
import {
  Button,
  Stack,
  IconButton,
  Input,
  CircularProgress,
  Box,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { orderBy } from "lodash";
import { matchSorter } from "match-sorter";

import MonitorRow from "./MonitorRow";
import styles from "./MonitorList.module.css";

import APIMonitor, { APIMonitorType } from "../../../../types/APIMonitor";
import { useTrueBizApi } from "../../../../api";

const useGetAllMonitors = () => {
  const api = useTrueBizApi();
  return useCallback(async () => {
    const wait = async (timeout = 400) => {
      await new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    };

    const allMonitors: APIMonitor[] = [];

    let limit = 50;
    let offset = 0;

    let i = 0;
    let done = false;

    do {
      const result = await api.getMonitors(offset, limit, null, [
        APIMonitorType.WebsiteContent,
      ]);
      allMonitors.push(...result.items);

      if (result.offset + result.limit >= result.count) {
        done = true;
        break;
      }

      offset = offset + limit;
      i++;
      await wait();
    } while (i < 1_000);

    if (!done) {
      throw new Error("Failed after attempting to request too many pages.");
    }

    return { monitors: allMonitors };
  }, [api]);
};

export interface Props {
  onClose: () => void;
  onCreateMonitor: () => void;
  onDeleteMonitor: (monitorId: string) => Promise<void>;
  onEditMonitor: (monitorId: string) => void;
}

export default function MonitorList({
  onClose,
  onCreateMonitor,
  onDeleteMonitor,
  onEditMonitor,
}: Props) {
  const getAllMonitors = useGetAllMonitors();

  const allMonitorsQuery = useQuery({
    queryKey: ["allMonitors"],
    queryFn: () => getAllMonitors(),
  });

  const [monitorSearch, setMonitorSearch] = useState("");
  const allMonitors = useMemo(
    () =>
      orderBy(
        allMonitorsQuery.data?.monitors || ([] as APIMonitor[]),
        ["domain"],
        ["asc"]
      ),
    [allMonitorsQuery.data?.monitors]
  );
  const filteredMonitors = useMemo(
    () =>
      monitorSearch
        ? matchSorter(allMonitors, monitorSearch, {
            keys: ["domain", "nextRun", "previousRun"],
          })
        : allMonitors,
    [monitorSearch, allMonitors]
  );

  const listLoading = allMonitorsQuery.isFetching && !allMonitorsQuery.data;

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        height: "100%",
        maxHeight: "100%",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
      }}
    >
      <h2>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
        Active Enrollments
      </h2>
      <Stack sx={{ overflow: "hidden" }}>
        <div style={{ display: "flex", gap: "1em" }}>
          <Input
            placeholder="Search enrollments..."
            sx={{
              display: "flex",
              flex: 1,
              minHeight: "40px",
            }}
            value={monitorSearch}
            onChange={(e) => {
              setMonitorSearch(e.target.value);
            }}
            endAdornment={
              monitorSearch === "" ? null : (
                <IconButton
                  onClick={() => {
                    setMonitorSearch("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          />
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              onCreateMonitor();
            }}
          >
            Enroll
          </Button>
        </div>
        <div
          className={styles.monitorsList}
          style={{ overflow: "scroll", paddingBottom: "0.5em" }}
        >
          {listLoading ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={2}
            >
              <CircularProgress />
            </Box>
          ) : (
            filteredMonitors.map((monitor) => (
              <MonitorRow
                key={monitor.id}
                monitor={monitor}
                onEdit={async (id) => {
                  onEditMonitor(id);
                }}
                onDelete={async (id) => {
                  await onDeleteMonitor(id);
                }}
              />
            ))
          )}
        </div>
      </Stack>
    </Stack>
  );
}
