import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Stack } from "@mui/material";
import MonitorTimelineBody from "./MonitorTimelineBody";
import { useTrueBizApi } from "../../../api";
import APIEnquiry from "../../../types/APIEnquiry";
import APINotification, {
  APINotificationOrderBy,
} from "../../../types/APINotification";

export interface Props {
  monitorId: UUID;
  focusedIssueId?: UUID | null | undefined;
}

const useGetAllMonitorEnquiries = () => {
  const api = useTrueBizApi();

  return useCallback(
    async (monitorId: UUID) => {
      const wait = async (timeout = 400) => {
        await new Promise((resolve) => {
          setTimeout(resolve, timeout);
        });
      };

      const allEnquiries: APIEnquiry[] = [];

      let limit = 50;
      let offset = 0;

      let i = 0;
      let done = false;

      do {
        const result = await api.getEnquiriesByMonitor(offset, limit, {
          id: monitorId,
        });
        allEnquiries.push(...result.items);

        if (result.offset + result.limit >= result.count) {
          done = true;
          break;
        }

        offset = offset + limit;
        i++;
        await wait();
      } while (i < 1_000);

      if (!done) {
        throw new Error("Failed after attempting to request too many pages.");
      }

      return { enquiries: allEnquiries };
    },
    [api]
  );
};

const useGetAllMonitorNotifications = () => {
  const api = useTrueBizApi();

  return useCallback(
    async (monitorId: UUID) => {
      const wait = async (timeout = 400) => {
        await new Promise((resolve) => {
          setTimeout(resolve, timeout);
        });
      };

      const allNotifications: APINotification[] = [];

      let limit = 50;
      let offset = 0;

      let i = 0;
      let done = false;

      do {
        const result = await api.getNotifications({
          limit,
          offset,
          orderBy: [APINotificationOrderBy.createdAtAsc],
          filterBy: [{ column: "monitor", operator: "eq", value: monitorId }],
        });

        allNotifications.push(...result.items);

        if (result.offset + result.limit >= result.count) {
          done = true;
          break;
        }

        offset = offset + limit;
        i++;
        await wait();
      } while (i < 1_000);

      if (!done) {
        throw new Error("Failed after attempting to request too many pages.");
      }

      return { notifications: allNotifications };
    },
    [api]
  );
};

export default function MonitorTimeline({ monitorId, focusedIssueId }: Props) {
  const api = useTrueBizApi();

  const getAllMonitorEnquiries = useGetAllMonitorEnquiries();
  const getAllMonitorNotifications = useGetAllMonitorNotifications();

  const monitorQuery = useQuery({
    queryKey: ["getMonitor", monitorId],
    queryFn: () => api.getMonitor(undefined, monitorId),
    throwOnError: true,
  });

  const monitorActivityQuery = useQuery({
    queryKey: ["getMonitorActivity", monitorId],
    queryFn: () => api.getMonitorActivity(monitorId),
    throwOnError: true,
  });

  const monitorEnquiriesQuery = useQuery({
    queryKey: ["getMonitorEnquiries", monitorId],
    queryFn: () => getAllMonitorEnquiries(monitorId),
    throwOnError: true,
  });

  const monitorNotificationsQuery = useQuery({
    queryKey: ["getMonitorNotifications", monitorId],
    queryFn: () => getAllMonitorNotifications(monitorId),
    throwOnError: true,
  });

  const focusedIssueCommentsQuery = useQuery({
    queryKey: ["getNotificationComments", focusedIssueId],
    queryFn: () => api.getNotificationComments(focusedIssueId!),
    enabled: focusedIssueId != null,
  });

  const focusedIssueActivityQuery = useQuery({
    queryKey: ["getNotificationActivity", focusedIssueId],
    queryFn: () => api.getNotificationActivity(focusedIssueId!),
    enabled: focusedIssueId != null,
  });

  const loading =
    monitorQuery.isLoading ||
    monitorActivityQuery.isLoading ||
    monitorEnquiriesQuery.isLoading ||
    monitorNotificationsQuery.isLoading ||
    focusedIssueCommentsQuery.isLoading ||
    focusedIssueActivityQuery.isLoading;

  return loading ? (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  ) : (
    <MonitorTimelineBody
      monitor={monitorQuery.data!}
      activity={monitorActivityQuery.data!}
      enquiries={monitorEnquiriesQuery.data?.enquiries!}
      notifications={monitorNotificationsQuery.data?.notifications!}
      focusedNotificationId={focusedIssueId}
    />
  );
}
