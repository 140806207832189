/* eslint-disable jsx-a11y/iframe-has-title */
import { Box } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useState } from "react";

import IframeModal from "./IframeModal";

export function IframeEmbed({
  loading,
  setLoading,
  url,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  url: string;
}) {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Box
        display={loading ? "none" : "flex"}
        onClick={() => {
          setShowModal(true);
        }}
        sx={{ cursor: "pointer" }}
      >
        <Box
          position="sticky"
          left="calc(100% - 35px)"
          top={10}
          marginBottom={-5}
          borderRadius={20}
          width={25}
          height={25}
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: "pointer", backgroundColor: "white" }}
        >
          <OpenInFullIcon fontSize="small" />
        </Box>

        <iframe
          src={url}
          width="100%"
          height="100%"
          style={{
            pointerEvents: "none",
            overflow: "hidden",
            minHeight: "300px",
          }}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
          }}
          tabIndex={-1}
          sandbox="allow-scripts allow-same-origin"
          scrolling="no"
        ></iframe>
      </Box>

      <IframeModal
        key={url}
        visible={showModal}
        url={url}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}
