import { startCase } from "lodash";

import APIWebsiteContent from "../../../types/APIWebsiteContent";
import {
  APIPolicyAnalysisAnalysis,
  APIPolicyAnalysisSource,
  APIPolicyAnalysisSourceType,
} from "../../../types/APIPolicyAnalysis";

export function getPrettyShortPolicyName(name: string | null | undefined) {
  if (!name) return "";
  switch (name) {
    case "cancellation":
      return "Cancellation";
    case "privacy":
      return "Privacy";
    case "returns":
      return "Returns";
    case "shipping":
      return "Shipping";
    case "tos":
      return "Terms of Service";
    default:
      return startCase(name.replace(/\s+policy$/i, ""));
  }
}

export function getPrettyLongPolicyName(name: string | null | undefined) {
  if (!name) return "";
  const lowered = name.toLowerCase();

  if (lowered.endsWith("policy") || lowered.endsWith("service")) {
    return startCase(lowered);
  }

  const prettyShort = getPrettyShortPolicyName(lowered);

  if (prettyShort?.toLowerCase().endsWith("service")) {
    return startCase(prettyShort || "Unknown Policy");
  }

  return startCase(`${prettyShort} policy`);
}

export function intersperse<T>(
  arr: T[],
  val: T extends Function ? never : T | ((a: T, b: T) => T)
): T[] {
  if (arr.length < 2) {
    return [...arr];
  }

  const result: T[] = [];
  for (let i = 0; i < arr.length; i++) {
    const thisItem = arr[i];
    result.push(thisItem);

    if (i === arr.length - 1) {
      // last item
      break;
    }

    const nextItem = arr[i + 1];
    const joiner = typeof val === "function" ? val(thisItem, nextItem) : val;

    result.push(joiner);
  }

  return result;
}

export function orderAnalysisQuestions(
  analyses: APIPolicyAnalysisAnalysis[] | null | undefined
) {
  if (!analyses) return [];

  const expectedOrder = [
    // refund-related questions
    /What is the type of refund policy/i,
    /What is the refund policy/i,
    /How can a customer return purchased goods/i,

    // shipping
    /What is the estimated days it takes to fulfill an order/i,
    /Does the policy detail how and where products are shipped and under what timeline/i,

    // privacy
    /Does the policy detail the website's privacy policy and how the data of customers is protected, used or disclosed/i,
    /Does the policy detail how customers can cancel their accounts, orders, subscriptions or reservations/i,
    /Does the policy detail how consumer data is protected/i,
    /Does the policy detail when payment information is transmitted and how payment information is protected/i,
  ];

  const result: APIPolicyAnalysisAnalysis[] = [];
  let remaining = [...analyses];

  for (const order of expectedOrder) {
    for (let i = 0; i < remaining.length; i++) {
      const analysis = remaining[i];
      if (analysis.question.match(order)) {
        result.push(analysis);
        remaining = remaining.filter((_, idx) => idx !== i);
        break;
      }
    }
  }

  result.push(...remaining);
  return result;
}

export function getLegacyPolicyLinkForPolicyType(
  websiteContent: APIWebsiteContent | null,
  type: APIPolicyAnalysisSourceType
) {
  switch (type) {
    case "cancellation":
      return websiteContent?.cancellation_policy_link;
    case "privacy":
      return websiteContent?.privacy_policy_link;
    case "refunds":
      return websiteContent?.refund_policy_link;
    case "returns":
      return websiteContent?.return_policy_link;
    case "shipping":
      return websiteContent?.shipping_policy_link;
    case "tos":
      return websiteContent?.terms_of_service_link;
    default:
      return null;
  }
}

export function getLegacyHasSuspiciousContentForPolicyType(
  websiteContent: APIWebsiteContent | null,
  type: APIPolicyAnalysisSourceType
) {
  switch (type) {
    case "privacy":
      return websiteContent
        ? websiteContent.is_privacy_policy_suspicious
        : null;
    case "tos":
      return websiteContent
        ? websiteContent.is_terms_of_service_suspicious
        : null;
    default:
      return null;
  }
}

export function getHasSuspiciousContentForPolicy(
  policy: APIPolicyAnalysisSource | null
) {
  if (!policy) return null;
  return policy.has_placeholder_text;
}
