import * as React from "react";
import type { SVGProps } from "react";
const SvgCk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1365.333}
    height={1365.333}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="m293.5 137.6-27.9 7.4-11.7-1.2-11.8-1.2-15.3 3.7c-15.1 3.5-15.5 3.6-23.3 2.3-6.4-1-13.3-1-34.9 0-14.8.7-27.2 1.6-27.6 2.1-10.5 11.8-46.4 50.3-73.7 79L32 266.9l-13.8 27.8-13.8 27.8-2.3 18-2.3 18L3.4 384c2 14.1 5.8 36.6 8.5 50l4.9 24.5.6 27.5c.7 27.4.7 27.6 6.1 58.5 3 17 6.1 35.3 7 40.5.8 5.2 1.8 10.5 2 11.7.3 1.4 3.6 4.4 8.4 7.8l7.8 5.5.7 6.2c.3 3.5 1.7 11.5 3.1 17.8 2.3 11.3 2.7 12 15.8 35.2 10.7 18.9 15.8 26.7 25.1 38.6l11.8 14.8 32.1 8.7c17.7 4.8 41.4 10.6 52.7 12.8 27.3 5.4 61.9 15 92.5 25.6 23.7 8.2 26 9.3 43 19.2l17.9 10.5 22.6 5.3c44.2 10.3 112.8 20.2 193.9 27.8l27.4 2.6 8.1 6.2c4.5 3.5 17 11.8 27.9 18.6 16.4 10.1 23.3 13.7 40.9 21.2l21.1 9 20.6 1.8 20.6 1.9 26.5-5.3c14.6-3 38-7.9 52-11l25.5-5.7 25.5-13c14-7.2 29.8-15.5 35-18.4 8.8-5 9.8-5.8 13.3-11.7 3.2-5.4 3.7-7.3 3.7-12.4 0-5.5.4-6.7 5.3-14.5 2.9-4.6 8.8-12.6 13.1-17.8 4.3-5.2 8.2-10.5 8.7-11.7.4-1.2 2.7-9.5 5.1-18.4 4.2-16.2 4.3-16.4 10.5-24.4 3.5-4.4 9-12 12.3-16.9 5.9-8.9 6-9 6-16 0-4.4-.9-10.1-2.4-15.1-4.8-16.5-.9-34.2 9.8-44.5 4.4-4.2 4.6-4.7 4.6-9.8 0-4.5.8-6.9 4.3-13.4 4.4-8.4 5.2-9.1 13-10.2 4.7-.7 5.1-.6 9.7 3.3l4.9 4.1 1.2-2.2c2-3.6 1.2-21-1.1-28.3-1.8-5.5-2.8-6.8-11.9-14.7-8.7-7.6-10.7-10-15.5-18.5-8.7-15.5-20.3-42.1-27.2-62.8-6.4-19-6.4-19-5.8-27.5.5-7 1.1-9.3 3.5-13.1 5-7.9 3.9-12.5-6.1-26.8l-6.5-9.2-3.1-32.9-3-32.8-6.2-9.5c-5.7-8.6-7.1-9.9-16.1-16.1-6.4-4.3-13.4-8-19.8-10.4-5.4-2-15.2-6.6-21.8-10.2-11.5-6.3-12.3-7-24.8-20.5-7.1-7.7-18.7-21.5-25.8-30.7-11.9-15.3-13.7-17.2-23.9-24.8-6.1-4.4-16.8-11.8-23.8-16.2-7-4.5-17-11.4-22.2-15.3-5.2-3.9-13.8-10.1-19.1-13.7l-9.5-6.6-41.5 8.8c-22.8 4.8-53.1 11.7-67.4 15.3-20.2 5.1-31.8 7.4-52 10.2-34 4.8-44.2 4.5-70.5-2-9.6-2.3-21.3-4.5-25.9-4.9-4.6-.3-8.6-.8-9-1.1-.3-.3-1-4.5-1.6-9.3l-1-8.7h-25.2l-24.2-16.4c-16-10.8-28.2-18.2-35.9-21.8-11.5-5.4-11.6-5.5-24.6-6.4-7.2-.4-15.6-1-18.6-1.3-4.5-.3-10.7 1-33.5 7" />
    <path d="m1011.8 622.2-3.5 6.3-6.9.3-6.9.3-3.4 4.7c-3.7 5-5.8 9.9-6.6 15.9-.4 3.4-.1 4.1 2.9 6.5l3.3 2.8 8.1-1.2 8.1-1.2 8.6-11.7c8.8-12 9-12.7 7.9-21.1-.6-3.9-1.1-4.8-4.1-6.2-1.8-.9-3.5-1.6-3.7-1.6-.3 0-2 2.8-3.8 6.2M999.4 667.7c-1.7.2-4.3 1.7-5.8 3.2l-2.6 2.7 2.9 4.1 3 4.1-3 4.1c-2.4 3.4-2.9 5-2.9 10.1 0 5.8 3.2 15 5.2 15 .5 0 4.2-1.8 8.3-4.1 6.5-3.5 7.6-4.5 8.4-7.7 3.7-14 4-16.1 3.5-20.9-.6-4.8-.9-5.2-5.8-8.2-2.9-1.7-5.9-3-6.6-2.9-.8 0-2.9.3-4.6.5M988 730c-4.1 6.2-5.1 8.5-4.8 11.2.7 7.8 10.9 1.7 14.1-8.5 2.3-7.3 2.2-8.3-1.2-9.5-2.9-1-3-.9-8.1 6.8M943.1 787.9c-1.9.5-2.8 1.8-3.7 5.5-1.2 4.6-1.1 4.9 1.8 9.1l3.1 4.4 3.7-1.9c5.2-2.7 6.4-7.9 3.3-14.5-1.7-3.6-3.1-4.1-8.2-2.6" />
  </svg>
);
export default SvgCk;
