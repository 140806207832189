import { useCallback, useRef, useState } from "react";
import { Button, Checkbox, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface Props {
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<unknown>, checked: boolean) => void;
  onSelectAllOnPage?: () => void;
  onSelectNoneOnPage?: () => void;
  onSelectNone?: () => void;
  anySelected?: boolean;
  anySelectedOnPage?: boolean;
  allSelectedOnPage?: boolean;
}

export default function ColumnHeader({
  checked,
  indeterminate,
  disabled,
  onChange,
  onSelectAllOnPage,
  onSelectNoneOnPage,
  onSelectNone,
  anySelected,
  anySelectedOnPage,
  allSelectedOnPage,
}: Props) {
  const [el, setEl] = useState<HTMLElement | null>(null);
  const inputRef = useRef(null);

  const close = useCallback(() => {
    setEl(null);
  }, []);

  return (
    <>
      <Button
        disabled={disabled}
        variant="text"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => {
          if (e.target === inputRef.current) return;
          setEl(e.currentTarget);
        }}
        sx={{
          pl: 0,
          pt: 0,
          pb: 0,
          "> .MuiButton-endIcon": {
            ml: 0,
          },
        }}
      >
        <Checkbox
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e, checked) => {
            onChange?.(e, checked);
          }}
          inputRef={inputRef}
          disableRipple
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
        />
      </Button>
      <Menu
        open={!disabled && el != null}
        onClose={() => setEl(null)}
        anchorEl={el}
      >
        <MenuItem
          onClick={() => {
            onSelectAllOnPage?.();
            close();
          }}
          disabled={allSelectedOnPage}
        >
          All on page
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectNoneOnPage?.();
            close();
          }}
          disabled={!anySelectedOnPage}
        >
          None on page
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectNone?.();
            close();
          }}
          disabled={!anySelected}
        >
          None
        </MenuItem>
      </Menu>
    </>
  );
}
