import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Input,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { matchSorter } from "match-sorter";

import { useApply } from "./hooks";

export interface Props<T = string> {
  allValues: T[] | null | undefined;
  stickyValues?: T[] | null | undefined;
  selectedValues: T[] | null | undefined;
  onApply: (selected: T[] | null | undefined) => void;
}

export default function ValuePickerFilter<T = string>({
  allValues,
  stickyValues,
  selectedValues,
  onApply,
}: Props<T>) {
  const [searchValue, setSearchValue] = useState("");
  const filteredValues = useMemo(() => {
    const filtered = [];
    const searched = matchSorter(allValues || [], searchValue);

    for (const stickyValue of stickyValues || []) {
      if (searched.includes(stickyValue)) {
        filtered.push(stickyValue);
      }
    }

    return filtered.concat(searched.filter((f) => !stickyValues?.includes(f)));
  }, [allValues, stickyValues, searchValue]);
  const [selected, setSelected] = useState<T[]>(() => selectedValues || []);

  const apply = useCallback(() => {
    onApply(selected);
    setSearchValue("");
  }, [onApply, selected]);

  useApply(apply);

  useEffect(() => {
    if (!allValues) return;
    if (!selectedValues) return;

    setSelected(selectedValues);
  }, [allValues, selectedValues]);

  if (!allValues?.length) return null;

  return (
    <div style={{ marginTop: "1em" }}>
      <div style={{ display: "flex", gap: "0.5em" }}>
        <Button
          size="small"
          onClick={() => {
            setSelected((old) => {
              const newItems: T[] = [];
              for (const visibleItem of filteredValues) {
                if (!old.includes(visibleItem)) {
                  newItems.push(visibleItem);
                }
              }

              if (!newItems.length) {
                return old;
              }

              return [...old, ...newItems];
            });
          }}
        >
          Select all
        </Button>
        <Button
          size="small"
          onClick={() => {
            setSelected((old) => {
              const newItems: T[] = [];
              for (const visibleItem of filteredValues) {
                if (old.includes(visibleItem)) {
                  newItems.push(visibleItem);
                }
              }

              if (!newItems.length) {
                return old;
              }

              return old.filter((x) => !newItems.includes(x));
            });
          }}
        >
          Clear all
        </Button>
      </div>
      <Input
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        endAdornment={
          searchValue === "" ? null : (
            <IconButton onClick={() => setSearchValue("")}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }
        style={{
          minHeight: "40px",
          width: "100%",
        }}
        placeholder="Search..."
      />
      <Stack style={{ maxHeight: "175px", overflow: "scroll" }}>
        {filteredValues.map((v: any) => (
          <FormControlLabel
            key={v}
            label={v}
            control={
              <Checkbox
                checked={selected.includes(v)}
                onChange={(_, checked) => {
                  if (checked) {
                    setSelected((old) => {
                      if (old.includes(v)) {
                        return old;
                      }
                      return [...old, v];
                    });
                  } else {
                    setSelected((old) => {
                      if (!old.includes(v)) {
                        return old;
                      }
                      return old.filter((s) => s !== v);
                    });
                  }
                }}
              />
            }
          />
        ))}
      </Stack>
    </div>
  );
}
