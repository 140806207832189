import { ReactElement, ReactNode, isValidElement, useContext } from "react";

import PropelAuthIdentity from "./PropelAuth";
import ApiKeyIdentity from "./ApiKey";

import type { ProviderType } from "./types";
import { AuthenticationRedirectContext } from "./context";
import { useIdentity } from "./hooks";
import Loading from "../Loading";

export function IdentityProvider({
  children,
  type = "propelAuth",
  credential,
  persistCredential,
  removePersistedCredential,
}: {
  children: ReactNode;
  type?: ProviderType;
  credential?: string;
  persistCredential?: (key: string) => void;
  removePersistedCredential?: () => void;
}) {
  switch (type) {
    case "propelAuth":
      return <PropelAuthIdentity>{children}</PropelAuthIdentity>;
    case "apiKey":
      return (
        <ApiKeyIdentity
          credential={credential}
          persistCredential={persistCredential}
          removePersistedCredential={removePersistedCredential}
        >
          {children}
        </ApiKeyIdentity>
      );
    default:
      throw new Error(`Unknown identity provider: "${type}"`);
  }
}

export function RedirectToAuthentication() {
  const Redirect = useContext(AuthenticationRedirectContext);
  if (!Redirect)
    throw new Error(
      "RedirectToAuthentication must be used within a AuthenticationRedirectContext.Provider"
    );
  return <Redirect />;
}

interface ChildrenProps {
  children: ReactNode;
}

export function Authenticated(ElementOrProps: ReactElement | ChildrenProps) {
  const { loading, isAuthenticated } = useIdentity();

  if (isAuthenticated)
    return isValidElement(ElementOrProps)
      ? ElementOrProps
      : (ElementOrProps as ChildrenProps).children;
  if (loading) return <Loading />;
  return <RedirectToAuthentication />;
}

export function Anonymous(ElementOrProps: ReactElement | ChildrenProps) {
  return isValidElement(ElementOrProps)
    ? ElementOrProps
    : (ElementOrProps as ChildrenProps).children;
}
