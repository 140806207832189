import { useCallback, useState, SyntheticEvent } from "react";
import {
  Box,
  Typography,
  Alert,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { validateApiKey } from "./utils";

import Logo from "../../Logo";
import NetworkFailureToast from "../../NetworkFailureToast";

export default function Authenticate({
  onValidKeyEntered,
}: {
  onValidKeyEntered: (key: string) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [apiKeyInvalid, setApiKeyInvalid] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [keyValue, setKeyValue] = useState("");
  const [revealKey, setRevealKey] = useState(false);

  const onSubmitForm = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      setLoading(true);
      setApiKeyInvalid(false);

      try {
        onValidKeyEntered(await validateApiKey(keyValue));
      } catch {
        setApiKeyInvalid(true);
      } finally {
        setLoading(false);
      }
    },
    [keyValue, onValidKeyEntered]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5em",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        minHeight: "100vh",
      }}
    >
      <Logo style={{ maxWidth: "250px" }} />
      <Box
        sx={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          padding: "1.5em",
          maxWidth: "400px",
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" fontWeight={600} component="h2" sx={{ mb: 0 }}>
          Enter a TrueBiz API key to continue
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 1, mb: 3 }}>
          If you don't have an API key or would like to customize your risk
          profile, please{" "}
          <a href="mailto:danny@truebiz.io?subject=TrueBiz API Key Access&body=Hi Danny, I'd like to get access to our API key for testing">
            contact us
          </a>
          .
        </Typography>

        {apiKeyInvalid && (
          <Alert severity="warning" sx={{ my: 3 }}>
            API key is not valid.
          </Alert>
        )}

        <form onSubmit={onSubmitForm}>
          <Stack spacing={1.75}>
            <TextField
              autoComplete="current-password"
              type={revealKey ? "text" : "password"}
              fullWidth
              autoFocus
              required
              disabled={loading}
              label="API Key"
              value={keyValue}
              onChange={(e) => setKeyValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setRevealKey((x) => !x)}>
                      {revealKey ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              Enter
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <NetworkFailureToast
        open={unknownError}
        onClose={() => setUnknownError(false)}
      />
    </div>
  );
}
