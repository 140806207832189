export enum APINotificationActivityAction {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export enum APINotificationActivityDetailsType {
  NotificationAssignmentDetails = "NotificationAssignmentDetails",
}

export type APINotificationActionDetails = {
  assigning_user_id: UUID;
  assigned_user_id: UUID;
};

export default interface APINotificationActivity {
  id: UUID;
  created_at: ISODateTime;
  updated_at: ISODateTime | null | undefined;
  action: APINotificationActivity;
  action_details_type: APINotificationActivityDetailsType | null | undefined;
  action_details: APINotificationActionDetails | null | undefined;
  message: string;
}
