import { Box, Divider } from "@mui/material";

import AnalysisText from "./AnalysisText";
import FlaggedConcerns from "./FlaggedConcerns";
import LevelOfConcern from "./LevelOfConcern";
import ReviewCounts from "./ReviewCounts";

import APICustomerReviews from "../../../types/APICustomerReviews";

export interface Props {
  reviews: APICustomerReviews;
}

export default function ReviewDetails({ reviews }: Props) {
  return (
    <Box>
      <ReviewCounts providers={reviews.providers} />
      {reviews.analysis && (
        <>
          <Divider style={{ marginTop: "1.5em", marginBottom: "1.5em" }} />
          <LevelOfConcern
            levelOfConcern={reviews.analysis.level_of_concern}
            hasFlags={!!reviews.analysis.flags?.length}
          />
          <Box display="flex" flexWrap="wrap" gap="0.5em" mb="1em">
            <FlaggedConcerns flaggedConcerns={reviews.analysis.flags} />
          </Box>
          <AnalysisText>{reviews.analysis.text}</AnalysisText>
        </>
      )}
    </Box>
  );
}
