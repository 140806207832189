import { createContext } from "react";

export type WebsiteStatusCheckContext = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

const websiteStatusCheckContext =
  createContext<WebsiteStatusCheckContext | null>(null);
export default websiteStatusCheckContext;
