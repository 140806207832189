import { Box, Paper, Button } from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import APIResponse from "../types/APIResponse";

export default function JSONResponse() {
  const { response } = useOutletContext<{ response: APIResponse | null }>();
  const { domain } = useParams();

  if (!response) return null;

  const download = () => {
    const blob = new Blob([JSON.stringify(response, null, 2)], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, `${domain}-${Date.now()}.json`);
  };

  return (
    <Paper elevation={3} sx={{ position: "relative" }}>
      <Button
        onClick={download}
        variant="contained"
        size="small"
        startIcon={<DownloadIcon />}
        sx={{ position: "absolute", top: 15, right: 15 }}
      >
        Download
      </Button>

      <Box component="pre" sx={{ py: 1, px: 2, overflow: "auto" }}>
        <code>{JSON.stringify(response, null, 2)}</code>
      </Box>
    </Paper>
  );
}
