import { Alert, Link } from "@mui/material";

export default function UnauthorizedErrorDisplay({
  onResetState,
  lastRequestStatus,
}: {
  onResetState: () => void;
  lastRequestStatus: number | null;
}) {
  const isRequestUnauthorized = lastRequestStatus === 401;

  if (!isRequestUnauthorized) return null;

  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      The API request was rejected due to an invalid API key.{" "}
      <Link component="button" variant="body2" onClick={onResetState}>
        Enter a new key
      </Link>
    </Alert>
  );
}
