export default function isReadOnly() {
  if (window.location.pathname.includes("/passfort/")) {
    return true;
  }

  if (window.location.pathname.includes("/relay/")) {
    return true;
  }

  return false;
}
