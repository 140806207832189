import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ScreenshotModal from "./ScreenshotModal";
import { useIdentity } from "../../IdentityProvider";
import { useTrueBizApi } from "../../../api";

const IMAGE_READY_STATUS = 200;

export default function ProtectedImageEmbed({
  src,
  relayQueryParameters = null,
  relayRequestId = null,
}: {
  src: string | null;
  relayQueryParameters: URLSearchParams | null;
  relayRequestId: string | null;
}) {
  const api = useTrueBizApi();

  const { isAuthenticated } = useIdentity();
  const anonymousAccessAllowed =
    !!relayQueryParameters &&
    Array.from(relayQueryParameters.keys()).length > 0;
  const hasAuthComponents = isAuthenticated || anonymousAccessAllowed;

  const [imgObjectUrl, setImageObjectUrl] = useState<string | null>(null);
  const [failed, setFailed] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setFailed(false);
    setImageObjectUrl(null);
  }, [src, relayQueryParameters]);

  useEffect(() => {
    if (!hasAuthComponents) return;
    if (!src) return;

    const fetchScreenshot = async (
      src: string,
      abortController: AbortController
    ) => {
      try {
        const result = isAuthenticated
          ? await api.getScreenshot(src, abortController.signal)
          : window.location.href.includes("/passfort/")
          ? await api.getPassfortScreenshot(
              src,
              relayQueryParameters,
              relayRequestId,
              abortController.signal
            )
          : await api.getRelayScreenshot(
              src,
              relayQueryParameters,
              relayRequestId,
              abortController.signal
            );

        if (result.status === IMAGE_READY_STATUS && result.url) {
          setImageObjectUrl(result.url);
        } else {
          throw new Error(
            `Screenshot request failed with status ${result.status}`
          );
        }
      } catch (e) {
        if (
          e instanceof DOMException &&
          e.name === "AbortError" &&
          controller.signal.aborted
        ) {
          // ignore any aborted pending requests
          return;
        }

        console.error(e);
        setFailed(true);
      }
    };

    const controller = new AbortController();
    fetchScreenshot(src, controller);

    return () => controller.abort();
  }, [
    isAuthenticated,
    hasAuthComponents,
    api,
    src,
    relayQueryParameters,
    relayRequestId,
  ]);

  if (!hasAuthComponents) return null;

  if (failed)
    return (
      <Box textAlign="center" p={2}>
        Unable to capture screenshot
      </Box>
    );

  return (
    <Box>
      {imgObjectUrl ? (
        <Box onClick={() => setShowModal(true)} sx={{ cursor: "pointer" }}>
          <Box
            position="sticky"
            left="calc(100% - 35px)"
            top={10}
            marginBottom={-5}
            borderRadius={20}
            width={25}
            height={25}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", backgroundColor: "white" }}
          >
            <OpenInFullIcon fontSize="small" />
          </Box>

          <img
            style={{ width: "100%", display: "block" }}
            src={imgObjectUrl}
            alt="Website screenshot"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 4,
          }}
        >
          <CircularProgress color="inherit" />

          <Box marginTop={3}>Loading screenshot...</Box>
        </Box>
      )}

      {imgObjectUrl && (
        <ScreenshotModal
          visible={showModal}
          imgUrl={imgObjectUrl}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
}
