import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const constainerStyles = {
  position: "relative",
  borderRadius: 1,
  border: "1px solid #cbcbcb",
  backgroundColor: "#fff",
  "&:hover": {
    borderColor: "#252525",
  },
  marginLeft: 0,
  width: "100%",
};

const iconWrapperStyles = {
  padding: 2,
  paddingLeft: 1.5,
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const inputBaseStyles = {
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: 1,
    paddingLeft: "3em",
    transition: "width 0.2s ease-in-out",
    width: "100%",
  },
};

export default function Filters({
  searchTerm,
  setSearchTerm,
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  minDate: string;
  setMinDate: (minDate: string) => void;
  maxDate: string;
  setMaxDate: (maxDate: string) => void;
}) {
  const onAcceptMinDate = (date: dayjs.Dayjs | null) => {
    setMinDate(date ? date.startOf("day").toISOString() : "");
  };

  const onAcceptMaxDate = (date: dayjs.Dayjs | null) => {
    setMaxDate(date ? date.endOf("day").toISOString() : "");
  };

  return (
    <Box display="flex" gap={3}>
      <Box sx={constainerStyles}>
        <Box sx={iconWrapperStyles}>
          <SearchIcon />
        </Box>

        <InputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search", width: "100%" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={inputBaseStyles}
        />
      </Box>

      <DatePicker
        label="Min date"
        value={minDate ? dayjs(minDate) : null}
        onAccept={onAcceptMinDate}
        maxDate={maxDate ? dayjs(maxDate).endOf("day") : dayjs()}
        slotProps={{
          textField: {
            size: "small",
          },
          actionBar: {
            actions: ["clear"],
          },
        }}
      />

      <DatePicker
        label="Max date"
        minDate={minDate ? dayjs(minDate).startOf("day") : undefined}
        maxDate={dayjs()}
        value={maxDate ? dayjs(maxDate) : null}
        onAccept={onAcceptMaxDate}
        slotProps={{
          textField: {
            size: "small",
          },
          actionBar: {
            actions: ["clear"],
          },
        }}
      />
    </Box>
  );
}
