import { getAllCountries } from "country-locale-map";

export const getCountries = () => {
  return getAllCountries().map((country) => {
    if (country.alpha2 === "XK") {
      country.continent = "Europe";
    }

    if (country.alpha2 === "CY") {
      // geographically asia, but part of the EU.  our customers think of cyprus as european for the most part...
      country.continent = "Europe";
    }

    if (country.alpha2 === "CI") {
      country.currency = "XOF";
      country.currency_name = "CFA Franc BCEAO";
      // @ts-ignore
      country.currency_symbol = "CFA";
    }

    if (country.continent.toLowerCase() === "americas") {
      country.continent =
        country.region.toLowerCase() !== "south america"
          ? "North America"
          : "South America";
    }

    if (country.alpha2 === "AN") {
      country.languages = ["en", "nl"];
    }

    return country;
  });
};
