import { Paper, Grid, Typography } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";

import BooleanAttribute from "./BooleanAttribute";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIAddress from "../../types/APIAddress";
import { humanizeString } from "../../utilities/formatting";

export default function SubmittedAddress({ address }: { address: APIAddress }) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <Typography variant="h6" component="h3" marginBottom={1}>
        Submitted Address
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <BooleanAttribute
            label="Valid?"
            labelIcon={
              <Tooltip
                title="Is the primary address known to and confirmed as valid by the postal service?"
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            // only show validity signals, no validation failures
            value={address.is_valid}
            trueIsPositive
            failureText="Unknown"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Attribute
            label="Postal flags"
            labelIcon={
              <Tooltip title={"Not yet implemented"} placement="right" arrow>
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            failureText="Unknown"
          >
            {address?.postal_service_flags?.map(humanizeString)?.join(", ") ||
              "No Flags Found"}
          </Attribute>
        </Grid>
        <Grid item xs={12} sm={12}>
          <BooleanAttribute
            label="Is shared with registered agent?"
            labelIcon={
              <Tooltip title={"Not yet implemented"} placement="right" arrow>
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            value={address.is_shared_with_registered_agent}
            trueIsPositive={false}
            failureText="Unknown"
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
