import { Box, IconButton, Snackbar } from "@mui/material";
import type { BoxProps } from "@mui/material/Box";
import LinkIcon from "@mui/icons-material/Link";
import { createPortal } from "react-dom";
import useCopy from "@react-hook/copy";

export interface Props extends BoxProps {
  issueId: string;
  disabled?: boolean;
}

export default function LinkButton({ issueId, disabled }: Props) {
  const root =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://app.truebiz.io";

  const { copied, copy, reset } = useCopy(
    `${root}/monitoring/issues/?issueId=${issueId}`
  );

  return (
    <Box>
      <>
        <IconButton disabled={disabled} onClick={() => copy()}>
          <LinkIcon />
        </IconButton>
        {/* align the notification with the window */}
        {createPortal(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={copied}
            autoHideDuration={5000}
            onClose={() => reset()}
            message="Successfully copied link to issue"
          />,
          document.body
        )}
      </>
    </Box>
  );
}
