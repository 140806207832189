import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { isTruthy } from "../../utilities/isTruthy";

export default function Attribute({
  label,
  children,
  labelIcon,
  failureText = "Not Found",
}: {
  label: string;
  children: React.ReactNode;
  labelIcon?: React.ReactElement;
  failureText?: string;
}) {
  return (
    <Typography component="div" sx={{ mb: 1 }}>
      <Typography
        component="div"
        fontSize={14}
        fontWeight={600}
        color={grey[600]}
      >
        {label}
        {labelIcon}
      </Typography>
      {isTruthy(children) ? children : failureText}
    </Typography>
  );
}
