import { useFormik } from "formik";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { array, object } from "yup";

import Tooltip from "../Tooltip";

const INDUSTRIES = {
  Adult: ["Fetish Gear", "Sex Shops", "Sex Toys"],
  Alcohol: ["Bars and Taverns", "Beer and Wine", "Liquor Stores"],
  "Animal Entertainment": [
    "Animal Fighting",
    "Animal Racing",
    "Rodeos",
    "Zoos",
  ],
  "Animal Slaughter": ["Meat Packing", "Slaughterhouses"],
  "Asset Management": ["Investment Management", "Retirement Funds"],
  Auctions: ["Offline Auctions", "Online Auctions"],
  Cannabis: ["CBD Products", "Cannabis Paraphernalia", "Cannabis Products"],
  "Commodities or Securities Trading": [
    "Brokerage Investment Services",
    "Commodities or Securities Trading",
  ],
  "Career Placement Services": [
    "Job Fairs",
    "Recruiters",
    "Job Coaches",
    "Career Advice",
  ],
  "Counterfeit Goods and IP Infringement": [
    "Counterfeit Goods",
    "Cracks",
    "Cyberlocking",
    "Espionage Goods or Services",
    "File Sharing",
    "Illegal Streaming",
    "Personal Information Brokerage",
    "Ticket Scalping",
    "Essay and Homework Writing",
  ],
  "Credit Monitoring and Identity Protection Services": [
    "Credit Monitoring",
    "Identity Protection",
    "Identity Restoration",
  ],
  Crowdfunding: [],
  "Cruise Lines": [],
  Cryptocurrency: [
    "Crypto Exchanges",
    "Crypto Mining",
    "Cryptocurrencies",
    "NFTs",
    "Stored Value Goods and Services",
  ],
  "Dairy and Feed Lots": ["Animal Farms", "Dairies"],
  Dating: ["Dating Websites", "Other Dating Services"],
  "Debt Services": [
    "Bankruptcy",
    "Crowdfunding",
    "Debt Collection",
    "Debt Consolidation",
    "Factoring",
    "Reposession",
  ],
  "Discount and Rebate Services": [
    "Coupon Clubs",
    "Discount Groups",
    "Rebate Trackers",
    "Travel Clubs",
  ],
  "Endangered Animals": [],
  Fireworks: ["Fireworks", "Firecrackers", "Incendiary Devices"],
  Gambling: [
    "Bookmaking",
    "Fantasy Sports",
    "Games of Chance",
    "Lotteries",
    "Online Gambling",
    "Sweepstakes and Contests",
  ],
  Government: ["Defense", "Government", "Military"],
  "Hazardous Materials": [
    "Heavy Metals",
    "Mining",
    "Nuclear",
    "Other Toxic Materials",
    "Radioactive Materials",
    "Rare Earth Minerals",
  ],
  "High Value Assets": ["Loose Stones", "Precious metals"],
  Infomercials: [],
  "International Customs Services": ["Brokers", "Forwarders", "Transportation"],
  "Insurance Services": ["Brokerage", "Underwriting", "Warranty"],
  "Leather and Taxidermy": ["Animal Skins", "Leather Products", "Taxidermy"],
  "Lead Generation": [],
  Lending: [
    "Bail Bonds",
    "Bill Payment",
    "Cash Storage",
    "Check Cashing",
    "Currency Exchange",
    "Debt Consolidation",
    "P2P",
    "Pawn Shops",
    "Payment Processing",
    "Personal and Business Loans",
    "Venture Capital",
    "Wire Transfers and Money Orders",
  ],
  "Medical Devices": [],
  "Multi-level Marketing": [],
  "Oil and Gas": [
    "Coal",
    "Natural Gas",
    "Petroleum",
    "Refining",
    "Related Transportation",
  ],
  "Pet Stores": ["Live Animals"],
  Pharmaceuticals: [
    "Designer Drugs",
    "Drug Paraphernalia",
    "Drug Stores",
    "Online or Mail Order Pharmacy",
    "Pharmaceutical Manufacturing",
    "Steroids",
    "Vitamin Supplements",
    "Weight Loss",
    "Intravenous Therapy",
  ],
  "Prepaid Telephony": ["Prepaid SIM", "Prepaid Minutes"],
  "Psychics and Fortune Tellers": [
    "Psychics, Mediums, Fortune Tellers, Non-religious Spiritual Guides",
  ],
  Pornography: ["Adult Video Games", "Adult Videos and Photos"],
  "Real Estate": ["Sales", "Trading", "Trusts"],
  "Religious and Political Groups": [
    "Political Action Committees",
    "Religious Organizations",
    "Social Organizations",
  ],
  "Scams and Scamware": [
    "Online Tech Support",
    "Vrius Scanners",
    "Malware Purveyors",
  ],
  "Sex Work": [
    "Adult Chat",
    "Escort Services",
    "Live Sexual Entertainment",
    "Prostitution",
    "Sexual Massage",
  ],
  "Shell Companies": ["Shell Banks", "Shell Companies", "Syndicates"],
  "Social Media Manipulation": ["Increasing Clicks and Views", "Review Farms"],
  Solar: ["Installation", "Repair", "Manufacturing"],
  Telehealth: [],
  Telemarketing: ["Outbound Telemarketing Services"],
  Timeshares: [],
  Tobacco: ["Cigars and Cigarettes", "Smokeless Tobacco", "Vaping"],
  Weapons: [
    "Ammunition",
    "Gunpowder",
    "Guns",
    "Other Weapons",
    "Weapons Components",
  ],
  "Web Hosting": [],
};

const DEFAULT_INDUSTRIES = [
  "Adult",
  "Alcohol",
  "Cannabis",
  "Cryptocurrency",
  "Dating",
  "Debt Services",
  "Gambling",
  "Insurance Services",
  "Lending",
  "Multi-level Marketing",
  "Oil and Gas",
  "Pharmaceuticals",
  "Pornography",
  "Sex Work",
  "Tobacco",
  "Weapons",
  "Fireworks",
];

interface FormData {
  industries: string[];
}

const IndustriesStepSchema = object({
  industries: array().required(),
});

export default function IndustriesStep({
  selectedValues,
  onStepCompletion,
}: {
  selectedValues: string[];
  onStepCompletion: (industryCategories: string[]) => void;
}) {
  const formik = useFormik({
    initialValues: {
      industries:
        !!selectedValues && selectedValues.length > 0
          ? selectedValues
          : DEFAULT_INDUSTRIES,
    },
    onSubmit: (values: FormData) => onStepCompletion(values.industries),
    validationSchema: IndustriesStepSchema,
  });
  const joinFormatter = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction",
  });

  const allSelected =
    formik.values.industries.length === Object.keys(INDUSTRIES).length;

  return (
    <Grid container spacing={2}>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <FormGroup>
          <FormControl>
            <Typography>
              What industry groupings do you want to consider high risk?
            </Typography>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Chip
                  label={`${allSelected ? "Unselect" : "Select"} All`}
                  color={allSelected ? "error" : "primary"}
                  variant="outlined"
                  onClick={() => {
                    formik.setFieldValue(
                      "industries",
                      allSelected ? [] : Array.from(Object.keys(INDUSTRIES))
                    );
                  }}
                />
                <Chip
                  label="TrueBiz Defaults"
                  color={"primary"}
                  variant="outlined"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    formik.setFieldValue("industries", DEFAULT_INDUSTRIES);
                  }}
                />
              </Box>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              {Object.entries(INDUSTRIES).map(
                ([industryName, subcategories]: [string, string[]]) => {
                  return (
                    <Grid item xs={4} key={industryName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.industries.includes(
                              industryName
                            )}
                            name="industries"
                          />
                        }
                        label={
                          <Typography>
                            {industryName}
                            {!!subcategories && subcategories.length > 0 && (
                              <Tooltip
                                title={`Examples include ${joinFormatter.format(
                                  subcategories
                                )}`}
                                placement="right"
                                arrow
                              >
                                <HelpOutlineOutlinedIcon
                                  fontSize="inherit"
                                  sx={{ ml: 0.25 }}
                                />
                              </Tooltip>
                            )}
                          </Typography>
                        }
                        name="industries"
                        onChange={async (event, checked) => {
                          let selectedIndustries = [
                            ...formik.values.industries,
                          ];
                          if (
                            checked &&
                            !selectedIndustries.includes(industryName)
                          ) {
                            selectedIndustries.push(industryName);
                          }
                          if (
                            !checked &&
                            selectedIndustries.includes(industryName)
                          ) {
                            selectedIndustries = selectedIndustries.filter(
                              (selectedIndustry) =>
                                industryName !== selectedIndustry
                            );
                          }
                          await formik.setFieldValue(
                            "industries",
                            selectedIndustries
                          );
                        }}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
            {formik.errors.industries && formik.touched.industries && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                {formik.errors.industries}
              </Alert>
            )}
          </FormControl>
        </FormGroup>

        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}></Box>
            <Box sx={{ display: "flex" }}>
              <Button variant="contained" size="large" type="submit">
                Next
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}
