import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import DropdownList from "./DropdownList";
import EstimatedSpendLineChart from "./EstimatedSpendLineChart";
import Attribute from "../Attribute";
import BooleanAttribute from "../BooleanAttribute";
import Tooltip from "../../Tooltip";
import APIDomain from "../../../types/APIDomain";
import { isTruthy } from "../../../utilities/isTruthy";

const MINIMUM_RECORDS_TO_SHOW_HISTORICAL_SPEND_CHART = 3;

export default function WebserverAnalysis({
  domain,
  domainProperties,
}: {
  domain: APIDomain;
  domainProperties: null | any;
}) {
  return (
    <Grid
      container
      spacing={2}
      marginTop={1}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 2 }}
    >
      <Grid item xs={12}>
        {domain?.hosting_providers && (
          <Grid item xs={12}>
            <Attribute
              label="Hosting Providers"
              labelIcon={
                <Tooltip
                  title={domainProperties?.hosting_providers?.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
            >
              {domain.hosting_providers.map((host) => {
                return (
                  <Typography key={host.name || "" + host.country || ""}>
                    {host.name} ({host.country})
                  </Typography>
                );
              })}
            </Attribute>
          </Grid>
        )}

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BooleanAttribute
              label="Webserver responsive?"
              labelIcon={
                <Tooltip
                  title={domainProperties?.is_webserver_responsive.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={domain?.is_webserver_responsive}
              failureText="Unknown"
            />
          </Grid>

          <Grid item xs={4}>
            <BooleanAttribute
              label="SSL valid?"
              labelIcon={
                <Tooltip
                  title={domainProperties?.is_ssl_valid.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={domain?.is_ssl_valid}
              failureText="Unknown"
            />
          </Grid>

          <Grid item xs={4}>
            <BooleanAttribute
              label="Parked?"
              labelIcon={
                <Tooltip
                  title={domainProperties?.is_parked.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={domain?.is_parked}
              trueIsPositive={false}
              failureText="Unknown"
            />
          </Grid>

          <Grid item xs={4}>
            <BooleanAttribute
              label="Has robots.txt?"
              labelIcon={
                <Tooltip
                  title={domainProperties?.has_robots_txt.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={domain?.has_robots_txt}
              trueIsPositive={true}
              failureText="Unknown"
            />
          </Grid>

          <Grid item xs={4}>
            <BooleanAttribute
              label="Supports apex and www?"
              labelIcon={
                <Tooltip
                  title={domainProperties?.has_apex_and_www.description}
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={domain?.has_apex_and_www}
              trueIsPositive={true}
              failureText="Unknown"
            />
          </Grid>
          {isTruthy(domain?.inbound_linking_domains_count) && (
            <Grid item xs={4}>
              <Attribute
                label="Inbound linking domains count"
                labelIcon={
                  <Tooltip
                    title={
                      domainProperties?.inbound_linking_domains_count
                        ?.description
                    }
                    placement="right"
                    arrow
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize="inherit"
                      sx={{ ml: 0.25 }}
                    />
                  </Tooltip>
                }
              >
                {domain?.inbound_linking_domains_count}
              </Attribute>
            </Grid>
          )}
          {domain?.redirects && (
            <Grid item xs={4}>
              <Attribute
                label="Redirects"
                labelIcon={
                  <Tooltip
                    title={domainProperties?.redirects?.description}
                    placement="right"
                    arrow
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize="inherit"
                      sx={{ ml: 0.25 }}
                    />
                  </Tooltip>
                }
              >
                <DropdownList domains={domain.redirects} />
              </Attribute>
            </Grid>
          )}
          {domain?.cohosted_domains && (
            <Grid item xs={4}>
              <Attribute
                label="Co-hosted domains"
                labelIcon={
                  <Tooltip
                    title={domainProperties?.cohosted_domains?.description}
                    placement="right"
                    arrow
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize="inherit"
                      sx={{ ml: 0.25 }}
                    />
                  </Tooltip>
                }
              >
                <DropdownList domains={domain.cohosted_domains} />
              </Attribute>
            </Grid>
          )}

          {domain.estimated_tech_spend_history &&
          domain.estimated_tech_spend_history.length >=
            MINIMUM_RECORDS_TO_SHOW_HISTORICAL_SPEND_CHART ? (
            <EstimatedSpendLineChart
              spendHistory={domain.estimated_tech_spend_history}
              estimatedTechSpend={domain.estimated_tech_spend}
              domainProperties={domainProperties}
            />
          ) : (
            <>
              {!!domain.estimated_tech_spend && (
                <Grid item xs={4}>
                  <Attribute
                    label="Estimated Tech Spend"
                    labelIcon={
                      <Tooltip
                        title={
                          domainProperties?.estimated_tech_spend?.description
                        }
                        placement="right"
                        arrow
                      >
                        <HelpOutlineOutlinedIcon
                          fontSize="inherit"
                          sx={{ ml: 0.25 }}
                        />
                      </Tooltip>
                    }
                  >
                    ${domain.estimated_tech_spend.toLocaleString("en-US")}
                  </Attribute>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
