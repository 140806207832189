import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Confirmation from "./Confirmation";
import Success from "./Success";
import Error from "./Error";
import { useTrueBizApi } from "../../../../api";

type ViewState = "confirmation" | "success" | "error";

export interface Props {
  monitorId: string;
  onCancel: () => void;
  onDone: () => void;
  onDelete: (id: string) => Promise<void>;
}

export default function MonitorDelete({
  onCancel,
  onDone,
  onDelete,
  monitorId,
}: Props) {
  const api = useTrueBizApi();
  const [currentView, setCurrentView] = useState<ViewState>("confirmation");

  const monitorQuery = useQuery({
    queryKey: ["getMonitor", monitorId],
    queryFn: useCallback(
      () => api.getMonitor(undefined, monitorId),
      [api, monitorId]
    ),
  });

  const matchProfilesQuery = useQuery({
    queryKey: ["getMatchProfiles"],
    queryFn: useCallback(() => api.getCompanyMatchProfiles(), [api]),
  });

  const submittedDomain = monitorQuery.data?.domain || "unknown";

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
        paddingBottom: "0.75em",
        display: "flex",
        flex: 1,
      }}
    >
      <h2>
        <IconButton
          onClick={() => {
            onCancel();
          }}
        >
          <CloseIcon />
        </IconButton>
        Unenroll Merchant
      </h2>
      {monitorQuery.isLoading || matchProfilesQuery.isLoading ? (
        <Stack
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : currentView === "confirmation" && monitorQuery.data ? (
        <Confirmation
          domain={monitorQuery.data.domain}
          onDelete={async () => onDelete(monitorQuery.data.id)}
          onCancel={onCancel}
          onSuccess={() => {
            setCurrentView("success");
          }}
          onError={() => {
            setCurrentView("error");
          }}
        />
      ) : currentView === "success" ? (
        <Success domain={submittedDomain} onDone={onDone} />
      ) : currentView === "error" || monitorQuery.error ? (
        <Error
          domain={submittedDomain}
          reason={String(monitorQuery.error) || ""}
          onDone={onDone}
        />
      ) : null}
    </Stack>
  );
}
