import { useQuery } from "@tanstack/react-query";
import { Divider, Stack } from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import MoreIcon from "@mui/icons-material/More";
import ArchiveIcon from "@mui/icons-material/Archive";
import StorageIcon from "@mui/icons-material/Storage";

import NavigationButton from "./NavigationButton";
import NavigationBadge from "./NavigationBadge";
import { useTrueBizApi } from "../../../api";
import { useIdentity } from "../../IdentityProvider";
import {
  APINotificationStatus,
  APINotificationType,
} from "../../../types/APINotification";

export default function Navigation() {
  const api = useTrueBizApi();
  const identity = useIdentity();

  const myOpenIssueCountQuery = useQuery({
    enabled: !!identity.user?.email,
    queryKey: ["my", "issues", "openIssueCount"],
    queryFn: async () => {
      const myEmail = identity.user!.email!; // the query will only ever run if we have an email
      const issues = await api.getNotifications({
        limit: 1,
        filterBy: [
          {
            column: "type",
            operator: "in",
            value: [APINotificationType.SiteContent],
          },
          {
            column: "status",
            operator: "in",
            value: [APINotificationStatus.Assigned, APINotificationStatus.Open],
          },
          { column: "assignee", operator: "in", value: [myEmail] },
        ],
      });

      return { open: issues.count };
    },
  });

  const allOpenIssueCountQuery = useQuery({
    queryKey: ["all", "issues", "openIssueCount"],
    queryFn: async () => {
      const issues = await api.getNotifications({
        limit: 1,
        filterBy: [
          {
            column: "type",
            operator: "in",
            value: [APINotificationType.SiteContent],
          },
          {
            column: "status",
            operator: "in",
            value: [APINotificationStatus.Assigned, APINotificationStatus.Open],
          },
        ],
      });

      return { open: issues.count };
    },
  });

  return (
    <Stack sx={{ alignItems: "flex-start", paddingRight: "1.5em" }}>
      <NavigationButton to="issues/mine" startIcon={<PersonIcon />}>
        <NavigationBadge
          to="issues/mine"
          badgeContent={myOpenIssueCountQuery.data?.open}
        >
          My Issues
        </NavigationBadge>
      </NavigationButton>

      <Divider flexItem />

      <NavigationButton to="issues/open" startIcon={<MoreIcon />}>
        <NavigationBadge
          to="issues/open"
          badgeContent={allOpenIssueCountQuery.data?.open}
        >
          Open
        </NavigationBadge>
      </NavigationButton>
      <NavigationButton to="issues/resolved" startIcon={<ArchiveIcon />}>
        Resolved
      </NavigationButton>
      <NavigationButton to="issues/all" startIcon={<StorageIcon />}>
        Everything
      </NavigationButton>
    </Stack>
  );
}
