import { Box, Typography, useTheme, Grid } from "@mui/material";
import { capitalize, orderBy } from "lodash";

import ProviderLogo from "./ProviderLogo";
import {
  humanizeString,
  formatNumberForDisplay,
} from "../../../utilities/formatting";
import { APICustomerReviewProvider } from "../../../types/APICustomerReviews";
import { Optional } from "../../../utilities/types";

export interface Props {
  providers: Optional<APICustomerReviewProvider[]>;
}

export default function ReviewCounts({ providers }: Props) {
  const theme = useTheme();

  if (!providers?.length) return <>{null}</>;

  return (
    <Grid container columnGap="1em" rowGap="1em">
      {orderBy(providers, ["meta.review_count"], ["desc"]).map((data) => {
        const reviewCount = data.meta.review_count;

        return (
          <a
            href={data.meta?.url}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              aspectRatio: "1 / 1",
              textDecoration: "none",
              color: theme.palette.text.primary,
            }}
            title={data.meta?.url && "Open reviews in a new tab"}
            key={data.provider}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              border="1px solid"
              borderColor={theme.palette.grey[300]}
              borderRadius="3px"
              sx={{
                aspectRatio: "1 / 1",
                "&:hover": {
                  borderColor: "#E1E1F9",
                  backgroundColor: "#F9F8FD",
                },
                overflow: "hidden",
                minWidth: "175px",
                minHeight: "175px",
              }}
            >
              <Box display="flex" alignItems="baseline" gap="0.25em">
                <ProviderLogo
                  provider={data.provider}
                  width={16}
                  color={theme.palette.grey[700]}
                  fill={theme.palette.grey[700]}
                />
                <Typography
                  color={theme.palette.grey[700]}
                  fontWeight={600}
                  component="div"
                >
                  {capitalize(humanizeString(data.provider))}
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontWeight: 100 /* there is bug in font-source where 100 and 300 weights are swapped */,
                  }}
                  fontSize={55}
                  component="div"
                >
                  {formatNumberForDisplay(data.meta.review_average, false, {
                    mantissa: 1,
                    trimMantissa: false,
                  })}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color={theme.palette.grey[700]}
                  fontWeight={600}
                  component="div"
                >
                  {formatNumberForDisplay(reviewCount, false)} review
                  {reviewCount === 1 ? "" : "s"}
                </Typography>
              </Box>
            </Box>
          </a>
        );
      })}
    </Grid>
  );
}
