export enum APIPhoneLineType {
  Landline = "Landline",
  Mobile = "Mobile",
  FixedVOIP = "FixedVOIP",
  NonFixedVOIP = "NonFixedVOIP",
  TollFree = "TollFree",
  Other = "Other",
  Voicemail = "Voicemail",
  Unknown = "",
}

export enum APIPhoneLinkType {
  Direct = "Direct",
  Employee = "Employee",
  Address = "Address",
}

export interface APIPhoneLink {
  type: APIPhoneLinkType;
  duration: string | null;
}

export default interface APIPhone {
  number: string;
  is_valid: boolean | null;
  line_type: APIPhoneLineType | null;
  country_code: string | null;
  country_name: string | null;
  links_to_company: APIPhoneLink[];
  source?: string | null;
}
