import { ReactNode } from "react";
import { Alert } from "@mui/material";

export default function ResponseErrorDisplay({
  callToActionComponent = null,
  lastRequestStatus,
}: {
  callToActionComponent?: ReactNode | null;
  lastRequestStatus: number | null;
}) {
  const isRequestUnsuccessful =
    lastRequestStatus && (lastRequestStatus > 401 || lastRequestStatus === 400);

  if (!isRequestUnsuccessful) return null;

  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      There was an error when submitting the request.
      {callToActionComponent && <> {callToActionComponent}</>}
    </Alert>
  );
}
