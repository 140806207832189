import { isEmpty, isPlainObject, isArray, isNil, pickBy } from "lodash";
import { useState, useEffect } from "react";
import {
  Outlet,
  useParams,
  generatePath,
  useSearchParams,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import { Alert, Box, Slide, useTheme, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { backOff } from "exponential-backoff";
import ReplayIcon from "@mui/icons-material/Replay";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";

import UnauthorizedErrorDisplay from "./UnauthorizedErrorDisplay";
import UnsuccessfulErrorDisplay from "./UnsuccessfulErrorDisplay";
import NetworkFailureToast from "./NetworkFailureToast";
import ResultsTabs from "./ResultsTabs";
import WebOnly from "./WebOnly";
import SearchBar from "./SearchBar";
import { useTrueBizApi } from "../api";
import APICompanyLookup from "../types/APICompanyLookup";
import { humanizeDateString } from "../utilities/formatting";

/**
 * Checks to see if the `response_data` returned by the company lookup
 * is a skeleton populated with null/undefined/false values. A skeleton is
 * returned when a search is still being processed on the backend. For now,
 * this works, but I think a better solution is to add some kind of field/state
 * to the backend (i.e. `completed_at`) to be able to explicitly check if a search
 * is still running, vs there was a legit problem and we didn't get any data back.
 */
function isSkeletonResponse(v: any): boolean {
  if (isPlainObject(v)) {
    return isEmpty(v) ? true : Object.values(v).every(isSkeletonResponse);
  }

  if (isArray(v)) {
    return isEmpty(v) ? true : v.every(isSkeletonResponse);
  }

  return isNil(v) || v === false;
}

export default function LookupCompany() {
  const api = useTrueBizApi();
  const { searchId, domain } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // we arrived here as the result of submitting a search directly
  const isNewlyCompletedSearch = location.state?.newlyCompletedSearch === true;

  const [searchIncomplete, setSearchIncomplete] = useState(false);
  const [failed, setFailed] = useState<boolean>(false);

  const [companyLookup, setCompanyLookup] = useState<null | APICompanyLookup>(
    null
  );

  const [lastRequestStatus, setLastRequestStatus] = useState<null | number>(
    null
  );

  const onResetState = () => {
    setCompanyLookup(null);
    setLastRequestStatus(null);
    setSearchIncomplete(false);
  };

  const matchProfileQuery = useQuery({
    queryKey: ["getCompanyMatchProfiles"],
    queryFn: () => api.getCompanyMatchProfiles(),
  });

  useEffect(() => {
    if (!searchId) return;

    let cancelled = false;
    setFailed(false);
    setSearchIncomplete(false);

    class SearchIncompleteError extends Error {}

    backOff(
      async () => {
        let companyLookup;

        try {
          companyLookup = await api.getCompanyLookup(searchId);
        } catch (e) {
          if (cancelled) return;

          if (api.isResponseError(e)) {
            setLastRequestStatus(e.status);
          }
          return;
        }

        if (cancelled) return;

        setLastRequestStatus(200);

        if (isSkeletonResponse(companyLookup.response_data)) {
          setSearchIncomplete(true);
          throw new SearchIncompleteError();
        }

        setSearchIncomplete(false);
        setCompanyLookup(companyLookup);
      },
      {
        maxDelay: 30_000,
        startingDelay: 1_000,
        delayFirstAttempt: false,
        retry: (e, attemptNumber) => {
          if (e instanceof SearchIncompleteError) {
            console.error(
              `[getCompanyLookup]: attempt ${attemptNumber} failed, search is incomplete, retrying.`
            );
            return !cancelled;
          }
          console.error(`[getCompanyLookup]: ${e}`);
          return false;
        },
      }
    )
      .catch(() => {
        if (cancelled) return;
        setFailed(true);
      })
      .finally(() => {
        if (cancelled) return;
        setSearchIncomplete(false);
      });

    return () => {
      cancelled = true;
    };
  }, [api, searchId]);

  const getSearchParamPairs = () => {
    let output = [];
    if (companyLookup?.search_term) {
      output.push(["Domain", companyLookup?.search_term]);
    }
    if (companyLookup?.search_query?.submitted_business_name) {
      output.push([
        "Business Name",
        companyLookup?.search_query?.submitted_business_name,
      ]);
    }
    if (companyLookup?.search_query?.submitted_email) {
      output.push(["Email", companyLookup?.search_query?.submitted_email]);
    }
    if (companyLookup?.search_query?.submitted_phone) {
      output.push(["Phone", companyLookup?.search_query?.submitted_phone]);
    }
    if (companyLookup?.search_query?.submitted_full_name) {
      output.push([
        "Applicant Name",
        companyLookup?.search_query?.submitted_full_name,
      ]);
    }

    const addrPieces = [];
    if (companyLookup?.search_query?.address_line_1) {
      addrPieces.push(companyLookup?.search_query?.address_line_1);
    }
    if (companyLookup?.search_query?.address_line_2) {
      addrPieces.push(companyLookup?.search_query?.address_line_2);
    }
    if (companyLookup?.search_query?.city) {
      addrPieces.push(companyLookup?.search_query?.city);
    }
    if (companyLookup?.search_query?.state_province) {
      addrPieces.push(companyLookup?.search_query?.state_province);
    }
    if (companyLookup?.search_query?.postal_code) {
      addrPieces.push(companyLookup?.search_query?.postal_code);
    }
    if (companyLookup?.search_query?.country) {
      addrPieces.push(companyLookup?.search_query?.country);
    }

    if (addrPieces.length) {
      output.push(["Address", addrPieces.join(", ")]);
    }

    if (
      companyLookup?.search_query?.match_profile_id &&
      matchProfileQuery.data &&
      matchProfileQuery.data.length > 1
    ) {
      const riskProfileName = matchProfileQuery.data.filter(
        (p) => p.id === companyLookup.search_query?.match_profile_id
      )[0]?.name;
      if (riskProfileName) {
        output.push(["Risk Profile", riskProfileName]);
      }
    }

    return output;
  };
  const searchParamPairs = getSearchParamPairs();

  const encodedDomain = encodeURIComponent(domain ? String(domain) : "offline");

  const pathPrefix = generatePath("/search/:domain/:searchId", {
    searchId: encodeURIComponent(String(searchId)),
    domain: encodedDomain,
  });

  const theme = useTheme();

  return (
    <div style={{ position: "relative" }}>
      <NetworkFailureToast open={failed} onClose={() => setFailed(false)} />

      <WebOnly>
        {!isNewlyCompletedSearch && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 99,
              transform: "translateY(-100%)",
            }}
          >
            <NavLink
              to={`/searches?${searchParams.toString()}`}
              title="Back to Search History"
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: "0.5em",
                  fontSize: "85%",
                }}
              >
                <ArrowBackIcon fontSize="small" /> Back to Search History
              </div>
            </NavLink>
          </div>
        )}
      </WebOnly>

      <Box marginBottom={3} marginTop={1}>
        {!companyLookup && searchIncomplete && (
          <Alert severity="info">
            We&rsquo;re still analyzing all of the data for this search. This
            page will refresh when your search completes. Please check back in a
            few minutes.
          </Alert>
        )}
      </Box>

      <UnsuccessfulErrorDisplay
        lastRequestStatus={lastRequestStatus}
        callToActionComponent={
          <NavLink to="json">View the raw JSON response</NavLink>
        }
      />
      <UnauthorizedErrorDisplay
        lastRequestStatus={lastRequestStatus}
        onResetState={onResetState}
      />

      {companyLookup && !searchIncomplete && (
        <>
          <SearchBar
            autoFocus={false}
            domain={companyLookup.search_term}
            matchProfileId={companyLookup.search_query?.match_profile_id}
            fullWidth
            style={{
              maxWidth: "550px",
              marginBottom: "-40px",
              zIndex: 1,
              position: "relative",
            }}
          />

          <Slide direction="up" in>
            <Box marginTop={0}>
              <ResultsTabs
                pathPrefix={pathPrefix}
                response={companyLookup.response_data}
              />

              <Outlet
                context={{
                  response: companyLookup.response_data,
                  lookup: companyLookup,
                  domain,
                  searchParametersDisplay: (
                    <Grid container columnSpacing={3}>
                      <Grid item xs={12} mb={2}>
                        <div
                          style={{
                            border: "1px solid #ccc",
                            padding: "0.5em",
                            borderRadius: "5px",
                            background: "#fdfdfd",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              minHeight: "38px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <strong style={{ color: "#555" }}>
                                Parameters for Search (
                                {humanizeDateString(companyLookup?.created_at)})
                              </strong>
                            </div>
                            <WebOnly>
                              <Link
                                to={`/?${queryString.stringify(
                                  pickBy({
                                    domain: companyLookup?.search_term,
                                    businessName:
                                      companyLookup?.search_query
                                        ?.submitted_business_name,
                                    email:
                                      companyLookup?.search_query
                                        ?.submitted_email,
                                    phone:
                                      companyLookup?.search_query
                                        ?.submitted_phone,
                                    applicantFullName:
                                      companyLookup?.search_query
                                        ?.submitted_full_name,
                                    addressLine1:
                                      companyLookup?.search_query
                                        ?.address_line_1,
                                    addressLine2:
                                      companyLookup?.search_query
                                        ?.address_line_2,
                                    city: companyLookup?.search_query?.city,
                                    stateProvince:
                                      companyLookup?.search_query
                                        ?.state_province,
                                    postalCode:
                                      companyLookup?.search_query?.postal_code,
                                    country:
                                      companyLookup?.search_query?.country,
                                    riskProfile:
                                      companyLookup?.search_query
                                        ?.match_profile_id,
                                  })
                                )}`}
                                style={{
                                  textDecoration: "none",
                                  color: theme.palette.primary.main,
                                  flexShrink: 0,
                                }}
                              >
                                <div style={{ display: "flex", gap: "0.25em" }}>
                                  <ReplayIcon /> Re-run this search
                                </div>
                              </Link>
                            </WebOnly>
                          </div>
                          <hr
                            style={{
                              borderStyle: "solid",
                              borderColor: "#eee",
                            }}
                          />
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              gridRowGap: "0.5em",
                            }}
                          >
                            {searchParamPairs.map(([k, v]) => (
                              <div key={k}>
                                <span style={{ color: "#777" }}>{k}</span>
                                <br />
                                <strong style={{ color: "#555" }}>{v}</strong>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ),
                }}
              />
            </Box>
          </Slide>
        </>
      )}
    </div>
  );
}
