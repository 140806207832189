import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import "@fontsource/aileron/100.css"; // there is a bug in the package where 100 and 300 weight files are swapped
import "@fontsource/aileron/300.css";
import "@fontsource/aileron/400.css";
import "@fontsource/aileron/600.css";
import "@fontsource/aileron/700.css";

import { IdentityProvider } from "./components/IdentityProvider";
import { TrueBizApiProvider } from "./api";
import App from "./App";
import Oops from "./components/Oops";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

dayjs.extend(utc);
dayjs.extend(timezone);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new QueryClient();

root.render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={() => <Oops />}>
      <ThemeProvider theme={theme}>
        <IdentityProvider
          type={
            window.localStorage.getItem("TRUEBIZ-API-KEY")
              ? "apiKey"
              : "propelAuth"
          }
          credential={window.localStorage.getItem("TRUEBIZ-API-KEY") || ""}
          persistCredential={(key: string) => {
            window.localStorage.setItem("TRUEBIZ-API-KEY", key);
          }}
          removePersistedCredential={() => {
            window.localStorage.removeItem("TRUEBIZ-API-KEY");
          }}
        >
          <QueryClientProvider client={client}>
            <TrueBizApiProvider>
              <BrowserRouter>
                <CssBaseline />
                <App />
              </BrowserRouter>
            </TrueBizApiProvider>
          </QueryClientProvider>
        </IdentityProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
