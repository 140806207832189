import APIUser from "./APIUser";

export enum APIMonitorActivityAction {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export default interface APIMonitorActivity {
  id: UUID;
  actor: APIUser | null | undefined;
  created_at: ISODateTime;
  updated_at: ISODateTime | null | undefined;
  action: APIMonitorActivityAction;
  message: string;
}
