/* global RequestInfo */
/* global RequestInit */
import { isEmpty, omit } from "lodash";

// I don't think this is actually possible rn in typescript, so just fake it
// https://github.com/microsoft/TypeScript/issues/17572
type Constructable = any;

type ResponseType = "json" | "blob" | "text";
export interface Stub {
  status: number;
  delayMs: number;
  returnValue: any;
  type: ResponseType;
}

interface RequestConfig {
  unwrapResponse?: boolean;
  type?: ResponseType;
  fetchOptions?: RequestInit;
}

export class BaseApi {
  readonly rootUrl: string = `/`;

  readonly getAuthorizationHeaders: () => Record<string, any>;
  readonly headers: any;

  readonly stub?: Stub;

  isResponseError(e: any): e is Response {
    if (!e) return false;
    if (e instanceof Response) return true;
    if ("status" in e && "ok" in e && "json" in e) return true; // stubbed responses
    return false;
  }

  constructor({
    getAuthorizationHeaders = () => ({}),
    headers = {},
    stub,
  }: {
    getAuthorizationHeaders?: () => Record<string, any>;
    headers?: any;
    stub?: Stub;
  } = {}) {
    this.getAuthorizationHeaders = getAuthorizationHeaders;
    this.headers = headers;
    this.stub = stub;
  }

  authenticated() {
    const authHeaders = this.getAuthorizationHeaders();

    if (isEmpty(authHeaders)) {
      throw new Error("No authentication information present.");
    }

    return new (this.constructor as Constructable)({
      headers: {
        ...this.headers,
        ...authHeaders,
      },
      ...(this.stub ? { stub: { ...this.stub } } : {}),
    }) as typeof this;
  }

  anonymous() {
    return new (this.constructor as Constructable)({
      headers: {
        ...omit(this.headers, Object.keys(this.getAuthorizationHeaders())),
      },
      ...(this.stub ? { stub: { ...this.stub } } : {}),
    }) as typeof this;
  }

  json() {
    return new (this.constructor as Constructable)({
      getAuthorizationHeaders: this.getAuthorizationHeaders,
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
      },
      ...(this.stub ? { stub: { ...this.stub } } : {}),
    }) as typeof this;
  }

  untyped() {
    return new (this.constructor as Constructable)({
      getAuthorizationHeaders: this.getAuthorizationHeaders,
      headers: {
        ...omit(this.headers, ["Content-Type"]),
      },
      ...(this.stub ? { stub: { ...this.stub } } : {}),
    }) as typeof this;
  }

  stubbedWith(
    returnValue: any,
    {
      delayMs = 1_000,
      status = 200,
      type = "json",
    }: Partial<Omit<Stub, "returnValue">> = {}
  ) {
    if (
      process.env.NODE_ENV === "development" &&
      ["0", "false", undefined].includes(
        process.env.REACT_APP_DISABLE_DEV_API_STUBBING
      )
    ) {
      return new (this.constructor as Constructable)({
        getAuthorizationHeaders: this.getAuthorizationHeaders,
        headers: { ...this.headers },
        stub: {
          delayMs,
          status,
          returnValue,
          type,
        },
      }) as typeof this;
    } else {
      return this;
    }
  }

  async fetch(input: RequestInfo | URL, config: RequestConfig = {}) {
    const { fetchOptions = {}, unwrapResponse = true, type = "json" } = config;

    let response;

    if (this.stub) {
      const { status, delayMs, type: stubType, returnValue } = this.stub;
      const value =
        typeof returnValue === "function" ? returnValue() : returnValue;

      // console.log(
      //   `Stubbing ${delayMs}ms ${
      //     fetchOptions.method || "GET"
      //   } response for url ${input}: ${JSON.stringify(value, null, 2)}`
      // );

      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), delayMs);
      });

      response = {
        status,
        ok: status < 400,
        [stubType]: () => Promise.resolve(value),
      };
    } else {
      const { body } = fetchOptions;
      response = await fetch(input, {
        ...fetchOptions,
        headers: { ...this.headers, ...fetchOptions.headers },
        ...(body === undefined
          ? {}
          : { body: typeof body === "string" ? body : JSON.stringify(body) }),
      });
    }

    if (!response.ok) {
      throw response;
    }

    if (unwrapResponse) {
      return await (response[type] as () => Promise<any>)();
    } else {
      return response;
    }
  }

  async get(url: string | URL, config: RequestConfig = {}) {
    return await this.fetch(url, config);
  }

  async post(url: string | URL, body?: any, config: RequestConfig = {}) {
    const { fetchOptions = {}, ...etc } = config;

    return await this.fetch(url, {
      ...etc,
      fetchOptions: {
        ...fetchOptions,
        body,
        method: "POST",
      },
    });
  }

  async delete(url: string | URL, body?: any, config: RequestConfig = {}) {
    const { fetchOptions = {}, ...etc } = config;
    return await this.fetch(url, {
      ...etc,
      fetchOptions: {
        ...fetchOptions,
        body,
        method: "DELETE",
      },
    });
  }

  async put(url: string | URL, body?: any, config: RequestConfig = {}) {
    const { fetchOptions = {}, ...etc } = config;
    return await this.fetch(url, {
      ...etc,
      fetchOptions: {
        ...fetchOptions,
        body,
        method: "PUT",
      },
    });
  }
}
