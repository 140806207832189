import { ReactNode } from "react";

export function useIsPrint() {
  return !!window.matchMedia?.("print")?.matches;
}

export interface Props {
  children: ReactNode;
}

export default function PrintOnly({ children }: Props) {
  const isPrint = useIsPrint();
  if (!isPrint) return null;
  return <>{children}</>;
}
