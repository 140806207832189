import { format, max, parseISO } from "date-fns";
import { APIMonitorType } from "../../../types/APIMonitor";
import APIUser from "../../../types/APIUser";

export const PENDING_DATETIME = "Pending...";
export const isPending = (isoDateString: ISODateTime) =>
  isoDateString === PENDING_DATETIME;

export function formatDateTime(isoDateString?: string | null | undefined) {
  if (!isoDateString) return;
  if (isPending(isoDateString)) return isoDateString;

  try {
    return format(parseISO(isoDateString), "MMMM d, yyyy h:mm a");
  } catch (e) {
    console.error(e, isoDateString);
    return isoDateString;
  }
}

export function formatDate(isoDateString?: string | null | undefined) {
  if (!isoDateString) return;
  if (isPending(isoDateString)) return isoDateString;

  try {
    return format(parseISO(isoDateString), "MMMM d, yyyy");
  } catch (e) {
    console.error(e, isoDateString);
    return isoDateString;
  }
}

export const formatMonitorType = (type: APIMonitorType) => {
  if (type === APIMonitorType.WebsiteContent) return "Site Scan";
  if (type === APIMonitorType.WebPresence) return "Web Presence Review";
  if (type === APIMonitorType.WebsiteStatus) return "Site Status";
  return "Other/System Activity";
};

export const formatUser = (user: APIUser) => user.email || "a TrueBiz user";

export const maxDate = (...dates: (string | null | undefined | Date)[]) => {
  const cleaned: (string | Date)[] = dates.filter((x) => !!x) as (
    | string
    | Date
  )[];
  try {
    return max(cleaned).toISOString();
  } catch (e) {
    return undefined;
  }
};
