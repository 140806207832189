import {
  ThumbDownOffAlt as ThumbDownOffAltIcon,
  ThumbUpOffAlt as ThumbUpOffAltIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export const Value = ({
  trueIsPositive,
  value,
  positiveText,
  negativeText,
}: {
  trueIsPositive: boolean;
  value: boolean;
  positiveText: string;
  negativeText: string;
}) => {
  const trueColor = trueIsPositive ? "success.light" : "error.light";
  const TrueIcon = trueIsPositive ? ThumbUpOffAltIcon : ThumbDownOffAltIcon;
  const trueText = positiveText;

  const falseColor = trueIsPositive ? "error.light" : "success.light";
  const FalseIcon = trueIsPositive ? ThumbDownOffAltIcon : ThumbUpOffAltIcon;
  const falseText = negativeText;

  const color = value ? trueColor : falseColor;
  const Icon = value ? TrueIcon : FalseIcon;
  const text = value ? trueText : falseText;

  return (
    <Box>
      <Typography
        color={color}
        display="flex"
        alignItems="center"
        fontWeight="600"
        component="span"
      >
        <Icon fontSize="small" sx={{ marginRight: 0.25 }} />
        {text}
      </Typography>
    </Box>
  );
};

export default function BooleanAttribute({
  label,
  labelIcon,
  value,
  trueIsPositive = true,
  failureText = "Not Found",
}: {
  label: string;
  labelIcon?: React.ReactElement;
  value: boolean | null;
  trueIsPositive?: boolean;
  failureText?: string | null;
}) {
  return (
    <Typography component="div" sx={{ mb: 1 }}>
      <Typography
        component="div"
        fontSize={14}
        fontWeight={600}
        color={grey[600]}
      >
        {label}
        {labelIcon}
      </Typography>

      {value === null ? (
        <span>{failureText}</span>
      ) : (
        <Value
          trueIsPositive={trueIsPositive}
          value={value}
          positiveText="Yes"
          negativeText="No"
        />
      )}
    </Typography>
  );
}
