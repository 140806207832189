import { useEffect } from "react";
import { TrueBizApi } from "../../../api";

export const createGetAuthorizationHeaders = (key: string) => () => ({
  "X-API-KEY": key,
});

export const validateApiKey = async (key: string): Promise<string> => {
  const api = new TrueBizApi({
    getAuthorizationHeaders: createGetAuthorizationHeaders(key),
  });

  await api.getCompanyMatchProfiles();
  return key;
};

export function SideEffect({ onMount }: { onMount: () => void }) {
  useEffect(() => {
    onMount();
  }, [onMount]);
  return null;
}
