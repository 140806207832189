import dayjs from "dayjs";
import { isoDuration, en } from "@musement/iso-duration";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { capitalize } from "lodash";
import numbro from "numbro";
import APIAddress from "../types/APIAddress";
import { APIPhoneLineType } from "../types/APIPhone";

isoDuration.setLocales({ en }, { fallbackLocale: "en" });

export const humanizeDateString = (str: string | null): string | null =>
  str ? dayjs(str).format("MMMM D, YYYY") : null;

export const tzAwareHumanizeTimeString = (str: string | null): string | null =>
  str ? dayjs(str).tz(dayjs.tz.guess()).format("MMMM D, YYYY h:mm a") : null;

export const humanizeBoolean = (bool: boolean): string => (bool ? "Yes" : "No");

export const humanizeString = (str: string): string =>
  str
    .replace(/_/g, " ")
    .trim()
    .replace(/\b[A-Z][a-z]+\b/g, (word) => word.toLowerCase())
    .replace(/^[a-z]/g, (first) => first.toUpperCase());

export const humanizePhoneLineType = (lineType: APIPhoneLineType) => {
  switch (lineType) {
    case APIPhoneLineType.FixedVOIP:
      return "fixed VoIP";
    case APIPhoneLineType.NonFixedVOIP:
      return "non-fixed VoIP";
    case APIPhoneLineType.Landline:
      return "landline";
    case APIPhoneLineType.Mobile:
      return "mobile";
    case APIPhoneLineType.TollFree:
      return "toll-free";
    case APIPhoneLineType.Voicemail:
      return "voicemail";
    default:
      return "other/unknown";
  }
};

export const humanizeISODuration = (duration: string) => {
  try {
    return isoDuration(duration).humanize("en", { largest: 1 });
  } catch (e) {
    console.error(e);
    // pass
  }

  return duration;
};

export const formatCurrency = (
  num: number | null,
  currencyCode: string | null
) => {
  if (!num) {
    return null;
  }

  if (!currencyCode) {
    return num;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  }).format(num);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    return (
      parsePhoneNumberFromString(phoneNumber)?.formatInternational() ||
      phoneNumber
    );
  } catch {
    return phoneNumber;
  }
};

export const formatNumberForDisplay = (
  number: number | string,
  abbreviate = true,
  numbroOptions?: numbro.Format
) => {
  try {
    return numbro(number).format({
      thousandSeparated: true,
      average: abbreviate,
      mantissa: abbreviate ? 1 : 0,
      trimMantissa: abbreviate,
      ...(numbroOptions || {}),
    });
  } catch (e) {
    return number;
  }
};

export const getDisplayAddress = (address: APIAddress): string | null => {
  if (address.full_address) {
    return address.full_address;
  }
  let displayAddress = null;
  if (address.line_1) {
    displayAddress = address.line_1;
    if (address.line_2) {
      displayAddress += `, ${address.line_2}`;
    }
  }

  if (address.city) {
    displayAddress = displayAddress
      ? `${displayAddress}, ${address.city}`
      : address.city;
  }

  if (address.state_province) {
    displayAddress = displayAddress
      ? `${displayAddress}, ${address.state_province}`
      : address.state_province;
  }

  if (address.postal_code) {
    displayAddress = displayAddress
      ? `${displayAddress}, ${address.postal_code}`
      : address.postal_code;
  }

  if (address.country) {
    displayAddress = displayAddress
      ? `${displayAddress}, ${address.country}`
      : address.country;
  }

  return displayAddress;
};

export const capitalizeFirstLetterOnly = (s: string) => {
  if (!s) return s;
  return `${capitalize(s[0])}${s.slice(1)}`;
};

export function getRelativeTimeString(date: Date, language = "en-US"): string {
  try {
    const currentInMs = date.getTime();
    const deltaSeconds = Math.round((currentInMs - Date.now()) / 1000);
    const timeSlices = [
      60,
      3600,
      86400,
      86400 * 7,
      86400 * 30,
      86400 * 365,
      Infinity,
    ];
    const units: Intl.RelativeTimeFormatUnit[] = [
      "second",
      "minute",
      "hour",
      "day",
      "week",
      "month",
      "year",
    ];
    const unitIndex = timeSlices.findIndex((slice) => {
      return slice > Math.abs(deltaSeconds);
    });
    const divisor = unitIndex ? timeSlices[unitIndex - 1] : 1;
    const formatter = new Intl.RelativeTimeFormat(language, {
      numeric: "auto",
    });
    return formatter.format(
      Math.floor(deltaSeconds / divisor),
      units[unitIndex]
    );
  } catch {
    return "";
  }
}
