/* eslint-disable jsx-a11y/iframe-has-title */
import { Fade, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { LoadingIframe } from "./LoadingIframe";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: 1,
  overflow: "auto",
  outline: "none",
  boxShadow: 24,
  lineHeight: 1,
  p: 1.5,
};

export default function IframeModal({
  url,
  visible,
  onClose,
}: {
  url: string;
  visible: boolean;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="screenshot-modal-title"
      aria-describedby="screenshot-modal-description"
    >
      <Fade in={visible}>
        <Box sx={modalStyle}>
          <Box
            position="sticky"
            left="calc(100% - 50px)"
            top={10}
            marginBottom={-5}
            borderRadius={20}
            width={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onClose}
            sx={{ cursor: "pointer", backgroundColor: "white" }}
          >
            <CloseIcon fontSize="large" />
          </Box>

          <Box width="100%" height="100%">
            {loading && <LoadingIframe />}
            <iframe
              src={url}
              width="100%"
              height="100%"
              style={{
                overflow: "none",
                pointerEvents: "none",
                display: loading ? "none" : "flex",
              }}
              tabIndex={-1}
              sandbox="allow-scripts allow-same-origin"
              onLoad={() => {
                setLoading(false);
              }}
              onError={() => {
                setLoading(false);
              }}
            ></iframe>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
