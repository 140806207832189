import { useCallback, useState } from "react";
import type { SortDirection } from "@tanstack/react-table";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";

import { useApply } from "./hooks";

export interface Props {
  currentSort?: SortDirection | null | undefined;
  onApply: (sort: SortDirection | null | undefined) => void;
}

export default function SimpleSort({ currentSort, onApply }: Props) {
  // can't use null/undefined directly here or React will think we're switching
  // to an uncontrolled component
  const [newSort, setNewSort] = useState<SortDirection | "">(currentSort || "");

  const applySort = useCallback(() => {
    onApply(newSort || null);
  }, [onApply, newSort]);

  useApply(applySort);

  return (
    <RadioGroup
      value={newSort}
      onChange={(_, v) => {
        if (v === "asc" || v === "desc") {
          setNewSort(v);
        }
      }}
    >
      <FormControlLabel
        value="asc"
        control={<Radio />}
        label="Sort ascending"
      />
      <FormControlLabel
        value="desc"
        control={<Radio />}
        label="Sort descending"
      />
    </RadioGroup>
  );
}
